import styled from '@mui/material/styles/styled'
import { CustomTheme } from '@typesApp/theme'
import { Typography } from '@mui/material'
import { Z_INDEX_LV2, Z_INDEX_LV3 } from '@constants/ui'
import { IconButton } from '@components/UI/IconButton'
import type { ToastProp } from './Toast.type'

const themeVariantMap = (theme: CustomTheme, themeVariant: ToastProp['themeVariant'] = 'primary') => {
  const themeStyles = {
    primary: {
      background: theme.palette.custom.grey,
      color: theme.palette.text.light.primary,
      borderColor: undefined,
    },
    secondary: {
      background: theme.palette.custom.light.grey,
      color: theme.palette.text.dark.primary,
      borderColor: theme.palette.custom.light2.grey,
    },
    tertiary: {
      background: theme.palette.custom.white,
      color: theme.palette.text.dark.primary,
      borderColor: theme.palette.custom.light2.grey,
    },
  }
  return themeStyles[themeVariant!]
}

const ToastWrapperBase = styled('div', {
  name: 'Toast',
  slot: 'WrapperBase',
  shouldForwardProp: prop => prop !== 'themeVariant' && prop !== 'open' && prop !== 'isMobile',
})<ToastProp>(({ themeVariant, theme, open, isMobile = false }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  zIndex: isMobile ? Z_INDEX_LV3 : Z_INDEX_LV2,
  position: 'absolute',
  width: '100%',
  padding: `0 ${theme.spacing(4)}`,
  backgroundColor: themeVariantMap(theme, themeVariant)?.background,
  color: themeVariantMap(theme, themeVariant)?.color,
  border: open ? `1px solid${themeVariantMap(theme, themeVariant)?.borderColor}` : 'none',
  fontSize: '0.75rem',
  lineHeight: '16px',
  bottom: '0',
  opacity: open ? '1' : '0',
  transform: open ? 'translateY(0)' : 'translateY(100%)',
  transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',

  a: {
    color: themeVariantMap(theme, themeVariant)?.color,
    textDecoration: 'underline',
  },
}))

export const ToastWrapper = styled(ToastWrapperBase, {
  name: 'Toast',
  slot: 'Wrapper',
})(({ theme }) => ({
  left: 0,

  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(6)} ${theme.spacing(16)} ${theme.spacing(6)} ${theme.spacing(16)}`,
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4),
    overflowY: 'auto',
  },
}))

export const ThinMarginToastWrapper = styled(ToastWrapperBase, {
  name: 'Toast',
  slot: 'ThinMarginWrapper',
})(({ theme }) => ({
  padding: theme.spacing(4),
  overflowY: 'auto',
}))

export const ToastHeader = styled('div', {
  name: 'Toast',
  slot: 'Header',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    paddingTop: 0,
  },
}))

export const ToastContainer = styled('div', {
  name: 'Toast',
  slot: 'Container',
})(({ theme }) => ({
  'p:first-of-type': {
    marginTop: '0',
  },

  'p:last-of-type': {
    marginBottom: '0',
  },

  [theme.breakpoints.down('md')]: {
    maxHeight: '250px',
    overflowY: 'auto',
  },
}))

export const ToastCloseButton = styled(IconButton, {
  name: 'Toast',
  slot: 'CloseIcon',
})(({ theme }) => ({
  position: 'absolute',
  right: '0',
  color: 'inherit',
  [theme.breakpoints.down('sm')]: {
    top: 0,
  },
}))

export const ToastCustomCloseButton = styled(IconButton, {
  name: 'Toast',
  slot: 'CloseIcon',
})(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(16),
  top: theme.spacing(6),
  color: 'inherit',
  [theme.breakpoints.down('sm')]: {
    top: theme.spacing(6),
    right: theme.spacing(4),
  },
}))

export const ToastHeading = styled(Typography, {
  name: 'Toast',
  slot: 'Heading',
})(({ theme }) => ({
  width: '100%',
  maxWidth: '100vw',
  margin: '0 auto',
  fontSize: '1.25rem',
  fontWeight: 700,
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  textAlign: 'center',

  [theme.breakpoints.up('sm')]: {
    fontSize: '1.5rem',
    paddingTop: 0,
    justifyContent: 'center',
  },
}))
