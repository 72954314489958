import styled from '@mui/material/styles/styled'

export const ProductPriceInitialPricePDPForCMS = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(3),
  flex: '0 0 auto',
  textDecoration: 'line-through',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  lineHeight: theme.spacing(4),
}))

export const ProductPriceContainerForCMS = styled('div', {
  shouldForwardProp: prop => prop !== 'isPDP' && prop !== 'isCompact',
})<{ isPDP: boolean | null; isCompact: boolean | null }>(({ isPDP, isCompact, theme }) => ({
  width: '100%',
  display: isPDP ? 'flex' : 'initial',
  flexWrap: isCompact ? 'wrap' : 'nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: '130px',
    flexWrap: 'wrap',
  },
  [theme.breakpoints.up('md')]: {
    flexWrap: 'nowrap',
  },
}))

export const ProductPriceCurrentPriceForCMS = styled('span', {
  shouldForwardProp: prop => prop !== 'isPDP',
})<{ isPDP: boolean | null }>(({ isPDP, theme }) => ({
  fontSize: isPDP ? theme.typography.body1.fontSize : theme.typography.subtitle2.fontSize,
  fontWeight: isPDP ? 600 : 400,
  paddingRight: theme.spacing(1),
  color: theme.palette.custom.red,
  lineHeight: theme.spacing(6),
}))

export const ProductPriceDiscountBoxForCMS = styled('span')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px 8px',
  gap: '10px',
  width: '63px',
  height: '24px',
  background: theme.palette.custom.light.red,
  color: theme.palette.custom.dark1.red,
  borderRadius: '38px',
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: '16px',
}))
