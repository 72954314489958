import { CreditCardPlaceHolderIcon } from '@components/UI/Icons/payments'
import { CheckoutName, DisplayName, PAYMENT_METHODS, Icon } from '@constants/paymentMethods'
import * as PaymentMethod from '../constants/paymentMethods'
const { CHECKOUT_NAMES, ICONS, DISPLAY_NAMES, FOOTER_NAMES } = PAYMENT_METHODS

type PaymentMethodName = CheckoutName | DisplayName

class PaymentMethodsService {
  private getIcon(
    NAMES: typeof CHECKOUT_NAMES | typeof DISPLAY_NAMES | typeof FOOTER_NAMES,
    methodName: PaymentMethodName
  ): Icon | null {
    let paymentMethodKey: PaymentMethodName | null = null

    for (const key in NAMES) {
      if (methodName === NAMES[key]) {
        paymentMethodKey = key as PaymentMethodName
        break
      }
    }

    return paymentMethodKey ? ICONS[paymentMethodKey] : null
  }

  getIconByChekoutName(checkoutName: CheckoutName): Icon | null {
    return this.getIcon(CHECKOUT_NAMES, checkoutName)
  }

  getIconByAvailableCards(AvailableCards: DisplayName): Icon | null {
    return this.getIcon(DISPLAY_NAMES, AvailableCards)
  }

  getIconByFooterName(footerName: PaymentMethod.FooterName): PaymentMethod.Icon | null {
    return this.getIcon(FOOTER_NAMES, footerName)
  }

  getIconsForCreditCardForm(): Icon[] {
    return [
      ICONS.VISA ?? CreditCardPlaceHolderIcon,
      ICONS.AMEX ?? CreditCardPlaceHolderIcon,
      ICONS.MASTERCARD ?? CreditCardPlaceHolderIcon,
    ]
  }
}

export default new PaymentMethodsService()
