import { css, styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { Box, TooltipProps as MuiTooltipProps } from '@mui/material'
import { IconCross } from '../Icons/VD/General'
import { PopperProps, tooltipClasses } from '@mui/material'
import { PopperUnstyled } from '@mui/base'
import type { ColorVariant } from './Tooltip.type'
import type { CustomTheme } from '../../../types/theme'

export const BootstrapTooltip = styled(Tooltip, {
  name: 'Tooltip',
  slot: 'Root',
  shouldForwardProp: prop => prop !== 'background',
})<{ background: string | undefined }>(({ theme, background }) => {
  let backgroundColor: MuiTooltipProps['color']
  let color: MuiTooltipProps['color']

  switch (background) {
    case 'background11':
      backgroundColor = theme.palette.background[11]
      break
    case 'neutral':
      backgroundColor = theme.palette.background.neutral
      break
    case 'background3':
      backgroundColor = theme.palette.background[3]
      color = theme.palette.background.neutral
      break
    default:
      backgroundColor = theme.palette.background.primary
  }
  return {
    [`&.${tooltipClasses.popper}`]: {
      maxWidth: 264,

      [`&[data-popper-placement="right"] .${tooltipClasses.tooltip}`]: {
        marginLeft: theme.spacing(4),
      },
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: backgroundColor,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: backgroundColor,
      color: color,
      padding: theme.spacing(4, 8),
    },
  }
})

export const TooltipContentBlock = styled('div', {
  name: 'Tooltip',
  slot: 'ContentBlock',
})(() => ({}))

export const TooltipContentRight = styled(Box, {
  name: 'Tooltip',
  slot: 'ContentRight',
})({
  float: 'right',
})

export const TooltipContent = styled(Box, {
  name: 'Tooltip',
  slot: 'Content',
})(() => ({}))

export const TooltipCross = styled(IconCross, {
  name: 'Tooltip',
  slot: 'Cross',
})({
  fontSize: '0.625rem',
  '&:hover': { cursor: 'pointer' },
})

export const StyledContent = styled('span', {
  name: 'Tooltip',
  slot: 'Content',
})(() => ({
  display: 'flex',
}))

type StyledPopperProps = PopperProps & { colorVariant: ColorVariant }

type ColorVariantMapped = {
  background: string
  text: string
  borderColor: string
  link: string
}

const mapColorVariantPopper = (theme: CustomTheme, variant: ColorVariant): ColorVariantMapped => {
  const themeStyles: {
    [K in ColorVariant]: ColorVariantMapped
  } = {
    darkgray: {
      background: theme.palette.custom.grey,
      text: theme.palette.custom.white,
      borderColor: '',
      link: theme.palette.custom.white,
    },
    lightgray: {
      background: theme.palette.custom.light.grey,
      text: theme.palette.text.dark.primary,
      borderColor: theme.palette.custom.light2.grey,
      link: theme.palette.custom.blue,
    },
    primaryBlue: {
      background: theme.palette.custom.blue,
      text: theme.palette.custom.white,
      borderColor: '',
      link: theme.palette.custom.white,
    },
    primaryLightBlue: {
      background: theme.palette.custom.light.blue,
      text: theme.palette.custom.cyprus,
      borderColor: '',
      link: theme.palette.custom.blue,
    },
    white: {
      background: theme.palette.custom.white,
      text: theme.palette.custom.black,
      borderColor: theme.palette.custom.light2.grey,
      link: theme.palette.custom.blue,
    },
    dark: {
      background: theme.palette.custom.cyprus,
      text: theme.palette.custom.white,
      borderColor: '',
      link: theme.palette.custom.white,
    },
    primary: {
      background: theme.palette.custom.halfBaked,
      text: theme.palette.custom.cyprus,
      borderColor: '',
      link: theme.palette.custom.cyprus,
    },
  }
  return themeStyles[variant]
}

export const PopperStyle = ({ theme, colorVariant }) =>
  css({
    [`.${tooltipClasses.tooltip}`]: {
      maxWidth: '16.5rem',
      backgroundColor: mapColorVariantPopper(theme, colorVariant).background,
      color: mapColorVariantPopper(theme, colorVariant).text,
      border: `${mapColorVariantPopper(theme, colorVariant).borderColor ? `1px solid ${mapColorVariantPopper(theme, colorVariant).borderColor}` : 'none'}`,
      fontSize: theme.typography.subtitle1.fontSize,
      lineHeight: 1.33,
      fontWeight: 'normal',
      padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
      margin: '0px !important',
      boxShadow: '15px 15px 32px 0px rgba(0, 0, 0, 0.15)',
      '.popover-body-container': {
        position: 'relative',
        '&__title': {
          fontSize: theme.typography.body.fontSize,
          lineHeight: theme.spacing(6),
          fontWeight: 700,
          margin: 0,
        },
        '&__storeTitle': {
          fontSize: theme.typography.body2.fontSize,
          lineHeight: theme.spacing(6),
          fontWeight: 700,
          margin: 0,
          width: 'max-content',
          maxWidth: '13.5rem',
        },
        '&__button': {
          position: 'absolute',
          width: '1rem',
          height: '1rem',
          right: 0,
          top: 0,
          cursor: 'pointer',
        },
        '&__body': {
          fontSize: theme.typography.body2.fontSize,
          lineHeight: theme.spacing(5),
          margin: 0,
          '&--spacing-up': {
            margin: `${theme.spacing(1)} 0 0 0`,
          },
        },
        '&--footer-container': {
          marginTop: theme.spacing(4),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontWeight: 600,
          ' &__cta > a': {
            fontSize: theme.typography.subtitle1.fontSize,
            color: mapColorVariantPopper(theme, colorVariant).link,
            textDecoration: 'underline',
          },
          ' &__phone > a': {
            fontSize: theme.typography.subtitle1.fontSize,
            color: mapColorVariantPopper(theme, colorVariant).link,
            textDecoration: 'underline',
          },
        },
      },
    },
    [`.${tooltipClasses.arrow}`]: {
      color: mapColorVariantPopper(theme, colorVariant).background,
      '&:before': {
        border: `${mapColorVariantPopper(theme, colorVariant).borderColor ? `1px solid ${mapColorVariantPopper(theme, colorVariant).borderColor}` : 'none'}`,
      },
    },
  })

export const PopperWithStyle = styled(PopperUnstyled, {
  slot: 'Tooltip',
  label: 'Popper',
  shouldForwardProp: prop => prop !== 'colorVariant',
})<StyledPopperProps>(PopperStyle)
