import clsx from 'clsx'
import styles from './styles/TopPageBanner.module.scss'
import GenericBanner from '../GenericBanner'
import { TextModuleBanner } from '../TextModuleBanner'
import { useContext } from 'react'
import { ContextWrapperData, PlacementContext } from '../../PlacementContextWrapper/PlacementContextWrapper'

const TopPageBanner: React.FC = () => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  if (!context) return null

  return (
    <>
      <GenericBanner
        textModuleClassName="hide-below-tablet"
        crop="TOP_PAGE_BANNER_S"
        className={styles.topPageBanner}
        lazy
        termsAndConditionsClassName={styles.topPageBannerTermsConditions}
        videoWrapperClassName={styles.topPageBannerVideoWrapper}
      />
      <TextModuleBanner className={clsx('hide-above-tablet', styles.topPageBannerTextModuleWrapper)} />
    </>
  )
}

export default TopPageBanner
