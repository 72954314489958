import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import { TitleContainer } from '@components/ProductGrid/components/PlpHeader/PlpHeader.style'

const PlpHeaderTitle: FC<{
  catalogLoading?: boolean
  isSticky?: boolean
  text?: string
}> = ({ catalogLoading, isSticky = false, text }) => (
  <TitleContainer isSticky={isSticky} data-testid="plpTitle">
    {catalogLoading || !text ? <Skeleton /> : text}
  </TitleContainer>
)
export default PlpHeaderTitle
