import { useTheme } from '@mui/material/styles'
import { FC, useState } from 'react'
// TYPES
import { Attachment } from '../../../types/product'
import {
  ProductFullPageGalleryImagesSlider,
  ProductFullPageGalleryThumbsSlider,
} from '@pages_views/ProductDetails/ProductDetails.style'
import {
  ProductFullPageGalleryCloseIcon,
  ProductFullPageGalleryContainer,
} from '@pages_views/ProductDetails/components/ProductImagesSlider/ProductImagesSlider.style'

//UI
import { CloseIcon } from '@components/UI/Icons/close'

/**
 * @param { Attachment[] } images slider images
 * @param { boolean } isVisible toggle the component visibility
 * @param { boolean } pdpDataloading pdp data loading status
 * @param { function } onCloseClick callback when clicking on close button
 * @param { number } currentSlide you can force the slide index to the slider
 */

export interface ProductFullPageGalleryProps {
  images?: Attachment[]
  isVisible?: boolean
  onCloseClick?: () => void
  currentSlide?: number
  pdpDataloading?: boolean
}

const ProductFullPageGallery: FC<ProductFullPageGalleryProps> = ({
  images,
  onCloseClick,
  isVisible,
}: ProductFullPageGalleryProps) => {
  const theme = useTheme()
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  return !!isVisible ? (
    <ProductFullPageGalleryContainer isVisible={isVisible}>
      <ProductFullPageGalleryCloseIcon onClick={() => onCloseClick && onCloseClick()}>
        <CloseIcon htmlColor={theme.palette.primary.main} />
      </ProductFullPageGalleryCloseIcon>
      {images && images?.length > 0 && (
        <>
          <ProductFullPageGalleryImagesSlider
            currentSlide={currentSlide}
            slideIndex={currentSlide}
            onSlideChange={slideNumber => setCurrentSlide(slideNumber || 0)}
            images={images}
            sliderImageProps={{
              srcsetmap: {
                300: '320w',
                400: '640w',
                600: '960w',
                700: '1280w',
                900: '1920w',
              },
              width: 600,
              usage: 'PDP',
              loading: 'lazy',
            }}
            sliderProps={{
              loop: false,
            }}
          />
          <ProductFullPageGalleryThumbsSlider
            currentSlide={currentSlide}
            onImageClick={index => {
              setCurrentSlide(index)
            }}
            images={images}
            sliderImageProps={{
              srcsetmap: {
                200: '320w',
                300: '640w',
              },
              width: 80,
              usage: 'PDP',
              loading: 'lazy',
            }}
            sliderProps={{
              loop: false,
              slidesPerView: images.length,
            }}
            withProgressBar
          />
        </>
      )}
    </ProductFullPageGalleryContainer>
  ) : null
}

export default ProductFullPageGallery
