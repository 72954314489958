import React from 'react'
import { DisplayName } from '@constants/paymentMethods'
import PaymentMethodsService from '@services/PaymentMethodsService'

export interface PaymentIconProps {
  availablePaymentMethods: DisplayName[]
}

export const PaymentMethodsIcons: React.FC<PaymentIconProps> = ({ availablePaymentMethods }) => (
  <>
    {availablePaymentMethods?.map((paymentMethodName, i) => {
      const PaymentMethodIcon = PaymentMethodsService.getIconByAvailableCards(paymentMethodName)

      return PaymentMethodIcon ? <PaymentMethodIcon key={i} /> : null
    })}
  </>
)
