import { ILXTeaserName } from '@typesApp/cmsPlacement/LXTeaser'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'

export const variantMap = (
  variant: 'teaserTitle2' | 'teaserTitle3' | 'teaserTitle4' | 'teaserText1' | 'teaserText2',
  theme,
  viewtype?: IViewType,
  hasCode?: boolean,
  styling?: string,
  breakpoint?: string
) => {
  switch (styling) {
    case 'fontWeight':
      switch (variant) {
        case 'teaserTitle2':
          switch (breakpoint) {
            case 'xs':
              return viewtype?.includes('-promo-banner-2') || viewtype?.includes('-promo-2')
                ? 400
                : viewtype?.includes('-promo-banner-3') || viewtype?.includes('-promo-3')
                  ? 700
                  : 0
            default:
              return viewtype?.includes('-promo-banner-3') || viewtype?.includes('-promo-3') ? 700 : 400
          }
        case 'teaserTitle3':
          switch (breakpoint) {
            case 'xs':
              return viewtype?.includes('-promo-banner-2') || viewtype?.includes('-promo-2')
                ? 700
                : viewtype?.includes('-promo-banner-3') ||
                    viewtype?.includes('-promo-3') ||
                    viewtype?.includes('-banner-collection-promo-multiple')
                  ? 400
                  : 0
            default:
              return viewtype?.includes('-promo-banner-3') || viewtype?.includes('-promo-3')
                ? 400
                : viewtype === 'fullwidth-banner-collection-promo' ||
                    viewtype === 'landscape-banner-collection-promo' ||
                    viewtype === 'top-page-banner-collection-promo' ||
                    viewtype?.includes('-banner-collection-promo-multiple')
                  ? 400
                  : 700
          }
        case 'teaserTitle4':
          return 400
        case 'teaserText2':
          return 400
        default:
          return 'auto'
      }
    case 'lineHeight':
      switch (variant) {
        case 'teaserTitle2':
          switch (breakpoint) {
            case 'xs':
              return viewtype?.includes('-promo-banner-2') || viewtype?.includes('-promo-2')
                ? theme.spacing(12.5)
                : viewtype?.includes('-promo-banner-3') || viewtype?.includes('-promo-3')
                  ? theme.spacing(18.75)
                  : 0
            default:
              return viewtype === 'fullwidth-banner-collection-promo' ||
                viewtype === 'landscape-banner-collection-promo' ||
                viewtype === 'top-page-banner-collection-promo' ||
                viewtype?.includes('-banner-collection-promo-multiple')
                ? 0
                : viewtype === 'fullwidth-promo-banner-3'
                  ? theme.spacing(30)
                  : viewtype === 'landscape-promo-banner-3'
                    ? theme.spacing(22.5)
                    : viewtype === 'top-page-promo-3'
                      ? theme.spacing(22.5)
                      : theme.spacing(10)
          }
        case 'teaserTitle3':
          switch (breakpoint) {
            case 'xs':
              return viewtype?.includes('-promo-banner-2') || viewtype?.includes('-promo-2')
                ? theme.spacing(18.75)
                : viewtype?.includes('-promo-banner-3') || viewtype?.includes('-promo-3')
                  ? theme.spacing(7.5)
                  : viewtype?.includes('-banner-collection-promo-multiple')
                    ? theme.spacing(4)
                    : 0
            default:
              return viewtype === 'fullwidth-banner-collection-promo' ||
                viewtype === 'landscape-banner-collection-promo' ||
                viewtype === 'top-page-banner-collection-promo' ||
                viewtype?.includes('-banner-collection-promo-multiple')
                ? theme.spacing(6)
                : viewtype?.includes('-promo-banner-3') || viewtype?.includes('-promo-3')
                  ? theme.spacing(10)
                  : theme.spacing(24)
          }
        case 'teaserTitle4':
          switch (breakpoint) {
            case 'xs':
              return viewtype?.includes('-promo-banner-2') ||
                viewtype?.includes('-promo-2') ||
                viewtype?.includes('-promo-banner-3') ||
                viewtype?.includes('-promo-3')
                ? theme.spacing(7.5)
                : viewtype?.includes('-banner-collection-promo-multiple') && !hasCode
                  ? theme.spacing(4)
                  : theme.spacing(6)
            default:
              return viewtype?.includes('-promo-banner-2') ||
                viewtype?.includes('-promo-2') ||
                viewtype?.includes('-promo-banner-3') ||
                viewtype?.includes('-promo-3') ||
                viewtype === 'fullwidth-promo-banner-4' ||
                viewtype === 'fullwidth-banner-collection-promo' ||
                viewtype === 'landscape-banner-collection-promo' ||
                viewtype === 'top-page-banner-collection-promo' ||
                (viewtype?.includes('-banner-collection-promo-multiple') && hasCode)
                ? theme.spacing(7.5)
                : theme.spacing(6)
          }
        case 'teaserText2':
          switch (breakpoint) {
            case 'xs':
              return viewtype?.includes('-promo-banner-2') || viewtype?.includes('-promo-2')
                ? theme.spacing(7.5)
                : theme.spacing(6)
            default:
              return theme.spacing(7.5)
          }
        default:
          return 'auto'
      }
    default:
      switch (variant) {
        case 'teaserTitle2':
          switch (breakpoint) {
            case 'xs':
              return viewtype?.includes('-promo-banner-2') || viewtype?.includes('-promo-2')
                ? theme.spacing(10)
                : viewtype?.includes('-promo-banner-3') || viewtype?.includes('-promo-3')
                  ? theme.spacing(21)
                  : 0
            default:
              return viewtype?.includes('-promo-banner-3') || viewtype?.includes('-promo-3')
                ? theme.spacing(24)
                : theme.spacing(8)
          }
        case 'teaserTitle3':
          switch (breakpoint) {
            case 'xs':
              return viewtype?.includes('-promo-banner-2') || viewtype?.includes('-promo-2')
                ? theme.spacing(24)
                : viewtype?.includes('-promo-banner-3') || viewtype?.includes('-promo-3')
                  ? theme.spacing(6)
                  : viewtype?.includes('-banner-collection-promo-multiple')
                    ? theme.spacing(3)
                    : 0
            default:
              return viewtype?.includes('-promo-banner-3') || viewtype?.includes('-promo-3')
                ? theme.spacing(8)
                : viewtype === 'fullwidth-banner-collection-promo' ||
                    viewtype === 'landscape-banner-collection-promo' ||
                    viewtype === 'top-page-banner-collection-promo'
                  ? theme.spacing(5)
                  : viewtype?.includes('-banner-collection-promo-multiple')
                    ? theme.spacing(4)
                    : theme.spacing(30)
          }
        case 'teaserTitle4':
          switch (breakpoint) {
            case 'xs':
              return viewtype?.includes('-promo-banner-2') || viewtype?.includes('-promo-2')
                ? theme.spacing(5)
                : viewtype?.includes('-banner-collection-promo-multiple') && !hasCode
                  ? theme.spacing(3)
                  : theme.spacing(4)
            default:
              return viewtype?.includes('-promo-banner-2') ||
                viewtype?.includes('-promo-2') ||
                (viewtype?.includes('-banner-collection-promo-multiple') && hasCode)
                ? theme.spacing(5)
                : theme.spacing(4)
          }
        case 'teaserText2':
          switch (breakpoint) {
            case 'xs':
              return viewtype?.includes('-promo-banner-2') || viewtype?.includes('-promo-2')
                ? theme.spacing(5)
                : theme.spacing(4)
            default:
              return theme.spacing(5)
          }
        default:
          return 'auto'
      }
  }
}

export const variantCollectionMap = (
  variantName: ILXTeaserName,
  theme,
  styling?: string,
  contentNumber?: number,
  breakpoint?: string
) => {
  switch (styling) {
    case 'fontWeight':
      switch (variantName) {
        case 'PromoInfoTextv1-1':
          switch (contentNumber) {
            case 1:
            case 2:
            case 3:
            case 5:
              return 700
            case 4:
            default:
              return 400
          }
        case 'PromoInfoTextv1-3':
          switch (contentNumber) {
            case 1:
            case 3:
              return 700
            case 2:
            default:
              return 400
          }
        case 'PromoInfoTextv2-1':
        case 'PromoInfoTextv2-2':
        case 'PromoInfoTextv2-3':
          switch (contentNumber) {
            case 1:
            case 2:
            case 3:
            default:
              return 700
          }
        default:
          return 'auto'
      }
    case 'fontSize':
      switch (variantName) {
        case 'PromoInfoTextv1-1':
          switch (contentNumber) {
            case 1:
            case 2:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(3)
                default:
                  return theme.spacing(5)
              }
            case 3:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(15)
                default:
                  return theme.spacing(32)
              }
            case 4:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(8)
                default:
                  return theme.spacing(15)
              }
            case 5:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(3.5)
                default:
                  return theme.spacing(6)
              }
            default:
              return theme.spacing(5)
          }
        case 'PromoInfoTextv1-3':
          switch (contentNumber) {
            case 1:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(15)
                default:
                  return theme.spacing(30)
              }
            case 2:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(8)
                default:
                  return theme.spacing(15)
              }
            case 3:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(3.5)
                default:
                  return theme.spacing(6)
              }
            default:
              return theme.spacing(6)
          }
        case 'PromoInfoTextv2-1':
        case 'PromoInfoTextv2-2':
        case 'PromoInfoTextv2-3':
          switch (contentNumber) {
            case 1:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(12)
                default:
                  return theme.spacing(22)
              }
            case 2:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(6)
                default:
                  return theme.spacing(12)
              }
            case 3:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(3.5)
                default:
                  return theme.spacing(5)
              }
            default:
              return theme.spacing(5)
          }
        default:
          return 'auto'
      }
    case 'lineHeight':
      switch (variantName) {
        case 'PromoInfoTextv1-1':
          switch (contentNumber) {
            case 1:
            case 2:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(4)
                default:
                  return theme.spacing(7.5)
              }
            case 3:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(15)
                default:
                  return theme.spacing(40)
              }
            case 4:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(10)
                default:
                  return theme.spacing(18.75)
              }
            case 5:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(5.25)
                default:
                  return theme.spacing(7.5)
              }
            default:
              return theme.spacing(7.5)
          }
        case 'PromoInfoTextv1-3':
          switch (contentNumber) {
            case 1:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(15)
                default:
                  return theme.spacing(40)
              }
            case 2:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(10)
                default:
                  return theme.spacing(18.75)
              }
            case 3:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(5.25)
                default:
                  return theme.spacing(7.5)
              }
            default:
              return theme.spacing(7.5)
          }
        case 'PromoInfoTextv2-1':
        case 'PromoInfoTextv2-2':
        case 'PromoInfoTextv2-3':
          switch (contentNumber) {
            case 1:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(15)
                default:
                  return theme.spacing(18.75)
              }
            case 2:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(7.5)
                default:
                  return theme.spacing(15)
              }
            case 3:
              switch (breakpoint) {
                case 'xs':
                  return theme.spacing(5.25)
                default:
                  return theme.spacing(7.5)
              }
            default:
              return theme.spacing(7.5)
          }
        default:
          return 'auto'
      }
    case 'gridTemplateColumns':
      switch (variantName) {
        case 'PromoInfoTextv1-1':
          return '0.1fr 0.1fr 0.1fr'
        case 'PromoInfoTextv1-3':
          return '0.1fr 0.1fr'
        case 'PromoInfoTextv2-1':
        case 'PromoInfoTextv2-2':
        case 'PromoInfoTextv2-3':
          return '0.5fr 0.4fr'
        default:
          return '1fr'
      }
    case 'gridTemplateRows':
      switch (variantName) {
        case 'PromoInfoTextv1-1':
          return '0.16fr 0.16fr 0.16fr 0.16fr 0.16fr 0.16fr'
        case 'PromoInfoTextv1-3':
          switch (breakpoint) {
            case 'xs':
              return '0.275fr 0.2fr 0.4fr'
            default:
              return '0.3fr 0.2fr 0.25fr'
          }
        case 'PromoInfoTextv2-1':
        case 'PromoInfoTextv2-2':
        case 'PromoInfoTextv2-3':
          switch (breakpoint) {
            case 'xs':
              return '0.4fr 0.4fr 0.2fr'
            default:
              return '0.3fr 0.3fr 0.4fr'
          }
        default:
          return '1fr'
      }
    case 'gridTemplateAreas':
      switch (variantName) {
        case 'PromoInfoTextv1-1':
          return `
          ". content3 ."
          ". content3 content4"
          "content1 content3 content4"
          "content2 content3 content5"
          ". content3 content5"
          ". . content5"
          `
        case 'PromoInfoTextv1-3':
        case 'PromoInfoTextv2-1':
        case 'PromoInfoTextv2-2':
        case 'PromoInfoTextv2-3':
          return `
          "content1 content2"
          "content1 content2"
          "content1 content3"
          `
        default:
          return 'content1'
      }
    default:
      return null
  }
}
