import RequestService from '../../../services/RequestService'

export type NewsletterItem = {
  id?: string
  newsletter_id: string
  firstName?: string
  email: string
  from?: string
  storeId: string
  xcontactSubscriptionId?: string
  hashedId?: string
  statusOnHold?: boolean
}

export type MutuelleItem = {
  id?: string
  newsletter_id: string
  insuranceIssuer: string
  firstName: string
  lastName: string
  email: string
  from: string
  storeId: string
}

/**
 * {
    "newsletter_id": "arn.newsletter",
    "email": "pietro.ferraresi@abstract.it"
   }
 */
/**
 * @deprecated Use Redux Toolkit Query instead
 */
export class NewsletterService {
  static async subscribeToNewsletter(newsletterItem: NewsletterItem) {
    const { newsletter_id, firstName, email, from, storeId } = newsletterItem

    const payload = {
      newsletter_id,
      firstName,
      email,
      from,
    }

    if (newsletterItem?.xcontactSubscriptionId) {
      payload['xcontactSubscriptionId'] = newsletterItem?.xcontactSubscriptionId
    }

    if (newsletterItem?.hashedId) {
      payload['hashedId'] = newsletterItem?.hashedId
    }

    if (newsletterItem.statusOnHold) {
      payload['status'] = 'on-hold'
    }

    return RequestService.request({
      body: payload,
      method: 'POST',
      path: '/store/{storeId}/newsletter',
      pathParams: { storeId },
    })
  }

  static async unsubscribeFromNewsletter(newsletterItem: Pick<NewsletterItem, 'email' | 'storeId'>) {
    const { email, storeId } = newsletterItem

    return RequestService.request({
      body: {
        email,
      },
      method: 'POST',
      path: '/store/{storeId}/newsletter/optOut',
      pathParams: { storeId },
    })
  }

  /**
   * Subscribes to Mutuelle.
   *
   * @param Item - The Mutuelle item containing the necessary information for subscription.
   * @returns A Promise that resolves to the result of the subscription request.
   */
  static async subscribeToMutuelle(Item: MutuelleItem) {
    const { newsletter_id, firstName, lastName, email, from, storeId, insuranceIssuer } = Item

    return RequestService.request({
      body: {
        newsletter_id,
        firstName,
        lastName,
        email,
        from,
        insuranceIssuer,
      },
      method: 'POST',
      path: '/store/{storeId}/newsletter',
      pathParams: { storeId },
    })
  }
}
