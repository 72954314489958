import React from 'react'
import clsx from 'clsx'
import { getCollectionTextOverlayStyle } from '@utils/cms/teaser'
import { TeaserBackgroundColor } from '@utils/cms/background'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ICMPlaceholder } from '@typesApp/cmsPlacement/CMPlaceholder'
import styles from './styles/CollectionText.module.scss'

type CollectionTextProps = {
  backgroundColor?: TeaserBackgroundColor
  marginLateral?: boolean
  teaser: ICMCollection | ICMPlaceholder
}

export const CollectionText: React.FC<CollectionTextProps> = ({ backgroundColor, marginLateral, teaser }) => {
  const { collectionSubTitle, collectionText, collectionTitle } = teaser
  const collectionTextOverlayStyle = getCollectionTextOverlayStyle(teaser)
  const hasContent = collectionSubTitle || collectionText || collectionTitle

  return hasContent ? (
    <div
      className={clsx(styles.wrapper, backgroundColor, collectionTextOverlayStyle, {
        [styles.withBottomPadding]: hasContent,
        [styles.marginLateral]: marginLateral,
      })}
    >
      {collectionTitle && <p className={styles.title}>{collectionTitle}</p>}
      {collectionSubTitle && <p className={styles.subTitle}>{collectionSubTitle}</p>}
      {collectionText && <div className={styles.text} dangerouslySetInnerHTML={{ __html: collectionText }} />}
    </div>
  ) : null
}
