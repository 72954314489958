import React, { forwardRef } from 'react'
import { RadioProps as MuiRadioProps } from '@mui/material'
import StyledRadio from '@components/UI/Radio/Radio.style'

export interface RadioProps {
  checked?: MuiRadioProps['checked']
  disabled?: MuiRadioProps['disabled']
  inputRef?: MuiRadioProps['inputRef']
  error?: boolean
  id?: MuiRadioProps['id']
  name?: MuiRadioProps['name']
  value?: MuiRadioProps['value']
  required?: MuiRadioProps['required']
  onChange?: MuiRadioProps['onChange']
  inputProps?: MuiRadioProps['inputProps']
}

const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const { error, ...rest } = props
  const radioProps = {
    inputRef: ref,
    ...rest,
    ...(error ? { color: 'error' as MuiRadioProps['color'] } : {}),
  }
  return <StyledRadio disableRipple {...radioProps} />
})

export default Radio
