import React from 'react'
import Head from 'next/head'
import { CrossOriginAnonymous } from '@typesApp/product'

export const ProductImageHead: React.FC<{
  preload: Record<string, string>
  src?: string | null
}> = ({ preload, src }) => {
  return (
    <Head>
      {src && <link rel="preload" as="image" href={src} crossOrigin={CrossOriginAnonymous} />}
      {preload[900] && (
        <link
          rel="preload"
          as="image"
          media="(min-width: 1440px)"
          href={preload[900]}
          crossOrigin={CrossOriginAnonymous}
        />
      )}
      {preload[700] && (
        <link
          rel="preload"
          as="image"
          media="(min-width: 1280px) and (max-width: 1439px)"
          href={preload[700]}
          crossOrigin={CrossOriginAnonymous}
        />
      )}
      {preload[600] && (
        <link
          rel="preload"
          as="image"
          media="(min-width: 1024px) and (max-width: 1279px)"
          href={preload[600]}
          crossOrigin={CrossOriginAnonymous}
        />
      )}
      {preload[400] && (
        <link
          rel="preload"
          as="image"
          media="(min-width: 601px) and (max-width: 1023px)"
          href={preload[400]}
          crossOrigin={CrossOriginAnonymous}
        />
      )}
      {preload[300] && (
        <link
          rel="preload"
          as="image"
          media="(max-width: 600px)"
          href={preload[300]}
          crossOrigin={CrossOriginAnonymous}
        />
      )}
    </Head>
  )
}
