import { CmsIcon } from '@components/Cms/CMSContent/components/CmsIcon/CmsIcon'
import { Button } from '@components/UI-CSS/Button'
import { useExternalsActions } from '@hooks/useExternalsAction'
import { ICMAction } from '@typesApp/cmsPlacement/CMAction'
import { ITeaserCallToAction } from '@typesApp/cmsPlacement/LXTeaser'
import { LinkAsButton } from '@components/UI-CSS/LinkAsButton'
import { CmsCtaModal } from '@components/Cms/CMSContent/components/CmsCtaModal'
import { CtaColor } from './constants'
import { getHref, getVariant } from './helpers'
import styles from './styles/index.module.scss'

type CmsCta = {
  currentPath?: string
  dataDescription?: string
  dataElementId?: string
  overrideStyle?: ITeaserCallToAction['style']
  teaserCtaSetting: ITeaserCallToAction
  teaserIcon?: string
}

export const CmsCta: React.FC<CmsCta> = ({
  currentPath,
  dataDescription,
  dataElementId,
  overrideStyle,
  teaserCtaSetting,
  teaserIcon,
}) => {
  const ctaStyle = getCtaStyle(overrideStyle ?? teaserCtaSetting.style)
  const { callToActionText, callToActionHash, target } = teaserCtaSetting ?? {}
  const { type, url: externalUrl, formattedUrl } = target ?? {}

  const isExternal = ['CMExternalLink'].includes(type)
  const url = isExternal ? externalUrl : formattedUrl
  const hasURL = Boolean(url)
  const actions = useExternalsActions()
  const hasAction = target?.type === 'Action'

  if (!teaserCtaSetting?.callToActionEnabled) return null

  if (hasAction) {
    const action = actions[target.idAction]

    return (
      <Button
        data-element-id={dataElementId}
        data-description={dataDescription}
        {...ctaStyle}
        onClick={() => action((target as ICMAction)?.title)}
      >
        {callToActionText}
      </Button>
    )
  }

  const linkProps: ReturnType<typeof getCtaStyle> & {
    href: string
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  } = {
    href: url ? getHref(currentPath, url, callToActionHash) : '',
    onClick: undefined,
    ...ctaStyle,
  }

  if (!hasURL) {
    linkProps.onClick = event => {
      event.preventDefault()
      event.stopPropagation()
      document?.getElementById(`${callToActionHash}`)?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  if (teaserCtaSetting.target?.type === 'LXTeaser') {
    return <CmsCtaModal dataElementId={dataElementId} text={callToActionText} ctaStyle={ctaStyle} teaser={target} />
  }

  return (
    <LinkAsButton
      data-element-id={dataElementId}
      data-description={dataDescription}
      {...linkProps}
      className={styles['cms-cta-link-as-button']}
    >
      {teaserIcon && <CmsIcon teaserIcon={teaserIcon} />}
      <span>{callToActionText}</span>
    </LinkAsButton>
  )
}

export const getCtaStyle = (style: ITeaserCallToAction['style']): Pick<Button, 'color' | 'variant'> => {
  const [, variantName, colorName, themeName] = style.split('-')

  const variant = getVariant(variantName, colorName, themeName)

  const color = CtaColor[colorName] ?? CtaColor.primary

  return {
    variant,
    color: color as Button['color'],
  }
}
