import { createContext, useMemo } from 'react'
import { ModulesConfigProps } from '@typesApp/cms'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { IProduct } from '@typesApp/product'

export type PlacementContextType = {
  index: number
  placement: IPlacement
  pdpData?: IProduct
  teaserIndex: number
  modules: ModulesConfigProps[] | null
}

export type ContextWrapperData<T = unknown> = {
  data: T
}

export const PlacementContext = createContext<ContextWrapperData<unknown>>({ data: undefined })

interface PlacementProviderProps<T = unknown> extends React.PropsWithChildren {
  initialData: T
}

/**
 * TODO: improve context typing
 */
export const PlacementContextWrapper: React.FC<PlacementProviderProps> = props => {
  const { children, initialData } = props
  const value = useMemo(() => ({ data: initialData }), [initialData])

  return <PlacementContext.Provider value={value}>{children}</PlacementContext.Provider>
}
