import styled from '@mui/material/styles/styled'
import Button from '@components/UI/Button/Button'
import { Pill } from '@components/UI/Pill/Pill'
import ProductImage from '@components/ProductImage/ProductImage'

export const StyledInfoProductContainer = styled('div', {
  name: 'PSPStickBar',
  slot: 'InfoProductContainer',
})(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
}))

export const StyledProductImage = styled(ProductImage, {
  name: 'PSPStickBar',
  slot: 'ProductImage',
})(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.default,
  video: {
    height: 'auto',
    maxWidth: '140px',
    marginTop: '5px',
    width: '100%',
  },
}))

export const StyledFrameContainer = styled('div', {
  name: 'PSPStickBar',
  slot: 'FrameContainer',
})<{ haslens?: boolean }>(({ theme, haslens = false }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  lineHeight: theme.typography.body1.fontSize,
  fontWeight: 600,
  textTransform: 'uppercase',
  flexBasis: theme.spacing(6),
  width: haslens ? 204 : 'unset',
  flexGrow: haslens ? 1 : 'unset',
  [theme.breakpoints.down('sm')]: {
    width: haslens ? 110 : 'unset',
  },

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.h3.fontSize,
    textTransform: 'capitalize',
  },
}))

export const StyledPriceContainer = styled('div', {
  name: 'PSPStickBar',
  slot: 'PriceContainer ',
})<{ haslens?: boolean }>(({ theme, haslens = false }) => ({
  display: 'flex',
  gap: haslens ? 0 : theme.spacing(1),
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    gap: haslens ? 0 : theme.spacing(2),
  },

  h5: {
    margin: 0,

    fontSize: theme.typography.subtitle1.fontSize,
    lineHeight: theme.typography.body1.fontSize,
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.h4.fontSize,
    },
  },

  h6: {
    margin: 0,

    textDecoration: 'line-through',
    fontSize: theme.typography.subtitle2.fontSize,
    lineHeight: theme.typography.body1.fontSize,
    fontWeight: 'normal',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.h4.fontSize,
    },
  },
}))

export const StyledSelectLensesCTA = styled(Button, {
  name: 'PSPStickBar',
  slot: 'SelectLensesCTA',
})(({ theme }) => ({
  minWidth: 163,
  height: 40,
  flexGrow: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  whiteSpace: 'nowrap',
}))

export const StyledRoundPill = styled(Pill, {
  shouldForwardProp: prop => prop !== 'clicked',
  name: 'PDPStickyBar',
  slot: 'RoundPill',
})<{ clicked: boolean }>(({ theme, clicked }) => ({
  border: 'none',
  padding: theme.spacing(1),
  backgroundColor: clicked ? theme.palette.custom.light2.grey : theme.palette.custom.light.grey,
  color: clicked ? theme.palette.custom.white : theme.palette.custom.black,
  '&:hover': {
    backgroundColor: theme.palette.custom.white,
    color: theme.palette.custom.black,
    boxShadow: theme.shadows[19],
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(4),
  },
}))

export const LensSpan = styled('span', {
  name: 'PSPStickBar',
  slot: 'LensSpan',
})(({ theme }) => ({
  textTransform: 'lowercase',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

export const BrandTitle = styled('h5', {
  name: 'PSPStickBar',
  slot: 'BrandTitle',
})(({ theme }) => ({
  textTransform: 'uppercase',
  margin: 0,
  fontSize: theme.typography.body2.fontSize,
  lineHeight: theme.typography.h4.fontSize,
  fontWeight: 600,
}))
export const ModelName = styled('h6', {
  name: 'PSPStickBar',
  slot: 'ModelName',
})(({ theme }) => ({
  margin: 0,
  fontSize: theme.typography.subtitle1.fontSize,
  lineHeight: theme.typography.body1.fontSize,
  fontWeight: 'normal',
}))

export const StickyBarCtaContainer = styled('div', {
  name: 'PSPStickBar',
  slot: 'CtaContainer',
})(({ theme }) => ({
  alignItems: 'center',
  padding: '1rem 0',
  width: '100%',
  display: 'flex',
  [theme.breakpoints.down(331)]: {
    button: {
      fontSize: 14,
    },
  },
}))

export const StickyBarCta = styled(Button)(() => ({}))
