import React, { useState } from 'react'
import {
  StyledBoardWithIconsContainer,
  StyledBoardWithIconsItemContainer,
  StyledBoardWithIconsItem,
  BoardWithIconTitleContainer,
  BoardWithIconTextContainer,
  BoardWithIconLinkContainer,
} from './BoardWithIcons.style'
import Avatar from '@mui/material/Avatar'
import { IPlacement, isLXTeaser, isPictureMedia } from '@typesApp/cmsPlacement/Placement'
import { ICMExternalLink } from '@typesApp/cmsPlacement/CMExternalLink'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { ICMChannel } from '@typesApp/cmsPlacement/CMChannel'
import { TMedia } from '@typesApp/cmsPlacement/Media'
import HTMLReactParser from 'html-react-parser'
import { Link } from '@mui/material'
import { hostnameUrlsSelector } from '@redux/selectors/site'
import { useSelector } from 'react-redux'
import { trimText } from '@utils/common'
import { getSrcSetsImageFromCms } from '@utils/url'
import { ModalDialog } from '@components/UI/ModalDialogV2/ModalDialog'

export interface IProps {
  columnAmount: 1
  placement: IPlacement | ICMCollection
  backgroundColor?: string
  iconCount: number
  textColor?: string
  index?: number
}

const BoardWithIcons: React.FC<IProps> = ({
  placement,
  backgroundColor,
  iconCount,
  textColor,
  index: placementIndex,
}) => {
  const [teaserModal, setTeaserModal] = useState('')
  const hostnameUrls = useSelector(hostnameUrlsSelector)
  const teasers = 'items' in placement ? placement.items.filter(isLXTeaser) : placement.teasableItems.filter(isLXTeaser)
  const srcSets = (media: TMedia[]) => {
    const picture = media?.find(isPictureMedia)?.uriTemplate || ''
    const url = getSrcSetsImageFromCms('AVATAR_MEGA_MENU', picture, hostnameUrls)
    return url.mobile || ''
  }

  const teaserHasTarget = (teaser: ILXTeaser): boolean => {
    return (
      !!teaser.teaserLXCallToActionSettings &&
      !!teaser.teaserLXCallToActionSettings[0] &&
      !!teaser.teaserLXCallToActionSettings[0].target
    )
  }

  interface TeaserDialogProps {
    open: boolean
    content: string
    title: string
    onClose: () => void
  }

  const TeaserDialog: React.FC<TeaserDialogProps> = ({ open, content, title, onClose }) => {
    return (
      <ModalDialog open={open} title={title} onClose={onClose} hideFooter>
        <span dangerouslySetInnerHTML={{ __html: content }}></span>
      </ModalDialog>
    )
  }

  const getUrlLink = (teaser: ILXTeaser) => {
    return teaser.teaserLXCallToActionSettings[0].target?.type === 'CMExternalLink'
      ? (teaser.teaserLXCallToActionSettings[0].target as ICMExternalLink).url
      : (teaser.teaserLXCallToActionSettings[0].target as ICMChannel).formattedUrl
  }

  const getAvatarImage = teaser => (
    <Avatar
      alt={teaser.teaserTitle1}
      imgProps={{ crossOrigin: 'anonymous', loading: 'lazy' }}
      src={srcSets(teaser?.media)}
      sx={{ width: 48, height: 48 }}
      variant="square"
    />
  )

  const teaserCount = iconCount !== teasers.length ? Math.min(teasers.length, 6) : iconCount

  return (
    <StyledBoardWithIconsContainer
      backgroundColor={backgroundColor ?? placement.backgroundColor}
      iconCount={iconCount}
      textColor={textColor}
    >
      <BoardWithIconTitleContainer>
        {placement.collectionTitle && placement.collectionTitle}
      </BoardWithIconTitleContainer>
      <BoardWithIconTextContainer>
        {placement.collectionText && HTMLReactParser(placement.collectionText)}
      </BoardWithIconTextContainer>
      <StyledBoardWithIconsItemContainer
        backgroundColor={backgroundColor || ''}
        iconCount={teaserCount}
        textColor={textColor}
      >
        {teasers.map((teaser, index) => (
          <StyledBoardWithIconsItem key={teaser.id}>
            {teaser.name && teaserHasTarget(teaser) && (
              <BoardWithIconLinkContainer
                backgroundColor={backgroundColor || ''}
                iconCount={iconCount}
                textColor={textColor}
              >
                <Link
                  data-element-id={`${placementIndex}Placement_Tile${index}_CTA`}
                  data-description={`${trimText(teaser?.name ?? '')}`}
                  target="_blank"
                  href={getUrlLink(teaser)}
                >
                  {getAvatarImage(teaser)}
                  {teaser.teaserTitle1}
                </Link>
              </BoardWithIconLinkContainer>
            )}
            {teaser.name && !teaserHasTarget(teaser) && (
              <BoardWithIconLinkContainer
                data-element-id={`${placementIndex}Placement_Tile${index}_CTA`}
                data-description={`${trimText(teaser?.name ?? '')}`}
                backgroundColor={backgroundColor || ''}
                iconCount={iconCount}
                textColor={textColor}
                onClick={() => teaser.teaserText1 && setTeaserModal(teaser.teaserTitle1)}
              >
                {getAvatarImage(teaser)}
                <span>{teaser.teaserTitle1}</span>
              </BoardWithIconLinkContainer>
            )}
            <TeaserDialog
              open={teaserModal === teaser.teaserTitle1}
              content={teaser.teaserText1}
              title={teaser.teaserTitle1}
              onClose={() => setTeaserModal('')}
            ></TeaserDialog>
          </StyledBoardWithIconsItem>
        ))}
      </StyledBoardWithIconsItemContainer>
    </StyledBoardWithIconsContainer>
  )
}

export default BoardWithIcons
