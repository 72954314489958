import React, { useContext } from 'react'
import clsx from 'clsx'
import GenericBanner from '../GenericBanner'
import {
  ContextWrapperData,
  PlacementContext,
  PlacementContextType,
} from '@components/Cms/CMSContent/PlacementContextWrapper/PlacementContextWrapper'
import { TextModuleBanner } from '../TextModuleBanner'
import styles from './styles/BoxWithMargin.module.scss'
import { CMS_MODULES } from '@components/Cms/constants'

const BoxWithMargin: React.FC = () => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  if (!context) return null

  const { data } = context as ContextWrapperData<PlacementContextType>
  const reflect = data?.placement?.placementReflect ?? false
  const viewType = data?.placement?.viewtype ?? CMS_MODULES.BOX_WITH_MARGIN

  return (
    <div
      className={clsx(
        viewType === CMS_MODULES.BOX_WITH_MARGIN ? styles.boxWithMarginWrapper : styles.boxWithoutMarginWrapper,
        { [styles.reverse]: !!reflect }
      )}
    >
      <TextModuleBanner
        className={styles.boxWithMarginTextWrapper}
        showCmsIcon={false}
        termsAndConditionsClassName={styles.termsAndConditions}
      />
      <GenericBanner
        crop="BOX_WITH_MARGIN_BANNER"
        className={clsx(
          viewType === CMS_MODULES.BOX_WITH_MARGIN
            ? styles.boxWithMarginMediaWrapper
            : styles.boxWithoutMarginMediaWrapper
        )}
        lazy
        hideTermsAndConditions
        textModuleClassName={styles.hideTextModule}
        videoWrapperClassName={styles.boxWithMarginVideoWrapper}
      />
    </div>
  )
}

export default BoxWithMargin
