import { areEquals } from '@utils/common'
import { CtaColor, CtaVariant } from './constants'
import { Button } from '@components/UI-CSS/Button'

export const getHref = (currentPath: string | undefined, url: string, callToActionHash: string | undefined): string => {
  const hasActionHash = Boolean(callToActionHash)

  if (url && hasActionHash && !areEquals(currentPath || '', url)) {
    return `${url}#${callToActionHash}`
  }

  if (areEquals(currentPath || '', url) && hasActionHash) {
    return `#${callToActionHash}`
  }

  return url ?? '#NOT_FOUND_FORMATTED_URL'
}

export const getVariant = (variantName: string, colorName: string, themeName: string): Button['variant'] => {
  if (colorName === CtaColor.primary) {
    if (variantName === CtaVariant.outline) {
      return themeName === 'light' ? 'outlined-light' : 'outlined-dark'
    } else {
      return themeName === 'light' ? 'contained-light' : 'contained-dark'
    }
  }
  return variantName === CtaVariant.outline ? 'outlined' : 'contained'
}
