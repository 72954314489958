import React from 'react'
import { PRODUCT_SOLDOUT_STATUS } from '../../../constants/product'
import { IProduct } from '../../../types/product'
import { getModelName, getSoldOut } from '../../../utils/productAttributes'
import { getProductImageAltLabel } from '@utils/productImage'
import { PDP_PRODUCT_IMAGE_FRONT_VIEW_SEQUENCE } from '@components/ProductImage/ProductImage'
import {
  ProductColorSelectorItem,
  ProductColorSelectorItemImage,
} from '@pages_views/ProductDetails/components/ProductColorItem.style'

interface IProductColorItem {
  product: IProduct // cluster item
}

const ProductColorItem: React.FC<IProductColorItem> = ({
  product, // cluster item
}) => {
  const soldOutStatus = getSoldOut(product.items?.[0] || product)
  const isSoldOut = soldOutStatus === PRODUCT_SOLDOUT_STATUS.SOLDOUT
  const name = getModelName(product)

  return (
    <ProductColorSelectorItem isSoldOut={isSoldOut}>
      <ProductColorSelectorItemImage
        alt={getProductImageAltLabel(product)}
        noImageStyle={{
          transform: 'translateX(0)',
          height: '90%',
        }}
        width={160}
        attachments={product.attachments}
        usage="PLP"
        sequence={PDP_PRODUCT_IMAGE_FRONT_VIEW_SEQUENCE}
        backgroundColor="#FFF"
        lazy={false}
        preload={false}
      />
    </ProductColorSelectorItem>
  )
}

export default ProductColorItem
