import clsx from 'clsx'
import { memo } from 'react'
import { CMSContent } from '@components/Cms/CMSContent'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { AssociatedProduct, IProduct } from '@typesApp/product'
import LoadingSkeleton from '@components/Cms/CmsComponents/CmsCommonMedia/LoadingSkeleton'

const PlacementLayout: React.FC<{
  placements: IPlacement[]
  currentProduct?: IProduct
  suggestedProducts?: AssociatedProduct[]
  loading?: boolean
  isPLP?: boolean
  contentVisibilityStart?: number
  className?: string
}> = ({
  placements,
  currentProduct,
  suggestedProducts,
  loading,
  isPLP = false,
  contentVisibilityStart = 1,
  className,
}) => {
  return (
    <div className={clsx('placements-container', className)}>
      {loading ? (
        <LoadingSkeleton height={'100vh'} />
      ) : placements && placements.length === 0 ? null : (
        <CMSContent
          placements={placements}
          currentProduct={currentProduct}
          suggestedProducts={suggestedProducts}
          activePlacements={activePlacements}
          isPLP={isPLP}
          contentVisibilityStart={contentVisibilityStart}
        />
      )}
    </div>
  )
}

const activePlacements: IViewType[] = [
  'full-width-banner',
  'landscape-banner',
  'boards-with-fields-below',
  'text-module',
  'squat-banner',
  'top-page-banner',
  'box-with-margin',
  'grid-of-boards-two-columns',
  'grid-of-boards-three-columns',
  'square-boards-with-split',
  'square-boards-without-split',
  'faqs',
  'grid-of-products',
  'dcw-products',
  'combo-mini-slider-plus-box-all-fields',
  'query-list',
  'wall-of-brands',
  'you-may-also-like',
  'size-guide',
  'Algolia Recommendations',
  'anchor-button',
  'm-04.1-grid-of-cards',
  'box-and-2-products',
  'box-and-4-products',
  'single-banner-with-side-fields',
  'm-social-video',
  'plain-slider',
  'registration-form-module-1',
  'registration-form-module-2',
  'trust-pilot',
  'two-column-banner-lg',
  'fullwidth-promo-banner',
  'fullwidth-promo-banner-2',
  'fullwidth-promo-banner-3',
  'fullwidth-promo-banner-4',
  'value-proposition-banner',
  'you-may-also-like',
  'newsletter-subscribe-banner',
  'Algolia Recommendations',
  'anchor-buttons',
  'carousel-banner-with-products',
  'four-products-carousel',
  'box-and-2-products',
  'box-and-4-products',
  'recently-viewed',
  'size-guide',
  'cly-four-products-category-tabs',
  'three-products-carousel',
  'three-item-board-carousel',
  'cly-products-category-tabs',
  'board-with-4-icons',
  'board-with-6-icons',
]

export default memo(PlacementLayout)
