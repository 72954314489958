import React, { useState } from 'react'
import clsx from 'clsx'
import HTMLReactParser from 'html-react-parser'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { StyledToast } from './Toast'
import { ToastThemeVariant, ToastThemeVariantValues } from './Toast/Toast.type'
import styles from './styles/TermsAndConditions.module.scss'

type TermsAndConditionsProps = {
  className?: string
  dataElementId?: string
  teaser: ILXTeaser | undefined
}

export const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ className, dataElementId, teaser }) => {
  const terms = teaser?.targetsTermsAndConditions
  const { target, text, style } = terms ?? {}

  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = () => setIsOpen(!isOpen)

  const variantFromStyle = style?.split('-').pop() as ToastThemeVariant
  const toastVariant: ToastThemeVariant = ToastThemeVariantValues.hasOwnProperty(variantFromStyle)
    ? variantFromStyle
    : 'primary'
  const variant: ToastThemeVariant = ToastThemeVariantValues.hasOwnProperty(variantFromStyle)
    ? variantFromStyle
    : 'tertiary'

  const themeStyles = {
    primary: 'white',
    secondary: '#1c4da1',
    tertiary: 'black',
  }

  const buttonColor = themeStyles[variant]

  return !target ? null : (
    <div className={clsx('terms-and-conditions', styles.termsAndConditions, className)}>
      <button
        aria-label={text}
        data-element-id={`${dataElementId ?? 'TermsAndConditions'}_${isOpen ? 'SHOW' : 'HIDE'}`}
        className={styles.termsAndConditionsCta}
        onClick={handleOpen}
        style={{ color: buttonColor }}
      >
        <SVGIcon library="validation" name="info" color={buttonColor} />
        {HTMLReactParser(text ?? '')}
      </button>

      <StyledToast
        body={target.detailText}
        headingText={target.title}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        themeVariant={toastVariant}
      />
    </div>
  )
}
