import React, { FC } from 'react'
import { StyledTooltip } from '@components/UI/Tooltip/'
import { useTranslation } from 'next-i18next'
import { PRODUCT_TYPES_KEYS } from '@constants/product'
import { ProductPriceLabel } from '@pages_views/ProductDetails/components/ProductPrice.style'
import { TotalContainer } from '@pages_views/ProductDetails/components/ProductPriceAlgolia.style'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

type PriceTotalContainerProps = {
  productType: string | undefined
  isPDP?: boolean
  isStickyBar?: boolean
  hideTooltip?: boolean
}

const TotalLabelContainer: FC<PriceTotalContainerProps> = props => {
  const { productType, isPDP, isStickyBar, hideTooltip } = props
  const { t: translate } = useTranslation()

  const isContactLensesPDP = isPDP && productType === PRODUCT_TYPES_KEYS.CONTACT_LENSES
  const totalLabel = translate(
    isContactLensesPDP
      ? 'ProductDetails.Labels.ContactLenses.Price.Total'
      : 'ProductDetails.Labels.CLAccessories.Price.Total'
  )
  return (
    <TotalContainer>
      <ProductPriceLabel isBold={isPDP} isStickyBar={isStickyBar}>
        {totalLabel}
      </ProductPriceLabel>
      {isContactLensesPDP && !hideTooltip && (
        <StyledTooltip
          contentVariant={{
            type: 'textonly',
            body: translate('ContactLenses.Tooltip.Total'),
          }}
          colorVariant={'darkgray'}
          placement={'right'}
        >
          <span>
            <SVGIcon library="validation" name="info" color="black" />
          </span>
        </StyledTooltip>
      )}
    </TotalContainer>
  )
}

export default TotalLabelContainer
