import React from 'react'
import {
  StyledGridProductLayout,
  StyledProductLabelWrap,
  StyledProductWrap,
} from '@components/ProductGrid/components/ProductGridView/ProductGridView.style'
import { badgeLabelsMap, updateAttributes } from '@components/UI/ProductLabel/helper'
import { usePlpState } from '@utils/Plp/PlpContext'
import { useTranslation } from 'next-i18next'
import ProductLabel from '@components/UI/ProductLabel'
import { ProductTileAlgolia } from '@components/ProductTile'
import { IUiState } from '@features/ui/slice'
import { IAlgoliaHit } from '@typesApp/product'

type ProductGridViewLayoutProps = {
  index: number
  isClustered: boolean
  product: IAlgoliaHit
  productListingLayout?: IUiState['productsMobileListingLayout']
  selectedProduct: React.MutableRefObject<HTMLDivElement | null>
  variant?: string
}

export const ProductGridViewLayout: React.FC<ProductGridViewLayoutProps> = ({
  index,
  isClustered,
  product,
  productListingLayout,
  selectedProduct,
  variant,
}) => {
  const { t } = useTranslation()
  const plpState = usePlpState()
  const lastSelectedProduct = plpState.lastSelectedProduct
  const updatedAttributes = updateAttributes(product['attributes'])
  const matchedBadgeKey = Object.keys(badgeLabelsMap).find(badgeKey => badgeKey in updatedAttributes)

  return (
    <StyledGridProductLayout
      key={product.productId}
      ref={!selectedProduct.current && lastSelectedProduct === product.productId ? selectedProduct : undefined}
      listingLayout={productListingLayout}
    >
      <StyledProductWrap id={`product_${product.productId}`}>
        <StyledProductLabelWrap>
          {matchedBadgeKey && <ProductLabel badge={matchedBadgeKey} label={t(`ProductLabel.${matchedBadgeKey}`)} />}
        </StyledProductLabelWrap>
        <ProductTileAlgolia
          isClustered={isClustered}
          product={product}
          tileIndex={index}
          variant={variant ? variant : 'plp'}
        />
      </StyledProductWrap>
    </StyledGridProductLayout>
  )
}
