import styled from '@mui/material/styles/styled'

export const StyledPaginationLayoutContainer = styled('div', {
  name: 'PaginationLayout',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(),

  div: {
    marginTop: `-${theme.spacing(0.5)}`,
    display: 'flex',
    flexDirection: 'row',
  },

  svg: {
    height: 16,
    cursor: 'pointer',
  },
}))

export const StyledPaginationContainer = styled('div', {
  name: 'Pagination',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  width: 24,
  height: 24,
  borderRadius: '50%',
  border: 'none',
  background: 'none',
  position: 'relative',
  zIndex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  margin: `0 ${theme.spacing(1)}`,

  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.background.dark.quaternary,
  },

  '&.selected': {
    a: {
      fontWeight: '600',
      color: theme.palette.color.black,
    },
  },

  '&.selected::after': {
    content: '""',

    position: 'absolute',
    zIndex: -1,
    top: 0,
    left: 0,

    width: '100%',
    height: '100%',

    borderRadius: '50%',
    backgroundColor: theme.palette.background.dark.secondary,
  },

  a: {
    fontSize: '10px',
    fontWeight: '300',

    color: theme.palette.text.light.secondary,
  },
}))
