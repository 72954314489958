import React, { useContext } from 'react'
import GenericBanner from '@components/Cms/CMSContent/components/GenericBanner'
import {
  ContextWrapperData,
  PlacementContext,
  PlacementContextType,
} from '@components/Cms/CMSContent/PlacementContextWrapper/PlacementContextWrapper'
import { CRITICAL_INDEX } from '@components/Cms/constants'
import styles from './styles/FullWidthBanner.module.scss'

const FullWidthBanner: React.FC = () => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  if (!context) return null
  const { data } = context as ContextWrapperData<PlacementContextType>

  return (
    <GenericBanner
      crop="FULL_WIDTH_BANNER_L"
      className={styles.fullWidthBanner}
      lazy={data.index >= CRITICAL_INDEX}
      termsAndConditionsClassName={styles.fullWidthBannerTermsConditions}
      videoWrapperClassName={styles.fullWidthBannerVideoWrapper}
    />
  )
}

export default FullWidthBanner
