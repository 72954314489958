import { useState } from 'react'
import {
  MultiDropDownContent,
  DropDownContainer,
  DropDownListItem,
  StyledDropDownListItemButton,
  DropDownPopper,
  StyledContactLensesSelectValueField,
  DropDownIcon,
  DropDownContainerInner,
} from './ContactLensesSelect.style'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { EyeContactLensAttribute, EyeContanctLensOption } from '@typesApp/prescription'
import { useTranslation } from 'next-i18next'

export interface ContactLensesSelectProps {
  id: EyeContactLensAttribute
  eye: string
  onSelectValueChange?: (eye: string, id: EyeContactLensAttribute, value?: string | null) => void
  value?: string | null
  options: EyeContanctLensOption[]
  open?: boolean
  onDropDownToggle?: (open?: boolean) => void
  error?: boolean
  disabled?: boolean
  multifield?: boolean
  defaultLabel?: string | null
  listheading?: React.ReactNode
  className?: string
  label?: string
}

const getOptionByIndex = (index: number, options?: ContactLensesSelectProps['options']) => {
  return options?.find((_option, i) => i === index)?.value
}

const getOptionTextByValue = (value: string | null, options?: ContactLensesSelectProps['options']) => {
  return options?.find(option => option.value === value)?.text
}

export const ContactLensesSelect: React.FC<ContactLensesSelectProps> = ({
  id,
  eye,
  onSelectValueChange,
  onDropDownToggle,
  error,
  disabled,
  value,
  options,
  multifield,
  listheading,
  className,
  label,
  ...props
}) => {
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState<number>(-1)
  const [open, setIsOpen] = useState<boolean>(false)

  const getComboboxAriaLabel = (eye, id, menu) => {
    const formattedEye = eye.charAt(0).toUpperCase() + eye.slice(1).toLowerCase()

    const formattedId = id
      .replace(/^x_/, '')
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/\b\w/g, char => char.toUpperCase())

    return `${formattedEye} ${formattedId} ${menu}`
  }

  const handleListKeyDown = e => {
    let selectedOptionIndex: number
    switch (e.key) {
      case 'Escape':
        e.preventDefault()
        onDropDownToggle && onDropDownToggle(false)
        break
      case 'ArrowUp':
        e.preventDefault()
        selectedOptionIndex = selectedOption - 1 >= 0 ? selectedOption - 1 : options.length - 1
        setSelectedOption(selectedOptionIndex)
        onSelectValueChange && onSelectValueChange(eye, id, getOptionByIndex(selectedOptionIndex, options))
        break
      case 'ArrowDown':
        e.preventDefault()
        selectedOptionIndex = selectedOption === options.length - 1 ? 0 : selectedOption + 1
        setSelectedOption(selectedOptionIndex)
        onSelectValueChange && onSelectValueChange(eye, id, getOptionByIndex(selectedOptionIndex, options))
        break
      default:
        break
    }
  }
  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)} {...props}>
      <DropDownContainer
        data-name={eye + '_' + id}
        disabled={disabled || false}
        error={error || false}
        open={open || false}
        onClick={() => !disabled && setIsOpen(!open)}
        className={className}
      >
        <DropDownContainerInner disabled={disabled || false} error={error || false} open={open || false}>
          <StyledContactLensesSelectValueField
            type="button"
            role="combobox"
            aria-label={getComboboxAriaLabel(eye, id, t('ContactLenses.Labels.Menu'))}
            aria-haspopup="listbox"
            aria-controls={`listbox-${eye}-${id}`}
            aria-expanded={open}
            aria-activedescendant={selectedOption !== -1 ? `option-${options[selectedOption]?.text}` : undefined}
            className={open ? `${className} expanded` : className}
            onKeyDown={handleListKeyDown}
          >
            {getOptionTextByValue(value || null, options) || '-'}
          </StyledContactLensesSelectValueField>
          <DropDownPopper open={open} className={className}>
            {listheading && listheading}
            <MultiDropDownContent
              id={`listbox-${eye}-${id}`}
              role="listbox"
              multifield={multifield}
              aria-activedescendant={selectedOption !== -1 ? `option-${options[selectedOption]?.text}` : undefined}
              tabIndex={-1}
              onKeyDown={handleListKeyDown}
              aria-label={label}
            >
              {options?.map(({ value, text }, index) => {
                return (
                  <DropDownListItem
                    key={`${eye}_${index}_${text}`}
                    id={`option-${text}`}
                    role="option"
                    aria-selected={selectedOption === index}
                    multifield={multifield}
                    className={`${className} ${selectedOption === index ? 'selected' : ''}`}
                  >
                    <StyledDropDownListItemButton
                      variant="body1"
                      className={className}
                      onClick={() => {
                        if (!disabled) {
                          onSelectValueChange && onSelectValueChange(eye, id, value)
                          setSelectedOption(index)
                          setIsOpen(false)
                        }
                      }}
                    >
                      {text}
                    </StyledDropDownListItemButton>
                  </DropDownListItem>
                )
              })}
            </MultiDropDownContent>
          </DropDownPopper>
          <DropDownIcon open={open} onClick={() => !disabled && setIsOpen(!open)} />
        </DropDownContainerInner>
      </DropDownContainer>
    </ClickAwayListener>
  )
}
