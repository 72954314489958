import React, { useState } from 'react'
import HTMLReactParser from 'html-react-parser'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { ToastThemeVariant, ToastThemeVariantValues } from '@components/UI/ToastImproved/Toast.type'
import { StyledDetailsInfo, StyledTermsConditionWrapper, StyledTermsToast } from './TermsAndConditions.style'
import useBreakpoints from '@hooks/useBreakpoints'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { ITermsAndConditions } from '@typesApp/cmsPlacement/LXTeaser'

type Props = {
  terms?: ITermsAndConditions
  overlayTextAlign?: string
  alignment?: IViewType | string | undefined
  viewtype?: string | undefined
  parentbg?: string
  twoColumns?: boolean
  counter?: {
    placementIndex?: number
    teaserIndex?: number
  }
}
/**
 * @deprecated
 * use src/components/Cms/CmsComponents-CSS/TermsAndConditions
 */
const TermsAndConditionsCta: React.FC<Props> = ({ terms, twoColumns = false, counter }) => {
  const text = terms?.text || ''
  const target = terms?.target
  const style = terms?.style || ''
  const [isOpen, setIsOpen] = useState(false)
  const { isViewportWidthAbove1440 } = useBreakpoints()
  const handleOpen = () => setIsOpen(!isOpen)
  const handleClose = () => setIsOpen(false)

  if (!target) return null
  const variantFromStyle = style?.split('-').pop() as ToastThemeVariant
  const variant: ToastThemeVariant = ToastThemeVariantValues.hasOwnProperty(variantFromStyle)
    ? variantFromStyle
    : 'primary'

  return (
    <>
      <StyledTermsConditionWrapper>
        <StyledDetailsInfo
          onClick={handleOpen}
          aria-label={text}
          data-element-id={`${counter?.placementIndex}Placement_Banner${counter?.teaserIndex}_${isOpen ? 'SHOW' : 'HIDE'}`}
        >
          <SVGIcon library="validation" name="info" />
          {HTMLReactParser(text)}
        </StyledDetailsInfo>
        <StyledTermsToast
          onClose={handleClose}
          headingText={target.title}
          body={target.detailText}
          open={isOpen}
          themeVariant={variant}
          isThinMargin={twoColumns && !isViewportWidthAbove1440}
        />
      </StyledTermsConditionWrapper>
    </>
  )
}

export default TermsAndConditionsCta
