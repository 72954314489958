import { ChangeEvent, ComponentType, useEffect } from 'react'
import { StyledPagination } from '@components/UI/Pagination/Pagination.style'
import {
  PaginationProps as MuiPaginationProps,
  PaginationItemProps as MuiPaginationItemProps,
  SvgIconProps,
  PaginationItem,
} from '@mui/material'
import { IconChevronLeft, IconChevronRight } from '../Icons/VD/General'
import Link from 'next/link'
import { APP_MAIN_CONTAINER_ID } from '@constants/ui'
import { usePathname } from 'next/navigation'
import { usePageType } from '@foundation/hooks/usePageType'
import { useDispatch } from 'react-redux'
import { setTotaPaginationPages } from '@features/ui/slice'
import { PAGE_TYPES_TO_CHECK_INDEXING } from '@constants/common'

export interface PaginationProps {
  page: MuiPaginationProps['page']
  count: MuiPaginationProps['count']
  boundaryCount?: MuiPaginationProps['boundaryCount']
  siblingCount?: MuiPaginationProps['siblingCount']
  renderItem?: MuiPaginationProps['renderItem']
  components?: MuiPaginationItemProps['components']
  onChange?: (event: ChangeEvent<unknown>, page: number) => void
  refine?: (page: number) => void
}

function Pagination(props: PaginationProps) {
  const renderIcon = (Icon: ComponentType<SvgIconProps>) => <Icon sx={{ fontSize: 16 }} />
  const pathname = usePathname()
  const { pageType } = usePageType()
  const dispatch = useDispatch()
  const { refine, count, onChange } = props

  const onPageClick = (pageNumber: number) => {
    if (refine) {
      refine(pageNumber - 1)
    }
    const appMainContainerElement = document.getElementById(APP_MAIN_CONTAINER_ID)
    appMainContainerElement?.scrollTo({ top: 0 })
  }

  useEffect(() => {
    if (pageType && PAGE_TYPES_TO_CHECK_INDEXING.includes(pageType)) {
      dispatch(setTotaPaginationPages(count))
    }

    return () => {
      dispatch(setTotaPaginationPages(0))
    }
  }, [pageType, count])

  return (
    <StyledPagination
      data-name="paginationNavigation"
      {...props}
      renderItem={item => {
        const isPrev = item.type === 'previous'
        const isNext = item.type === 'next'
        const isOnLastPaginationPage = item.page && item.page - 1 === count

        if (isOnLastPaginationPage) {
          return (
            <PaginationItem
              {...item}
              component="button"
              slots={{
                previous: () => renderIcon(IconChevronLeft),
                next: () => renderIcon(IconChevronRight),
              }}
              rel="nofollow"
            />
          )
        }

        return (
          <PaginationItem
            component={Link}
            href={{
              pathname: pathname,
              query: { page: item.page },
            }}
            slots={{
              previous: () => renderIcon(IconChevronLeft),
              next: () => renderIcon(IconChevronRight),
            }}
            {...item}
            onClick={e => {
              e.preventDefault()
              refine ? onPageClick(item.page || 1) : onChange && onChange(e, item.page || 1)
            }}
            rel={isPrev ? 'prev' : isNext ? 'next' : undefined}
            data-name={`page${item.page}`}
          />
        )
      }}
    />
  )
}

export default Pagination
