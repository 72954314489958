import { styled } from '@mui/system'
import { Radio as MuiRadio, radioClasses as MuiRadioClasses } from '@mui/material'

const StyledRadio = styled(MuiRadio, {
  name: 'Radio',
})(({ theme }) => ({
  [`&.${MuiRadioClasses.root}`]: {
    padding: theme.spacing(0),

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  '&.MuiRadio-colorError': {
    color: theme.palette.error.main,
  },

  '&.Mui-disabled': {
    color: theme.palette.background[4],
  },
}))

export default StyledRadio
