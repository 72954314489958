import { useDispatch } from 'react-redux'
import config from '@configs/index'
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_KEYS } from '@constants/product'
import { toggleAddToCart } from '@features/product/slice'
import { ProductRightColumnProps } from '@typesApp/product'
import { getSoldOut } from '@utils/productAttributes'
import { OmniChannelCarousel } from '@pages_views/ProductDetails/components/OmniChannelCarousel/OmnichannelCarousel'
import ProductColorSelector from '@pages_views/ProductDetails/components/ProductColorSelector'
import ProductAvailableNotificationDrawer from '@pages_views/ProductDetails/components/ProductAvailableNotificationDrawer'
import ProductCtaContainer from '@pages_views/ProductDetails/components/ProductCtaContainer'
import { ProductDetailsList } from '@pages_views/ProductDetails/components/ProductDetailsList'
import { ProductNameSection } from '@pages_views/ProductDetails/components/ProductNameSection'
import { StyledSeparator } from '@pages_views/ProductDetails/ProductDetails.style'
import { ShippingTimesBanner } from '@pages_views/ProductDetails/components/ShippingTimesBanner/ShippingTimesBanner'
import { StyledSpecialFrameInfo } from '../StyledSpecialFrameInfo'
import { ProductPriceAlgolia } from '../ProductPriceAlgolia'

export const AccessoriesPdpRightColumn = ({
  partNumber,
  currentProduct,
  soldOutStatus,
  productInCart,
  addToCartButtonFail,
  ctaRef,
  placements,
  cluster,
  onClusterProductClick,
  isSoldout,
  isRoxable,
  onlyFewPieces,
  isLoading,
}: ProductRightColumnProps) => {
  const dispatch = useDispatch()

  dispatch(toggleAddToCart(true))

  const areAllClusterItemsSoldOut = cluster?.length
    ? cluster.every(i => getSoldOut(i) === PRODUCT_SOLDOUT_STATUS.SOLDOUT)
    : false
  const displayable = currentProduct.displayable
  const showProductDetailsList = !!config.pdp.showProductDetailsInRightColumn

  return !currentProduct ? null : (
    <>
      <ProductNameSection currentProduct={currentProduct} isSoldout={isSoldout} />

      {!isSoldout ? (
        <>
          <StyledSpecialFrameInfo currentProduct={currentProduct} />
          <StyledSeparator />
        </>
      ) : null}

      {cluster && cluster.length >= 0 && !areAllClusterItemsSoldOut && (
        <>
          <ProductColorSelector
            currentProductItem={currentProduct}
            soldOutStatus={soldOutStatus}
            cluster={cluster}
            selectedPartNumber={partNumber}
            onClick={onClusterProductClick}
          />
          <StyledSeparator />
        </>
      )}

      {displayable && currentProduct.x_price && (
        <>
          <ProductPriceAlgolia isLoading={isLoading} soldOutStatus={soldOutStatus} />
          <ProductCtaContainer
            productInCart={productInCart}
            type={soldOutStatus}
            currentProduct={currentProduct}
            error={addToCartButtonFail}
            ctaRef={ctaRef}
            roxable={isRoxable}
            addToCartFillType={'fill'}
            onlyFewPieces={onlyFewPieces}
          />
        </>
      )}
      <ShippingTimesBanner productType={PRODUCT_TYPES_KEYS.ACCESSORIES} />

      <ProductAvailableNotificationDrawer
        attachments={currentProduct?.attachments || []}
        attributes={currentProduct?.productAttributes}
        product={currentProduct || undefined}
      />
      {placements && <OmniChannelCarousel placements={placements} />}

      {showProductDetailsList && <ProductDetailsList type={soldOutStatus} currentProduct={currentProduct} />}
    </>
  )
}
