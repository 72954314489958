import config from '@configs/index'
import { StyledLogoWrapper } from './Logo.style'
import { LOGOS_MAP } from './constants'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { useSite } from '@foundation/hooks/useSite'

const supportedLocales = config.availableLocales || []

const supportedLocaleMap = {
  'be-be': 'BE',
  'en-au': 'AU',
  'en-ca': 'CA',
  'en-nz': 'NZ',
  'es-es': 'ES',
  'fr-ca': 'CA',
  'fr-fr': 'FR',
  'fr-be': 'BE',
  'en-ie': 'IE',
  'it-it': 'IT',
  'nl-nl': 'NL',
  'nl-be': 'BE',
  'en-uk': 'GB',
  'de-de': 'DE',
}

export type LogoProps = {
  locale?: (typeof supportedLocales)[number]
  variant?: 'black' | 'white' | 'lightcolor'
  className?: string
  showSymbolOnly?: boolean
}

function Logo(props: LogoProps) {
  const { langCode: locale } = useStoreIdentity() as { langCode: (typeof supportedLocales)[number] }
  const { mySite } = useSite()
  const { variant, className, showSymbolOnly } = props

  const mappedLocale = locale ? supportedLocaleMap[locale] : null
  const brandName = mySite?.xStoreCfg?.brand?.toLowerCase()

  if (!brandName || !mappedLocale) {
    return null
  }

  return (
    <StyledLogoWrapper className={`${className}`} data-variant={variant} data-locale={mappedLocale}>
      {LOGOS_MAP[brandName]({ mappedLocale, variant, showSymbolOnly })}
    </StyledLogoWrapper>
  )
}
export default Logo
