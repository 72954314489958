import React, { useMemo } from 'react'
import { useSearchParams } from 'next/navigation'

// components
import { PlacementContainer } from '@components/Cms/CMSContent/components/PlacementContainer'
import { PlacementContextWrapper } from '@components/Cms/CMSContent/PlacementContextWrapper/PlacementContextWrapper'
// constants
import { DEFAULT_ACTIVE_PLACEMENTS } from '@components/Cms/CMSContent/constants'
import { DEFAULT_VIEW_TYPE } from '@components/Cms/CMSContent/constants'
// types
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { IProduct } from '@typesApp/product'
// utils
import { getCmsModulesByPlacement, getDefaultViewPlacement, isPlacementVisible } from './placementHelpers'
import styles from './styles/PlacementsSwitch.module.scss'
import { useDynamicCmsContent } from '@foundation/hooks/useDynamicCmsContent'

/*
 * preLoadedPlacementCount - since placementsswitch is rendered separately, the index of placement is affected
 */
export interface PlacementsSwitchProps {
  activePlacements?: IViewType[]
  isPLP?: boolean
  pdpData?: IProduct
  placements?: IPlacement[]
  preLoadedPlacementCount?: number
}

export const PlacementsSwitch: React.FC<PlacementsSwitchProps> = ({
  activePlacements = DEFAULT_ACTIVE_PLACEMENTS,
  isPLP = false,
  pdpData,
  placements,
  preLoadedPlacementCount = 0,
}) => {
  const searchParams = useSearchParams()
  const activePlacementsWithDefault = [...activePlacements, DEFAULT_VIEW_TYPE] as IViewType[]
  const { dynamicContentBanners: dynamicPlacements } = useDynamicCmsContent(placements ?? [])

  const visiblePlacements = useMemo(() => {
    return dynamicPlacements
      ?.filter(placement => isPlacementVisible(placement.viewtype, activePlacementsWithDefault))
      .filter(placement => {
        if (!isPLP) {
          return placement
        } else if (!searchParams?.get('offset')) {
          return placement
        } else {
          return placement?.name !== 'PLP_placement_1'
        }
      })
      .map((placement, placementIndex) => {
        const index = placementIndex + preLoadedPlacementCount
        const modules = getCmsModulesByPlacement({
          activePlacements: activePlacementsWithDefault,
          index,
          isPLP,
          pdpData,
          placement,
        })

        if (modules !== null) {
          const initialData = {
            placement,
            teaserIndex: index,
            modules,
            index,
            pdpData,
          }

          // Default view type is special
          if (placement.viewtype === DEFAULT_VIEW_TYPE) {
            const firstItem = getDefaultViewPlacement(placement)
            if (
              !firstItem ||
              !(isPlacementVisible(firstItem?.viewtype, activePlacementsWithDefault) || firstItem?.type === 'CMHTML')
            ) {
              return null
            }
          }

          return (
            <PlacementContextWrapper key={`${placement.name}-${index}`} initialData={{ ...initialData }}>
              <PlacementContainer {...initialData} />
            </PlacementContextWrapper>
          )
        }
        return null
      })
  }, [JSON.stringify(placements)])

  return (
    <div className="home__main-container">
      {placements && placements.length > 0 && <div className={styles.wrapper}>{visiblePlacements}</div>}
    </div>
  )
}
