import { IconButton } from '@components/UI/IconButton'
import { ProductMedia } from '@components/common/Media/ProductMedia'
import { Carousel } from '@components/common/components/Carousel'
import styled from '@mui/material/styles/styled'
import { Z_INDEX_LV2, Z_INDEX_LV4, Z_INDEX_LV5 } from '../../../../constants/ui'
import { ProductFullPageGalleryProps } from '../ProductFullPageGallery'
import { SwiperProps } from 'swiper/react'

const ProductImageCarousel = styled(Carousel, {
  name: 'PdpDetails',
  slot: 'ImageSlider',
})(({ theme }) => ({
  height: '100%',

  '.slide .lazy-load-image-background': {
    height: '100%',
  },

  '.slide img': {
    objectFit: 'contain',
    height: '100%',
    backgroundColor: theme.palette.custom.white, // white background because the image is transparent
  },
}))

type SliderThumbNailsWrapper = {
  isGalleryMode?: boolean
  height?: number
} & Partial<SwiperProps>

const SliderThumbNailsWrapper = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderThumbNailsWrapper',
  shouldForwardProp: prop => prop !== 'isGalleryMode' && prop !== 'height',
})<SliderThumbNailsWrapper>(({ isGalleryMode, theme, height }) => ({
  marginTop: isGalleryMode ? 0 : theme.spacing(4),
  marginBottom: theme.spacing(2),
  height: `${height}px`,
  display: 'contents',
}))

const WrapperThumbnails = styled('div', {
  name: 'PdpDetails',
  slot: 'WrapperThumbnail',
  shouldForwardProp: prop => prop !== 'isGalleryMode',
})<{ isGalleryMode?: boolean }>(({ isGalleryMode, theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
    marginLeft: isGalleryMode ? theme.spacing(16.5) : 0,
    marginTop: isGalleryMode ? 0 : theme.spacing(6),
    width: '80px',
    flexShrink: 0,
  },
}))

const ThumbnailButtonContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderNavigationContainer',
})(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  width: `calc(${theme.spacing(20)} - 2px )`,
  height: `calc(${theme.spacing(14)} - 2px )`,
}))

const SliderContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderContainer',
})(() => ({
  width: '100%',
  position: 'relative',
}))

const SliderThumbNailContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'selected' && prop !== 'isProductImage',
  name: 'PdpDetails',
  slot: 'SliderThumbNailContainer',
})<{
  selected?: boolean
  isProductImage?: boolean
}>(({ selected, theme }) => ({
  width: '100%',
  cursor: 'pointer',
  position: 'relative',
  border: `1px solid  ${selected ? theme.palette.neutral.tones[60] : theme.palette.custom.white}`,
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.common.white,

  '&:hover': {
    border: `1px solid  ${theme.palette.common.black}`,
  },

  'span.lazy-load-image-background': {
    width: '100%',
    height: '56px',
    lineHeight: '0',
  },
  '> div': {
    img: {
      'object-fit': 'contain',
    },
  },
}))

const ThumbNailBrandContainer = styled(SliderThumbNailContainer, {
  name: 'PdpDetails',
  slot: 'SliderContainer',
})(({ theme }) => ({
  cursor: 'auto',

  [theme.breakpoints.up('sm')]: {
    border: 'none',
    '&:hover': {
      border: 'none',
    },
  },
}))

const SliderThumbNail = styled(ProductMedia, {
  name: 'PdpDetails',
  slot: 'SliderThumbNail',
})(() => ({
  objectFit: 'contain',
  width: '100%',
  height: '100%',
}))

const SliderNavigationContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderNavigationContainer',
})(({ theme }) => ({
  zIndex: Z_INDEX_LV2,
  position: 'absolute',
  top: `calc(50% + ${theme.spacing(4)})`,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  transform: 'translate(0, -50%)',
}))

const SliderNavigationButton = styled(IconButton, {
  name: 'PdpDetails',
  slot: 'SliderNavigationButton',
  shouldForwardProp: prop => prop !== 'positionStart' && prop !== 'positionEnd',
})<{ positionStart?: boolean; positionEnd?: boolean }>(({ positionStart, positionEnd }) => ({
  padding: '0',
  visibility: positionStart || positionEnd ? 'hidden' : 'visible',
}))

// FULL PAGE GALLERY SLIDER
const ProductFullPageGalleryContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'ProductFullPageGalleryContainer',
  shouldForwardProp: prop => prop === 'isVisible' || prop === 'children',
})<ProductFullPageGalleryProps>(({ isVisible, theme }) => ({
  position: 'fixed',
  display: isVisible ? 'flex' : 'none',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.palette.custom.white,
  width: '100%',
  height: '100%',
  zIndex: Z_INDEX_LV5,
  left: 0,
  top: 0,
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

const ProductFullPageGalleryCloseIcon = styled(IconButton, {
  name: 'ProductFullPageGallery',
  slot: 'CloseIcon',
})(({ theme }) => ({
  borderRadius: 0,
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
  padding: theme.spacing(4),
  position: 'absolute',
  zIndex: Z_INDEX_LV4,
  top: 0,

  right: 0,
  svg: {
    width: theme.spacing(6),
  },
}))

const SliderProductImageWrapper = styled('div', {
  name: 'SliderProductImageWrapper',
  slot: 'CloseIcon',
})(() => ({
  position: 'relative',
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  '> div': {
    width: '100%',
    img: {
      'object-fit': 'contain',
    },
  },
}))

export {
  ProductFullPageGalleryCloseIcon,
  ProductFullPageGalleryContainer,
  ProductImageCarousel,
  SliderContainer,
  SliderNavigationButton,
  SliderNavigationContainer,
  SliderProductImageWrapper,
  SliderThumbNail,
  SliderThumbNailContainer,
  SliderThumbNailsWrapper,
  ThumbNailBrandContainer,
  ThumbnailButtonContainer,
  WrapperThumbnails,
}
