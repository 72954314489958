import React from 'react'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import {
  ValuePropBannerItem,
  ValuePropBannerItemIcon,
  ValuePropBannerWrapper,
} from '@layouts/Header/components/ValuePropBanner/ValuePropBanner.style'
import theme from '@themes/Clearly/light-theme'
import { CmsIcon } from '@components/Cms/CMSContent/components/CmsIcon'

export interface ValuePropBannerProps {
  placement?: IPlacement
}

export const ValuePropBanner = (props: ValuePropBannerProps) => {
  const { placement } = props

  const valuePropositions = placement?.items.map(item => {
    const betterTypeItem = { ...item } as ILXTeaser
    return (
      <ValuePropBannerItem key={betterTypeItem.id || betterTypeItem.title}>
        <ValuePropBannerItemIcon>
          <CmsIcon teaserIcon={betterTypeItem?.teaserIcon} size={16} color={theme.palette.text.dark.secondary} />
        </ValuePropBannerItemIcon>
        {betterTypeItem.teaserTitle1}
      </ValuePropBannerItem>
    )
  })

  return <ValuePropBannerWrapper tabIndex={0}>{valuePropositions}</ValuePropBannerWrapper>
}
