import { getBadges } from '@utils/productAttributes'
import { useMemo, useState } from 'react'
import { usePageType } from '../../foundation/hooks/usePageType'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { IProduct } from '../../types/product'
import ProductTileUtils from '../ProductTile/ProductTileUtils'

import { useTranslation } from 'next-i18next'
import { useSelector } from 'react-redux'
import { plpBadgesSelector } from '../../redux/selectors/site'

const useProductTile = (product: IProduct) => {
  const { pageType } = usePageType()
  const badgeSelector = useSelector(plpBadgesSelector)
  const { isRXEnabled, langCode } = useStoreIdentity()
  const { t } = useTranslation()
  const [selectedClusterIndex, setSelectedClusterIndex] = useState<number>(0)

  //ClustersData
  const clusters = product.cluster || []
  const clusterLength = clusters.length || 0
  const clusterSelected = useMemo<IProduct>(
    () => (clusters ? clusters[selectedClusterIndex] : product),
    [clusters?.length, selectedClusterIndex, product?.id]
  )

  const { primaryBadge, secondaryBadges } = getBadges(clusterSelected, t, badgeSelector, isRXEnabled)

  const productLinkTo = ProductTileUtils.getMocoLinkTo(clusterSelected!, langCode)

  const getTileDataElementId = (tileIndex: number) =>
    useMemo(() => {
      switch (pageType) {
        case 'search':
          return `X_X_SearchPanel_Content_Tile${tileIndex}`
        case 'pdp':
          return `X_X_AlsoLike_Tile${tileIndex}`
        default:
          return `X_X_Tiles_Tile${tileIndex}_Img`
      }
    }, [pageType])

  return {
    getTileDataElementId,
    selectedClusterIndex,
    setSelectedClusterIndex,
    getClustersData: {
      clusters,
      clusterLength,
      clusterSelected,
      productPrimaryBadge: primaryBadge,
      productSecondaryBadges: secondaryBadges,
      productLinkTo,
    },
  }
}

export default useProductTile
