import React from 'react'
import { BrandImg, StyledTopPageTitle, WrapperBrandIcon } from './BrandIcon.style'
import brandList from './brandList'
import brandListClearly from './brandListClearly'
import { BrandIconProps } from '@constants/brands'

const BrandIcon: React.FC<BrandIconProps> = ({ name, isInverted, width, isTabletLandscape, height, lazy }) => {
  const brand =
    brandList.find(brand => brand.name === name) ||
    brandListClearly.find(brand => brand.name.toLowerCase() === name?.toLowerCase())
  const brandName = brand?.name
  const isPradaLineaRossa = brandName === 'Prada Linea Rossa'

  return brand ? (
    <WrapperBrandIcon width={width} isTabletLandscape={isTabletLandscape} height={height}>
      <BrandImg
        effect="opacity"
        src={brand.logo}
        alt={brandName}
        isInverted={isInverted!}
        isPradaLineaRossa={isPradaLineaRossa}
      />
    </WrapperBrandIcon>
  ) : (
    <WrapperBrandIcon>
      <StyledTopPageTitle isLogo={true}>{name}</StyledTopPageTitle>
    </WrapperBrandIcon>
  )
}
export default BrandIcon
