import React from 'react'
import { useRelatedProducts } from '@algolia/recommend-react'
import { CmsCarousel, CmsCarouselProps } from '@components/Cms/CMSContent/components/CmsCarousel/CmsCarousel'
import { IAlgoliaHit, IProduct } from '@typesApp/product'
import { getProductsPartNumbers } from '@utils/product'
import styles from './styles/YouMayAlsoLike.module.scss'

type YouMayAlsoLikeProps = {
  algoliaConfig: {
    indexName: string
    maxRecommendations: number
    recommendClient: any
  }
  id: string
  currentProduct: IProduct
  description: string
  title: string
} & Pick<CmsCarouselProps, 'sliderProps'>

export const YouMayAlsoLike: React.FC<YouMayAlsoLikeProps> = ({
  algoliaConfig,
  currentProduct,
  description,
  id,
  title,
  sliderProps,
}) => {
  const productsPartNumber = getProductsPartNumbers([currentProduct])
  const recommendations = useRelatedProducts({
    ...algoliaConfig,
    objectIDs: productsPartNumber ? [...new Set(productsPartNumber)] : [],
    queryParameters: {
      ruleContexts: ['related-products'],
    },
  })?.recommendations

  const recommendationsWithPrices = recommendations.map((hit: Partial<IAlgoliaHit>) => ({
    ...hit,
    x_price: { ...hit.prices },
  }))

  return (
    <div id={id} className={styles.wrapper} data-analytics_available_call="0">
      <CmsCarousel
        products={recommendationsWithPrices}
        productTileProps={{
          alignPriceCenter: true,
        }}
        description={description}
        title={title}
        sliderProps={sliderProps}
        isAlgolia
      />
    </div>
  )
}
