import { CustomTheme } from '@typesApp/theme'
import { ColorVariant, ShapeVariant } from './TextModule.style'

type ColorVariantMapped = {
  background?: string
  text?: string
  hoverText?: string
  hoverBackground?: string
  hoverPaginationBackground?: string
  buttonBackground?: string
}

type ShapeVariantMapped = {
  height: string | undefined
  borderRadius: number
  boxShadow: string | undefined
  fontSize: string | number
}

export const mapVariant = (
  theme: CustomTheme,
  variant: ColorVariant = 'dark',
  shapeVariant?: ShapeVariant
): ColorVariantMapped => {
  const themeStyles: {
    [K in ColorVariant]: ColorVariantMapped
  } = {
    dark: {
      background: theme.palette.custom.black,
      text: theme.palette.custom.white,
      hoverText: theme.palette.custom.white,
      hoverBackground: theme.palette.custom.light1.grey,
      hoverPaginationBackground: theme.palette.custom.light2.grey,
      buttonBackground: 'linear-gradient(to right, rgb(0 0 0) 50%,rgb(0 0 0 / 0%) 100%)',
    },
    green: {
      background: theme.palette.custom.halfBaked,
      text: theme.palette.custom.cyprus,
      hoverText: theme.palette.custom.white,
      hoverBackground: theme.palette.custom.jaggedIce,
      hoverPaginationBackground: theme.palette.custom.light1.grey,
      buttonBackground: theme.palette.custom.halfBaked,
    },
    light: {
      background: theme.palette.custom.light.grey,
      text: theme.palette.custom.black,
      hoverText: shapeVariant === 'rectangle' ? theme.palette.custom.white : theme.palette.custom.black,
      hoverBackground: shapeVariant === 'rectangle' ? theme.palette.custom.light1.grey : theme.palette.custom.white,
      hoverPaginationBackground: theme.palette.custom.light2.grey,
      buttonBackground: 'linear-gradient(to right, rgb(255 255 255) 50%,rgba(255,255,255,0) 100%)',
    },
    blue: {
      background: theme.palette.custom.blue,
      text: theme.palette.custom.white,
      hoverText: theme.palette.custom.blue,
      hoverBackground: theme.palette.custom.light.blue,
      hoverPaginationBackground: theme.palette.custom.light.blue,
      buttonBackground: theme.palette.custom.blue,
    },
  }
  const transparentThemeStyles: {
    [K in ColorVariant]: ColorVariantMapped
  } = {
    ...themeStyles,
    dark: {
      background: 'transparent',
      text: theme.palette.custom.black,
      hoverText: theme.palette.custom.light2.grey,
      hoverBackground: 'transparent',
      hoverPaginationBackground: theme.palette.custom.light2.grey,
      buttonBackground: theme.palette.custom.black,
    },
    light: {
      background: 'transparent',
      text: theme.palette.custom.white,
      hoverText: theme.palette.custom.light2.grey,
      hoverBackground: 'transparent',
      hoverPaginationBackground: theme.palette.custom.light2.grey,
      buttonBackground: theme.palette.custom.black,
    },
    blue: {
      background: 'transparent',
      text: theme.palette.custom.blue,
      hoverText: theme.palette.custom.light.blue,
      hoverBackground: 'transparent',
      hoverPaginationBackground: theme.palette.custom.light.blue,
      buttonBackground: theme.palette.custom.black,
    },
  }

  if (shapeVariant === 'transparent') return transparentThemeStyles[variant]

  return themeStyles[variant]
}

export const mapControllerVariant = (
  theme: CustomTheme,
  variant: ColorVariant = 'dark',
  shadowPosition: 'to left' | 'to right' = 'to right'
): ColorVariantMapped => {
  const themeStyles: {
    [K in ColorVariant]: ColorVariantMapped
  } = {
    dark: {
      buttonBackground: `linear-gradient(${shadowPosition}, rgb(0 0 0) 50%,rgb(0 0 0 / 0%) 100%)`,
    },
    green: {
      buttonBackground: theme.palette.custom.halfBaked,
    },
    light: {
      buttonBackground: `linear-gradient(${shadowPosition}, rgb(255 255 255) 50%,rgba(255,255,255,0) 100%)`,
    },
    blue: {
      buttonBackground: theme.palette.custom.blue,
    },
  }

  return themeStyles[variant]
}

export const mapShapeVariant = (theme: CustomTheme, variant: ShapeVariant = 'round'): ShapeVariantMapped => {
  const themeStyles: {
    [K in ShapeVariant]: ShapeVariantMapped
  } = {
    round: {
      borderRadius: 18,
      height: undefined,
      boxShadow: theme.shadows[19],
      fontSize: '0.75rem',
    },
    rectangle: {
      borderRadius: 0,
      height: theme.spacing(24),
      boxShadow: theme.shadows[19],
      fontSize: '0.75rem',
    },
    transparent: {
      borderRadius: 0,
      height: undefined,
      boxShadow: undefined,
      fontSize: '1.125rem',
    },
  }
  return themeStyles[variant]
}
