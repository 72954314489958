import React, { useContext, useState } from 'react'
import clsx from 'clsx'

// components
import ProductImage, {
  PDP_PRODUCT_IMAGE_FRONT_VIEW_SEQUENCE,
  PDP_PRODUCT_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE,
} from '@components/ProductImage/ProductImage'
import useProductTile from '@components/ProductTile/useProductTile'
import { Anchor } from '@components/UI-CSS/Anchor'
import { ProductBadges } from '@components/features/ProductBadges'
import {
  ContextWrapperData,
  PlacementContext,
  PlacementContextType,
} from '@components/Cms/CMSContent/PlacementContextWrapper/PlacementContextWrapper'
// constants
import { PRODUCT_CATEGORY_TYPES } from '@constants/product'
// hooks
import { useAppDispatch } from '@hooks/redux'
// features
import { setLastSelectedProduct } from '@features/ui/action'
// types
import { CSSModule } from '@styles/types'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { getProductImageAltLabel } from '@utils/productImage'

import styles from './styles/CmsProductTile.module.scss'
import { ProductContext } from '@components/PagesSeo/product/context/ProductContext'
import { useTranslation } from 'next-i18next'
import { getProductColorLabel } from '@utils/productAttributesAlgolia'
import { IProduct, IServerProduct } from '@typesApp/product'
import { ProductPriceAlgolia } from '@pages_views/ProductDetails/components/ProductPriceAlgolia'

const siteName = process.env.NEXT_PUBLIC_STORENAME

type CmsProductTileProps = {
  className?: string
  hideHeader?: boolean
  hidePrice?: boolean
  hideVariant?: boolean
  onClick?: () => void
  preventImagelazyLoad?: boolean
  viewType?: IViewType
  /**
   * Styles of the following classes can be overridden:
   *
   * ```
   *   container,
   *   innerContainer,
   *   badgeContainer
   *   imageContainer
   *   imageAnchor
   *   imageWrapper
   *   footer
   *   productDescription
   *   productName
   *   productBrand
   * ```
   *
   * ELEMENT TREE STRUCTURE:
   *
   * ```
   * container
   *  innerContainer
   *    badgeContainer
   *    imageContainer
   *      imageAnchor
   *        imageWrapper
   *    footer
   *      productDescription
   *        productName
   *        productBrand
   * ```
   */
  styleOverrides?: CSSModule
}

export const CmsProductTile: React.FC<CmsProductTileProps> = ({
  className,
  onClick,
  preventImagelazyLoad = false,
  hideHeader = false,
  hidePrice = false,
  hideVariant = false,
  viewType,
  styleOverrides,
}) => {
  const { t } = useTranslation()
  const placementContext = useContext<ContextWrapperData>(PlacementContext)
  const productContext = useContext(ProductContext)
  const product = productContext?.product as IServerProduct | IProduct
  const { data } = placementContext as ContextWrapperData<PlacementContextType>

  const dispatch = useAppDispatch()
  const [hoverImage, setHoverImage] = useState<boolean>(false)

  const placementViewType = data.placement.viewtype ?? ''
  const isProductCategoryType = PRODUCT_CATEGORY_TYPES.includes(placementViewType)
  const isProductsWithCtaType = viewType === 'cly-products-w-cta'

  const productTile = useProductTile(product)
  const { clusterSelected, productAttributes, productBadges, productImageAttributes, productLinkTo } =
    productTile['clusterData']
  const clusterViewLength = productTile['clusterData']?.clusters?.length ?? 1
  const { brand, modelCode, name } = productAttributes
  const { primaryBadge, secondaryBadges } = productBadges
  const { hasNoHoverImage, productImageWidth } = productImageAttributes

  const onImageClick = () => dispatch(setLastSelectedProduct(product?.uniqueID || ''))

  const onProductTileMouseEnter = () => {
    setHoverImage(true)
  }

  const onProductTileMouseLeave = () => {
    setHoverImage(false)
  }

  if (!placementContext || !productContext) return null

  return (
    <div
      aria-label={primaryBadge || `${name}_${modelCode}`}
      className={clsx(styleOverrides?.container, className, styles.container, 'arn-product-tile')}
      data-description={`${siteName}_${name}_${modelCode}`}
      data-element-id={productTile['tileDataElementId']}
    >
      {/* TODO: replace this div with a <button> as having onClick on non-interactive elements is not preferred */}
      <div className={clsx(styleOverrides?.innerContainer, styles.innerContainer)} onClick={onClick}>
        {!hideHeader && (
          <ProductBadges
            primaryBadge={!isProductsWithCtaType ? primaryBadge : null}
            secondaryBadges={!isProductsWithCtaType ? secondaryBadges : null}
            styleOverride={styleOverrides}
          />
        )}

        <div className={clsx(styleOverrides?.imageContainer, styles.imageContainer)}>
          <Anchor
            aria-label={primaryBadge || `${name}_${modelCode}`}
            className={clsx(styleOverrides?.imageAnchor, styles.imageAnchor, 'arn-product-tile__inner')}
            data-description={`${siteName}_${name}_${modelCode}`}
            data-element-id={productTile['tileDataElementId']}
            href={productLinkTo}
          >
            <div
              className={clsx(styleOverrides?.imageWrapper, styles.imageWrapper, {
                [styles.zoomOnHover]: hasNoHoverImage && product?.attachments.length !== 0,
              })}
              onMouseEnter={onProductTileMouseEnter}
              onMouseLeave={onProductTileMouseLeave}
            >
              <ProductImage
                alt={getProductImageAltLabel(product)}
                attachments={product?.attachments}
                onClick={onImageClick}
                // preventlazyLoad={preventImagelazyLoad}
                sequence={
                  !isProductCategoryType || hasNoHoverImage || !hoverImage
                    ? PDP_PRODUCT_IMAGE_FRONT_VIEW_SEQUENCE
                    : PDP_PRODUCT_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE
                }
                usage="PLP"
                width={productImageWidth}
              />
            </div>
          </Anchor>
        </div>

        <div className={clsx(styleOverrides?.productDetails, styles.productDetails)}>
          {!hideVariant ? (
            <div className={clsx(styleOverrides?.productVariants, styles.productVariants)}>
              {clusterViewLength > 1
                ? `${clusterViewLength} ${t('ProductTile.Labels.colors')}`
                : getProductColorLabel(product)}
            </div>
          ) : null}

          <div className={clsx(styleOverrides?.footer, styles.footer)}>
            <div className={clsx(styleOverrides?.productDescription, styles.productDescription)}>
              <div className={clsx(styleOverrides?.productName, styles.productName)}>{name}</div>
              <div className={clsx(styleOverrides?.productBrand, styles.productBrand)}>{brand}</div>
              {!hidePrice && clusterSelected && <ProductPriceAlgolia className={styles.priceContainer} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
