import { ParsedQuery } from 'query-string'
import config from '@configs/index'
import { Attachment, ProductImageUsage } from '@typesApp/product'

export const generateProductImagePath = (
  host: string,
  attachments?: Attachment[],
  usage?: ProductImageUsage,
  sequence?: string,
  isAccessoriesProduct?: boolean,
  isFramesProduct?: boolean,
  fallback?: {
    usage: ProductImageUsage
    sequence?: string
  }
): string | null => {
  const attachment = attachments?.find(attachment => {
    return !isAccessoriesProduct
      ? attachment.usage?.toLowerCase() === usage?.toLowerCase() &&
          (sequence
            ? attachment.sequence.toLowerCase() === sequence?.toLowerCase() ||
              (isFramesProduct && attachment.sequence.toLowerCase() === '1.0')
            : true)
      : sequence
        ? attachment.sequence.toLowerCase() === sequence.toLowerCase()
        : true
  })
  if (!attachment) {
    const fallbackImageAttachment = attachments?.find(
      attachment =>
        attachment.usage?.toLowerCase() === fallback?.usage.toLowerCase() &&
        (fallback.sequence ? attachment.sequence === fallback?.sequence : true)
    )
    return fallbackImageAttachment ? host + fallbackImageAttachment?.attachmentAssetPathRaw : null
  }

  return attachment ? host + attachment?.attachmentAssetPathRaw : null
}

export const addAkamaiParameters = (
  baseURL: string | null,
  width?: number,
  backgroundColor?: string,
  attr?: ParsedQuery<string>
) => {
  if (!baseURL) {
    return config.publicUrl + 'images/common/404.svg'
  }

  const url = new URL(baseURL)
  url.searchParams.append('impolicy', config.productImageAkamaiPolicy)

  if (width) {
    url.searchParams.append('wid', String(width))
  }

  if (attr) {
    for (const key in attr) {
      url.searchParams.append(key, attr[key] as string)
    }
  }

  // TODO use bgcolor param once Akamai fixes the issue with the background color
  url.searchParams.append('bgc', backgroundColor || `#${config.productImageAkamaiDefaultBgColor}`)

  return url.toString()
}

export const generateSrcSet = (
  baseURL: string | null,
  srcSetMap: Record<number, string> | undefined,
  backgroundColor?: string,
  attr?: ParsedQuery<string>
): string | undefined => {
  if (!baseURL || !srcSetMap) {
    return undefined
  }
  const _srcSet: string[] = []

  for (const width in srcSetMap) {
    _srcSet.push(`${addAkamaiParameters(baseURL, Number(width), backgroundColor, attr)} ${srcSetMap[width]}`)
  }

  return _srcSet.join(', ')
}

export const isVideoURL = (url: string | null): boolean => {
  return url ? /\.(mp4|webm|ogg)$/i.test(url) : false
}
