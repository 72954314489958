import { IProduct, ProductSoldOutStatus } from '@typesApp/product'
import { isAccessories, isOptical } from '@utils/product'
import {
  HeaderTitle,
  ProductDetailsAttributeContainer,
  ProductDetailsAttributeName,
  ProductDetailsAttributeValue,
  ProductDetailsDescriptionContainer,
  ProductDetailsListContainer,
  ProductDetailsListHeader,
  ProductDetailsListWrapper,
} from '@pages_views/ProductDetails/components/ProductDetailsList.style'

import { useTranslation } from 'next-i18next'
import { PRODUCT_SOLDOUT_STATUS } from '@constants/product'
import { getAttributeValue, filterAttribute } from '../utils'
import { attributesKeyToDisplay } from '@utils/productAttributes'
import HtmlRenderer from '@components/UI/HtmlRender'

interface IProductDetailsList {
  currentProduct: IProduct
  type: ProductSoldOutStatus
}

export const ProductDetailsList = ({ currentProduct, type }: IProductDetailsList) => {
  const mockAvailableState = true
  const { t } = useTranslation()

  const productType = currentProduct?.productAttributes['PRODUCT_TYPE']
  const templeColor = currentProduct?.productAttributes['TEMPLE_COLOR']

  const attributes =
    currentProduct?.productAttributes &&
    Object.keys(currentProduct.productAttributes)
      .filter(attrKey => filterAttribute(attrKey, attributesKeyToDisplay))
      .sort((attrKeyA, attrKeyB) => {
        const indexA = attributesKeyToDisplay.indexOf(attrKeyA)
        const indexB = attributesKeyToDisplay.indexOf(attrKeyB)
        return indexA - indexB
      })

  const filteredAttributes = attributes?.filter(attr =>
    isOptical(productType) ? attr !== 'LENS_COLOR' && attr !== 'LENS_TREATMENT_FACET' : attr
  )

  const productDetailsListAttributes =
    filteredAttributes &&
    !isAccessories(productType) &&
    filteredAttributes.map((attrKey, i) => {
      return (
        <ProductDetailsAttributeContainer key={i}>
          <ProductDetailsAttributeName>
            {t(`ProductDetails.Attributes.${attrKey}`).toUpperCase()}:
          </ProductDetailsAttributeName>
          <ProductDetailsAttributeValue>
            {getAttributeValue(attrKey, currentProduct, templeColor, t)}
          </ProductDetailsAttributeValue>
        </ProductDetailsAttributeContainer>
      )
    })

  const productDetailsDescription = (
    <ProductDetailsDescriptionContainer>
      <HtmlRenderer htmlString={currentProduct?.longDescription} variant="body1" />
    </ProductDetailsDescriptionContainer>
  )

  return type !== PRODUCT_SOLDOUT_STATUS.SOLDOUT || mockAvailableState ? (
    <ProductDetailsListWrapper>
      <ProductDetailsListHeader>
        <HeaderTitle>{t('ProductDetails.Labels.AboutThisProduct')}</HeaderTitle>
      </ProductDetailsListHeader>
      <ProductDetailsListContainer>
        {[productDetailsListAttributes, productDetailsDescription]}
      </ProductDetailsListContainer>
    </ProductDetailsListWrapper>
  ) : null
}
