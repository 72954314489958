import React from 'react'

import { ModulesProps } from '@typesApp/cms'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { TextModuleTeaser } from './components/TextModuleTeaser'
import TextModuleArticle from './components/TextModuleArticle'

export interface ITextModuleProps extends ModulesProps {
  placement?: IPlacement | ICMCollection
  teaser?: ILXTeaser
  placementCenter?: boolean
  sectionTitle?: boolean
}

const TextModule: React.FC<ITextModuleProps> = props => {
  const { viewType, teaserIndex, placement, teaser, placementCenter, sectionTitle } = props
  const placementCounter = teaser?.placementCounter || placement?.placementCounter
  const areItemsInPlacement = placement && 'items' in placement
  const item = !!teaser ? teaser : areItemsInPlacement ? placement?.items[0] : placement?.teasableItems[0]
  const newItem = { ...item, placementCounter }
  const backgroundColor = (placement as IPlacement)?.backgroundColor
  const isLearnMoreArticle =
    placement?.viewtype === 'single-column-info-article' || viewType === 'double-column-info-article'
  switch (item?.type) {
    case 'LXTeaser':
      return (
        <TextModuleTeaser
          item={newItem as ILXTeaser}
          viewType={viewType || placement?.viewtype || 'default'}
          teaserIndex={teaserIndex}
          placementCenter={placementCenter}
          sectionTitle={sectionTitle}
        />
      )
    case 'CMArticle':
      return <TextModuleArticle item={item} backgroundColor={backgroundColor} isLearnMoreArticle={isLearnMoreArticle} />
    default:
      return null
  }
}

export default TextModule
