import React from 'react'
import { useFrameGenius } from '@hooks/useFrameGenius'
import { SizeAdvisorFilterContainer, SizeAdvisorButtonContainer } from '@components/PlpFilter/PlpFilter.style'
import { SizeAdvisorSwitcher } from './SizeAdvisorSwitcher'

export const SizeAdvisorFilter: React.FC<{
  sizeAdvisorSwitcherProps: {
    toggleHingesFacet?: () => void
    enabled: boolean
  }
}> = ({ sizeAdvisorSwitcherProps }) => {
  const frameGeniusData = useFrameGenius()

  if (!sizeAdvisorSwitcherProps.toggleHingesFacet) return null

  return (
    <SizeAdvisorFilterContainer>
      <SizeAdvisorButtonContainer onClick={frameGeniusData?.openSizeAdvisor} />
      {frameGeniusData?.analysisResults?.size && (
        <SizeAdvisorSwitcher
          toggleHingesFacet={sizeAdvisorSwitcherProps.toggleHingesFacet}
          enabled={sizeAdvisorSwitcherProps.enabled}
        />
      )}
    </SizeAdvisorFilterContainer>
  )
}
