import React from 'react'
import clsx from 'clsx'
import { v4 as uuid } from 'uuid'
import { CmsCta } from '@components/Cms/CMSContent/components/CmsCta'
import { TeaserBackgroundColor } from '@utils/cms/background'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ICMPlaceholder } from '@typesApp/cmsPlacement/CMPlaceholder'
import styles from './styles/CollectionCta.module.scss'

type CollectionCtaProps = {
  backgroundColor?: TeaserBackgroundColor
  marginLateral?: boolean
  teaser: ICMCollection | ICMPlaceholder
  marginVertical?: string
}

export const CollectionCta: React.FC<CollectionCtaProps> = ({
  backgroundColor,
  marginLateral,
  marginVertical,
  teaser,
}) => {
  const collectionCta = teaser.teaserLXCallToActionSettings ?? []
  const paddingTopEnabled = collectionCta.length > 0

  return collectionCta?.length ? (
    <div
      className={clsx(styles.wrapper, backgroundColor, {
        [styles.withTopPadding]: paddingTopEnabled,
        [styles.marginLateral]: marginLateral,
        [styles[`marginVertical${marginVertical}`]]: marginVertical,
      })}
    >
      {collectionCta?.map((actionSettings, index) => (
        <CmsCta
          dataElementId={`X_X_${index}Placement_Collection_CTA`}
          key={`cms-content__collection--cta-${uuid()}`}
          teaserCtaSetting={actionSettings}
        />
      ))}
    </div>
  ) : null
}
