import { FC, Fragment, useEffect, useState } from 'react'
import { getFacetLabel } from '@utils/productAttributesAlgolia'
import { CloseCircleIcon } from '@components/UI/Icons/close'
import { Pill } from '@components/UI/Pill'
import { useRefinementList } from 'react-instantsearch'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

interface IFacetFilterPill {
  facetValues: string[]
  facetName: string
  facet: string
  onFacetChange: (selection: string, label: string, parent: string, setFacet: (string) => void) => void
  searchTerm?: string
}

const FacetFilterPill: FC<IFacetFilterPill> = ({ facetValues, facetName, facet, onFacetChange, searchTerm }) => {
  const { items, refine, searchForItems } = useRefinementList({
    attribute: facet,
    operator: 'or',
    sortBy: ['count'],
    limit: facetValues.length,
  })

  const [activePills, setActivePills] = useState<string[]>([])

  useEffect(() => {
    searchForItems(searchTerm || '')
  }, [searchForItems, searchTerm])

  useEffect(() => {
    const refinedItems = items.filter(item => item.isRefined).map(item => item.value)
    setActivePills(refinedItems)
  }, [items])

  const handlePillClick = (facetValue: string, label: string) => {
    setActivePills(prevState =>
      prevState.includes(facetValue) ? prevState.filter(value => value !== facetValue) : [...prevState, facetValue]
    )

    setTimeout(() => {
      onFacetChange(facetValue, label, facet, refine)
    }, 0)
  }

  return (
    <>
      {facetValues.map(facetValue => {
        if (facetValue === '0') return null
        const item = items.find(item => item.value === facetValue)
        const isActive = item?.isRefined || activePills.includes(facetValue)
        let label = item ? item.label : getFacetLabel(facetValue)

        if (facetName === 'DISCOUNT') {
          const priceArray = label?.split('.')
          if (priceArray && +priceArray[1] === 0) label = priceArray[0]
          label += '% OFF'
        }
        const isDisabled = !item
        const endIcon = <SVGIcon library="close" name="close-circle" />

        return (
          <Fragment key={facetValue}>
            <Pill
              id={facetValue}
              icon={isActive ? endIcon : undefined}
              disabled={isDisabled}
              label={label || getFacetLabel(facetValue)}
              onClick={() => {
                handlePillClick(facetValue, label)
              }}
              isActive={isActive}
              variant={isActive ? 'primary' : 'secondary'}
            />
          </Fragment>
        )
      })}
    </>
  )
}

export default FacetFilterPill
