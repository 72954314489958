import { v4 as uuid } from 'uuid'
import TopReasons from '@components/TopReasons'
import { toggleAddToCart } from '@features/product/slice'
import Box from '@mui/material/Box'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { ProductRightColumnProps } from '@typesApp/product'
import { generateQuantityOptions } from '@utils/order'
import { getTopReasonsCMCollection } from '@components/TopReasons/helpers'
import { getClVolume } from '@utils/productAttributes'
import {
  StyledCLAccessoriesContainer,
  StyledFormConfigLabel,
  StyledFormControl,
  StyledMenuItem,
  StyledSelect,
  StyledSeparator,
} from '../../ProductDetails.style'
import { OmniChannelCarousel } from '../OmniChannelCarousel/OmnichannelCarousel'
import ProductAvailableNotificationDrawer from '../ProductAvailableNotificationDrawer'
import ProductCtaContainer from '../ProductCtaContainer'
import ProductPriceNew from '../ProductPriceNew'
import SizeSwitcher from '../SizeSwitcher'
import { DropDownIcon } from '../ContactLensesSelect/ContactLensesSelect.style'
import { parseProductTypeForPrice } from '@components/common/UI/ProductPrice/utils/utils'
import useProductPrice from '@hooks/useProductPrice'
import {
  DEFAULT_CLACCESSORIES_MAX,
  DEFAULT_CLACCESSORIES_MIN,
  PRODUCT_SOLDOUT_STATUS,
  PRODUCT_TYPES_KEYS,
} from '@constants/product'
import { TOP_REASONS_CMS_PDP_PLACEMENTID } from '@constants/common'
import { SubscriptionSelectionOption } from '@components/UI/ProductSubscriptionModule/pdp/SubscriptionPDP'
import { useSite } from '@foundation/hooks/useSite'
import storeUtil from '@utils/storeUtil'
import { isMtoProduct, isProductDiscontinued } from '@utils/product'
import config from '@configs/index'
import { ProductDetailsList } from '../ProductDetailsList'
import ErrorMessage from '@components/UI-CSS/ErrorMessage'
import styles from './styles/PdpRight.module.scss'
import { ShippingTimesBanner } from '../ShippingTimesBanner/ShippingTimesBanner'
import { ProductNameSection } from '../ProductNameSection'
import { StyledSpecialFrameInfo } from '../StyledSpecialFrameInfo'

const ASSOCIATION_TYPE = 'CL_Pack_Sizes'

export const CLAccessoriesPdpRightColumn = ({
  currentProduct,
  soldOutStatus,
  productInCart,
  addToCartButtonFail,
  ctaRef,
  placements,
  cluster,
  isRoxable,
  productQuantity,
  setProductQuantity,
  onlyFewPieces,
  adjustment,
  setAdjustment,
  isSoldout,
  liveStockError,
}: ProductRightColumnProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleQuantityUpdate = event => {
    setProductQuantity(event.target.value)
  }

  const quantityOptions = generateQuantityOptions(
    DEFAULT_CLACCESSORIES_MAX,
    DEFAULT_CLACCESSORIES_MIN,
    'ProductDetails.Pack',
    'ProductDetails.Packs',
    5,
    t
  )
  const clusterWithMerchandisingAssociations = cluster?.find(c => !!c.merchandisingAssociations)

  const getAssociatedMerchandising = cp => {
    return cp?.merchandisingAssociations?.some(ma => ma?.associationType === ASSOCIATION_TYPE)
  }

  const hasAssociatedMerchandising = useMemo(
    () => getAssociatedMerchandising(clusterWithMerchandisingAssociations),
    [clusterWithMerchandisingAssociations]
  )

  const topReasonsCMCollection = getTopReasonsCMCollection(placements || [], TOP_REASONS_CMS_PDP_PLACEMENTID)

  const getSizeSwitcherOptions = cp => {
    return cp?.merchandisingAssociations
      ?.filter(ma => ma.associationType === ASSOCIATION_TYPE)
      .map(ma => {
        const product = ma?.cluster?.[0]
        const size = getClVolume(product)
        return { size, label: '', href: product?.seo?.href }
      })
  }

  const options = useMemo(
    () => getSizeSwitcherOptions(clusterWithMerchandisingAssociations),
    [clusterWithMerchandisingAssociations]
  )

  const currentSize = currentProduct.productAttributes['CL_VOLUME']
  const productType = currentProduct.productAttributes['PRODUCT_TYPE']
  const { currentPrice: prices } = useProductPrice(currentProduct.x_price)
  const { mySite } = useSite()
  const { enabled, showOnPdp, allowedProducts } = config.productSubscription
  const isMTO = storeUtil.isMTOEnabled(mySite) && isMtoProduct(currentProduct)
  const showSubscriptionSection =
    !isMTO &&
    enabled &&
    showOnPdp &&
    allowedProducts?.includes(productType) &&
    soldOutStatus !== PRODUCT_SOLDOUT_STATUS.SOLDOUT &&
    soldOutStatus !== PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK
  const isOutOfStockCLAccessory = isSoldout || soldOutStatus === PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK
  const displayable = currentProduct.displayable
  const isDiscontinued = isProductDiscontinued(currentProduct)
  const hasRecommendedProducts = false // TODO

  const showProductDetailsList = !!config.pdp.showProductDetailsInRightColumn

  dispatch(toggleAddToCart(true))

  return (
    <>
      {hasAssociatedMerchandising && currentSize ? <SizeSwitcher options={options} currentSize={currentSize} /> : null}
      {currentProduct && !isSoldout ? (
        <>
          <StyledSpecialFrameInfo currentProduct={currentProduct} />
          <StyledSeparator />
        </>
      ) : null}

      {currentProduct && (
        <StyledCLAccessoriesContainer>
          <StyledFormControl fullWidth>
            {(showSubscriptionSection && (
              <SubscriptionSelectionOption
                currentProduct={currentProduct}
                isEditingContactLens={false}
                setAdjustment={setAdjustment}
              >
                <StyledSelect
                  id="packs"
                  name="packs_quantity"
                  onChange={handleQuantityUpdate}
                  defaultValue={1}
                  IconComponent={DropDownIcon}
                >
                  {quantityOptions?.map((q, i) => (
                    <StyledMenuItem key={uuid()} value={q.value ?? ''}>
                      {q.text}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
              </SubscriptionSelectionOption>
            )) || (
              <>
                <StyledFormConfigLabel>{t('ContactLenses.Labels.Quantity')}</StyledFormConfigLabel>
                <StyledSelect
                  id="packs"
                  name="packs_quantity"
                  onChange={handleQuantityUpdate}
                  defaultValue={1}
                  IconComponent={DropDownIcon}
                >
                  {quantityOptions?.map((q, i) => (
                    <StyledMenuItem key={i} value={q.value ?? ''}>
                      {q.text}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
              </>
            )}
          </StyledFormControl>
        </StyledCLAccessoriesContainer>
      )}

      <Box sx={{ gap: 0 }}>
        {currentProduct?.x_price && (
          <ProductPriceNew
            isPDP={true}
            price={prices}
            productType={parseProductTypeForPrice(productType)}
            isCLAccessories={true}
            showPricePerBox={true}
            showTotalPrice={true}
            showSalePrice={false}
            totalBoxes={Number(productQuantity)}
            adjustment={adjustment}
          />
        )}
      </Box>

      {displayable && <div className={styles.styledSeparator} />}

      {currentProduct && (
        <>
          {!isDiscontinued && isOutOfStockCLAccessory && !liveStockError && (
            <ErrorMessage errorTitle={t('OutOfStock.SorrySoldOut')} errorDescription={t('OutOfStock.BackInStock')} />
          )}

          {isDiscontinued && isOutOfStockCLAccessory && !liveStockError && (
            <ErrorMessage
              errorTitle={t('OutOfStock.SorryDiscontinued')}
              errorDescription={t(
                hasRecommendedProducts ? 'OutOfStock.BrowseSimilarProducts' : 'Discontinued.NoRecommendationMsg'
              )}
            />
          )}

          <ProductCtaContainer
            productInCart={productInCart}
            type={soldOutStatus}
            currentProduct={currentProduct}
            error={addToCartButtonFail}
            ctaRef={ctaRef}
            roxable={isRoxable}
            addToCartFillType={'fill'}
            productQuantity={productQuantity}
            onlyFewPieces={onlyFewPieces}
          />
        </>
      )}

      {!isOutOfStockCLAccessory && <ShippingTimesBanner productType={PRODUCT_TYPES_KEYS.CONTACT_LENSES_ACCESSORIES} />}

      <ProductAvailableNotificationDrawer
        attachments={currentProduct.attachments || []}
        attributes={currentProduct.productAttributes}
        product={currentProduct || undefined}
      />

      {liveStockError && (
        <ErrorMessage
          className={styles.clAccessoriesErrorContainer}
          errorTitle={t('OutOfStock.SorrySoldOut')}
          errorDescription={t('OutOfStock.BackInStock')}
        />
      )}

      {topReasonsCMCollection && <TopReasons topReasonsCMCollection={topReasonsCMCollection} />}

      {placements && <OmniChannelCarousel placements={placements} />}
      {showProductDetailsList && <ProductDetailsList type={soldOutStatus} currentProduct={currentProduct} />}
    </>
  )
}
