import React from 'react'
import { TextField } from '@components/UI/TextField'
import { useTranslation } from 'next-i18next'
import { MenuItem } from '@mui/material'
import { useGetSubscriptionsTermsAndConditionsQuery } from '@features/productSubscription/query'
import { useSite } from '@foundation/hooks/useSite'
import clsx from 'clsx'
import styles from './styles/SubscriptionPDP.module.scss'
import { useSelector } from 'react-redux'
import { productSubscriptionDiscountSelector } from '@redux/selectors/site'
import { useFrequencyList } from '@hooks/productSubscription/useFrequencyList'
import { getDescriptionFromMarketingSpot } from '../productSubscriptionHelpers'
import { RecurrencySelectProps } from '@typesApp/productSubscription'

export const RecurrencySelect: React.FC<RecurrencySelectProps> = ({
  defaultValue,
  onChange,
  showStacked,
  customLabel,
  customPlaceHolder,
}) => {
  const { t } = useTranslation()
  const { mySite } = useSite()
  const frequencyList = useFrequencyList()
  const { data: eSpotData } = useGetSubscriptionsTermsAndConditionsQuery({ storeId: mySite.storeID })
  const subscriptionTermsAndCondition = getDescriptionFromMarketingSpot(eSpotData)
  const subscriptionDiscountFromConfig = useSelector(productSubscriptionDiscountSelector)

  return (
    <div className={clsx(styles.subscriptionIntervalSection, showStacked && styles.showStacked)}>
      <div>
        {customLabel ??
          (subscriptionDiscountFromConfig &&
            t('Subscriptions.Msgs.Active.Description', { discountAmount: subscriptionDiscountFromConfig })) ??
          subscriptionTermsAndCondition}
      </div>
      <TextField
        select
        label={customPlaceHolder}
        className="recurrency-selector"
        defaultValue={defaultValue}
        value={defaultValue}
        onChange={e => onChange(e.target.value)}
      >
        {frequencyList.map(item => {
          const val = Object.keys(item)[0]
          return (
            <MenuItem key={val} value={val}>
              {item[val]}
            </MenuItem>
          )
        })}
      </TextField>
    </div>
  )
}
