import { RootReducerState } from '../../redux/reducers'

export const productTypeSelector = (state: RootReducerState) => state.product.productType
export const productContactLensesDataSelector = (state: RootReducerState) => state.product.contactLensesData
export const productAddToCartEnabledSelector = (state: RootReducerState) => state.product.addToCartEnabled
export const addToCartBusySelector = (state: RootReducerState): boolean => !!state.product.isAddToCartBusy
export const addedToCartSuccessSelector = (state: RootReducerState) => state.success.link
export const addContactLensesToCartErrorSelector = (state: RootReducerState) =>
  state.product.addContactLensesToCartError
export const addCLAccessoriesToCartErrorSelector = (state: RootReducerState) =>
  state.product.addCLAccessoriesToCartError
export const addSubscriptionToCartErrorSelector = (state: RootReducerState) => state.product.addSubscriptionToCartError
export const moCoOrderedIdsFromPlp = (state: RootReducerState) => state.product.moCoOrderedIds
