import TopReasons from '@components/TopReasons'
import { getFormattedPlpProduct } from '@features/plp/utils'
import { useTranslation } from 'next-i18next'
import { useSearchParams } from 'next/navigation'
import { useMemo, useState } from 'react'
import { PRODUCT_URL_SEARCH_PARAMS } from '../../../../constants/product'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import { useContactLensCartItemData } from '../../../../hooks/useContactLensCartItemData/useContactLensCartItemData'
import { IProduct, ProductRightColumnProps, SupplyData } from '../../../../types/product'
import { getClPackSize } from '../../../../utils/productAttributes'
import { getTopReasonsCMCollection } from '@components/TopReasons/helpers'
import { useAnnualSupply } from '../../hooks/useAnnualSupply'
import { ContactLensSelection } from '../ContactLensSelection'
import { OmniChannelCarousel } from '../OmniChannelCarousel/OmnichannelCarousel'
import ProductAvailableNotificationDrawer from '../ProductAvailableNotificationDrawer'
import ProductCtaContainer from '../ProductCtaContainer'
import ProductPriceNew from '../ProductPriceNew'
import SizeSwitcher from '../SizeSwitcher'
import SupplyBadges from '../SupplyBadge/SupplyBadges'
import { parseProductTypeForPrice } from '@components/common/UI/ProductPrice/utils/utils'
import useProductPrice from '@hooks/useProductPrice'
import { TOP_REASONS_CMS_PDP_PLACEMENTID } from '@constants/common'
import config from '@configs/config.base'

const ASSOCIATION_TYPE = 'CL_Pack_Sizes'

export const handleDiscountedBoxQuantities = (supplyData: SupplyData[], totalBoxes: number) => {
  const fixedSupplyQuantities = supplyData.map(y => y.quantity)
  const isMultipleOfQuantity = fixedSupplyQuantities.find(u => totalBoxes / +u! >= 1)

  const leftoverBoxes = (isMultipleOfQuantity && totalBoxes % +isMultipleOfQuantity) || 0
  const originalBoxPrice =
    supplyData && supplyData?.find(q => q.quantity === isMultipleOfQuantity || q)?.originalBoxPrice
  const discountedBoxPrice = supplyData?.find(d => d.quantity === isMultipleOfQuantity)?.discountedBoxPrice

  const combinedPricePerBox = isMultipleOfQuantity
    ? leftoverBoxes === 0
      ? (+discountedBoxPrice! * totalBoxes) / totalBoxes
      : (+originalBoxPrice! * (leftoverBoxes || 1) + +discountedBoxPrice! * +(totalBoxes - leftoverBoxes)) / totalBoxes
    : +originalBoxPrice!

  return combinedPricePerBox
}

export const ContactLensesPdpRightColumn = ({
  pdpData,
  currentProduct,
  soldOutStatus,
  productInCart,
  addToCartButtonFail,
  ctaRef,
  placements,
  isRoxable,
  onlyFewPieces,
  isLoading,
  adjustment,
  setAdjustment,
}: ProductRightColumnProps) => {
  const [totalBoxes, setTotalBoxes] = useState<number>(0)
  const { isDesktop } = useBreakpoints()
  const supplyData = useAnnualSupply(currentProduct)
  const { t } = useTranslation()
  const query = useSearchParams()
  const isEditingContactLens = !!query.get(PRODUCT_URL_SEARCH_PARAMS.EDIT_CONTACT_LENS)
  const { currentPrice: prices } = useProductPrice(currentProduct.x_price)
  const topReasonsCMCollection = getTopReasonsCMCollection(placements || [], TOP_REASONS_CMS_PDP_PLACEMENTID)

  const getAssociatedMerchandising = (cp: IProduct) => {
    const hasMassoc = cp?.merchandisingAssociations?.some(ma => ma?.associationType === ASSOCIATION_TYPE)
    return hasMassoc
  }

  const hasAssociatedMerchandising = useMemo(
    () => (currentProduct ? getAssociatedMerchandising(currentProduct) : false),
    [currentProduct]
  )

  const getSizeSwitcherOptions = cp => {
    const massocArray = cp?.merchandisingAssociations.filter(ma => {
      return ma?.associationType === ASSOCIATION_TYPE
    })
    const filteredMassocArray = massocArray.map(ma => {
      const formattedProduct = getFormattedPlpProduct(ma)
      // find the first item inside the cluster where type is CL_Pack_Sizes
      const clusterPackAssociation = formattedProduct?.cluster
        ? formattedProduct.cluster.find(cluster => cluster.associationType === ASSOCIATION_TYPE)
        : ma

      const size = clusterPackAssociation ? getClPackSize(clusterPackAssociation) : null
      return { size, label: t('ProductDetails.Pack'), href: ma?.seo?.href }
    })

    // find first not matching current product size
    if (filteredMassocArray?.length) {
      const massoc = filteredMassocArray.find(ma => ma.size !== getClPackSize(cp))
      return massoc ? [massoc] : []
    }

    return []
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const options = useMemo(() => getSizeSwitcherOptions(currentProduct) || [], [currentProduct])

  const currentSize = currentProduct && getClPackSize(currentProduct)

  const { orderItemClData } = useContactLensCartItemData({
    orderItem: productInCart,
  })

  const productType = currentProduct?.productAttributes['PRODUCT_TYPE']

  return (
    <>
      {hasAssociatedMerchandising && !!options?.length && !!currentSize ? (
        <SizeSwitcher options={options} currentSize={currentSize} />
      ) : null}

      {currentProduct && (
        <ContactLensSelection
          ctaRef={ctaRef}
          currentProduct={currentProduct}
          pdpData={pdpData}
          totalBoxes={setTotalBoxes}
          productInCart={productInCart}
          isEditingContactLens={isEditingContactLens}
          orderItemClData={orderItemClData}
          adjustment={adjustment}
          setAdjustment={setAdjustment}
          isLoading={!!isLoading}
          soldOutStatus={soldOutStatus}
        />
      )}

      {supplyData && !isDesktop && <SupplyBadges supplyData={supplyData} isMobile={!isDesktop} />}

      {currentProduct?.x_price && (
        <>
          <ProductPriceNew
            isCL={true}
            isPDP={true}
            price={prices}
            productType={parseProductTypeForPrice(productType)}
            totalBoxes={totalBoxes}
            showPricePerBox={config.pdp.showCLPricePerBox}
            showTotalPrice={!config.pdp.showCLPricePerBox}
            showSalePrice={false}
          />

          <ProductCtaContainer
            productInCart={productInCart}
            type={soldOutStatus}
            currentProduct={currentProduct}
            error={addToCartButtonFail}
            ctaRef={ctaRef}
            roxable={isRoxable}
            onlyFewPieces={onlyFewPieces}
          />
        </>
      )}
      {placements && <OmniChannelCarousel placements={placements} />}
      <ProductAvailableNotificationDrawer
        attachments={currentProduct?.attachments || []}
        attributes={currentProduct?.productAttributes}
        product={currentProduct || undefined}
      />
      {topReasonsCMCollection && <TopReasons topReasonsCMCollection={topReasonsCMCollection} />}
    </>
  )
}
