//Standard libraries
import { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'

//UI
import { loginStatusSelector } from '@features/user/selector'
import { sendNotFoundErrorEvent } from '@foundation/analytics/tealium/lib'
import { StyledBodyContainer } from './NotFound.style'
import { ICommerceHclPage, IPageStaticLanding } from '@typesApp/cms'
import PlacementLayout from '@components/Cms/PlacementLayout'
import { setSeoData } from '@features/seo/slice'
import { isEmpty } from '@utils/helpers'
import { ANALYTICS_PAGE_TYPE, useAnalyticsData } from '@foundation/hooks/useAnalyticsData'

type Props = {
  pageData?: IPageStaticLanding | ICommerceHclPage | null
}

const NotFound = ({ pageData }: Props) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const loginStatus = useSelector(loginStatusSelector)
  const data = pageData as IPageStaticLanding
  const analyticsData = useAnalyticsData(ANALYTICS_PAGE_TYPE.NOT_FOUND)

  useEffect(() => {
    sendNotFoundErrorEvent({
      common: analyticsData,
      loginStatus,
      requestedUrl: router.pathname,
    })
  }, [loginStatus, router.pathname])

  const { htmlTitle, htmlDescription } = data || {}
  const seoHeadData = useMemo(
    () => ({
      page: {
        title: htmlTitle,
        metaDescription: htmlDescription,
      },
    }),
    [htmlTitle, htmlDescription]
  )

  useEffect(() => {
    dispatch(setSeoData(!isEmpty(seoHeadData) ? seoHeadData : null))
  }, [seoHeadData])

  if (!data) {
    return null
  }

  const contentPlacements = data?.contentPlacements || []

  return (
    <StyledBodyContainer>
      <PlacementLayout placements={contentPlacements} className={data.cssClassName} />
    </StyledBodyContainer>
  )
}

export default NotFound
