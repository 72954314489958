import styled from '@mui/material/styles/styled'

export const BreadcrumbsWrapper = styled('div', {
  name: 'BreadcrumbsWrapper',
})(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(4, 0),
  justifyContent: 'center',

  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-start',
  },
}))
