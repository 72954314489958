import { isCLAccessoriesProduct } from '../../../utils/product'
import {
  ProductBrand,
  ProductBrandWrapper,
  ProductCLBrand,
  ProductCLName,
  ProductName,
  ProductNameWrapper,
} from './ProductNameSection.style'

import { IProduct } from '@typesApp/product'
import { useTranslation } from 'next-i18next'
import { useSelector } from 'react-redux'
import { wishlistEnabledSelector } from './../../../features/wishList/selector'
import { WishListButton } from './WishListButton'

interface IProductDetailsList {
  currentProduct: IProduct
  displayable?: boolean
  isSoldout?: boolean
  // isLoading?: boolean
}

export const ProductNameSection = ({ currentProduct, displayable, isSoldout }: IProductDetailsList) => {
  const { t } = useTranslation()
  const wishListEnabled = useSelector(wishlistEnabledSelector)

  return (
    <ProductNameWrapper>
      {currentProduct && !isCLAccessoriesProduct(currentProduct) ? (
        <>
          <ProductBrandWrapper>
            <ProductBrand>
              {currentProduct?.productAttributes['BRAND'] || t('ProductDetails.Labels.BrandName')}
            </ProductBrand>
            {wishListEnabled && !isSoldout && <WishListButton currentProduct={currentProduct} />}
          </ProductBrandWrapper>
          <ProductName>{currentProduct?.productAttributes['MODEL_NAME']}</ProductName>
        </>
      ) : (
        <>
          <ProductBrandWrapper>
            <ProductCLName>{currentProduct?.productAttributes['MODEL_NAME']}</ProductCLName>
          </ProductBrandWrapper>
          <ProductCLBrand>
            {currentProduct?.productAttributes['CL_BRAND'] || t('ProductDetails.Labels.BrandName')}
          </ProductCLBrand>
        </>
      )}
    </ProductNameWrapper>
  )
  // )
}
