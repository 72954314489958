import React from 'react'
import styled from '@mui/material/styles/styled'
import { TPlayerBannerHook } from '@hooks/useBannerPlayer'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const ControllerWrapper = styled('div', {
  name: 'VideoController',
  slot: 'Wrapper',
})(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(6),
  right: theme.spacing(6),
  borderRadius: 50,
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
  backdropFilter: 'blur(8px)',
  backgroundColor: 'rgba(111, 110, 111, 0.4)',

  svg: {
    cursor: 'pointer',
  },
}))

export const CustomVideoController = ({ playerBanner }: { playerBanner: TPlayerBannerHook }) => {
  return (
    <ControllerWrapper>
      {playerBanner.isPlaying ? (
        <SVGIcon library="media" name="pause" color="white" onClick={() => playerBanner.setIsPlaying(prev => !prev)} />
      ) : (
        <SVGIcon library="media" name="play" color="white" onClick={() => playerBanner.setIsPlaying(prev => !prev)} />
      )}
      {playerBanner.muted ? (
        <SVGIcon library="media" name="audio-off" color="white" onClick={() => playerBanner.setMuted(prev => !prev)} />
      ) : (
        <SVGIcon library="media" name="audio-on" color="white" onClick={() => playerBanner.setMuted(prev => !prev)} />
      )}
    </ControllerWrapper>
  )
}
