export const SOCIAL_ICONS = {
  fileName: '/images/icons/social-sprite.svg',
  icons: [
    'facebook', // UNUSED
    'facebook-white-circle', // UNUSED
    'instagram-white-circle', // UNUSED
    'twitter-white-circle', // UNUSED
    'pinterest-white-circle', // UNUSED
    'youtube-white-circle',
    'x-white',
    'x-white-circle', // UNUSED
    'facebook-white',
    'instagram-white',
    'tiktok-white',
    'twitter-white',
    'pinterest-white',
    'youtube-white',
    'facebook-black',
    'instagram-black',
    'tiktok-black',
    'pinterest-black',
    'youtube-black',
    'twitter-black',
    'x-black',
    'social-instagram',
    'social-instagram-bold',
    'social-tiktok-bold',
    'social-facebook-bold',
    'social-twitter-bold',
    'social-x-bold',
    'linkedin',
    'linkedin-white',
    'linkedin-black',
    'google',
  ],
} as const
