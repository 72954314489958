import brandList from '@components/BrandIcon/brandList'
import config from '@configs/index'
import CurrencyService from '@services/CurrencyService'
import { generateProductImagePath } from '@utils/product'
import queryString from 'query-string'
import Log from '../services/Log'
import { IProduct } from '../types/product'
import { Config, VMProduct, VMProductPrice } from '../types/virtualMirror'
import { fetchJsFromCDN } from './fetchFromCdn'
import { getProductPriceByCustomerSegments } from '@components/common/UI/ProductPrice/utils/utils'

export const VIRTUAL_MIRROR_SCRIPT_ID = 'virtual-mirror'
export const VIRTUAL_MIRROR_KEY = 'VIRTUAL_MIRROR'
export const VIRTUAL_MIRROR_WISHLIST_TOGGLE = 'VIRTUAL_MIRROR_WISHLIST_TOGGLE'
export const VIRTUAL_MIRROR_WISHLIST_ITEM_ID = 'VIRTUAL_MIRROR_WISHLIST_ITEM_ID'

interface IVirtualMirrorUtils {
  initVirtualMirror: (config: Config, scriptUrl: string) => void
  unmountVirtualMirror: () => void
  convertServerProduct: (product: IProduct, baseUrl: string, customerSegments: string[]) => VMProduct
  getProductPrices: (product: IProduct, customerSegments: string[]) => any
}

class VirtualMirrorUtils implements IVirtualMirrorUtils {
  private widget: VirtualMirrorWidget | null
  private promiseArr: Promise<unknown>[]
  constructor() {
    this.widget = null
    this.promiseArr = []
  }
  private isVirtualMirrorScriptLoaded = (scriptId: string) => {
    return !!document.getElementById(scriptId)
  }
  private loadVirtualMirrorScript = (
    scriptUrl: string,
    key = VIRTUAL_MIRROR_KEY,
    scriptId = VIRTUAL_MIRROR_SCRIPT_ID
  ) => {
    if (this.isVirtualMirrorScriptLoaded(scriptId)) {
      return
    }
    this.promiseArr.push(fetchJsFromCDN(scriptUrl, key, { id: scriptId }))
  }
  public initVirtualMirror = async (config: Config, scriptUrl: string) => {
    try {
      this.loadVirtualMirrorScript(scriptUrl)
      await Promise.race(this.promiseArr)
      this.warmUpVirtualMirror()
      this.widget = vmmv.VMWidgetApp.new(config)
      this.widget.init()
    } catch (e) {
      Log.error('Could not init virtual mirror')
    }
  }

  public unmountVirtualMirror = () => {
    if (this.widget) {
      this.widget.unmount()
    }
  }

  private warmUpVirtualMirror = () => {
    if (!this.isVirtualMirrorScriptLoaded(VIRTUAL_MIRROR_SCRIPT_ID)) {
      return
    }
    vmmv.warmUp()
  }

  public convertServerProduct = (product: IProduct, baseUrl: string): VMProduct => {
    const imagePath = generateProductImagePath(baseUrl, 'Thumbnail', product, undefined, product.attachments)
    const getImageUrl = (width: number) => {
      return queryString.stringifyUrl({
        url: imagePath.toString(),
        query: {
          impolicy: config.productImageAkamaiPolicy,
          wid: width,
        },
      })
    }

    return {
      upc: product.name,
      code: product.manufacturer || '',
      name: product.productAttributes['MODEL_NAME'],
      colorCode: product.productAttributes['COLOR_CODE'],
      thumbnailUrl: getImageUrl(200),
      lensColorLabel: product.productAttributes['LENS_COLOR'],
      frameColorLabel: product.productAttributes['FRONT_COLOR'],
      styleName: product.productAttributes['MODEL_NAME'],
      brand: {
        name: product.productAttributes['BRAND'],
        logoUrl: brandList.find(brand => brand.name === product.productAttributes['BRAND'])?.logo || '',
      },
    }
  }

  public getProductPrices = (product: IProduct, customerSegments: string[]): VMProductPrice => {
    const prices = getProductPriceByCustomerSegments(product.x_price, customerSegments)
    const previousPrice = prices?.offerPrice === prices?.listPrice ? undefined : prices?.listPrice

    return {
      upc: product.name,
      currency: CurrencyService.getSymbolByName(prices?.currency || ''),
      current: {
        text: prices?.offerPrice ? Number(prices?.offerPrice).toFixed(2).toString() : '',
        value: prices?.offerPrice || 0,
      },
      previous: previousPrice
        ? {
            text: Number(previousPrice).toFixed(2).toString(),
            value: previousPrice,
          }
        : undefined,
    }
  }

  public setCurrentUpc = (products: VMProduct[], currentPartNumber: string): VMProduct[] => {
    for (let i = 1; i < products.length; i++) {
      if (products[i].upc === currentPartNumber) {
        let temp = products[0]
        products[0] = products[i]
        products[i] = temp
        break
      }
    }

    return products
  }
}

export const virtualMirrorUtils = new VirtualMirrorUtils()
