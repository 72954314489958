// TODO migrate to object styled
import { Tab } from '@mui/material'
import { StyledTabProps } from '@typesApp/tab'
import styled from '@mui/material/styles/styled'
import React from 'react'

export const TabWithStyle = styled((props: StyledTabProps) => (
  <Tab style={{ marginBottom: '1px solid black', overflow: 'visible' }} {...props} />
))`
  &.MuiTab-root .MuiTouchRipple-root {
    border-bottom: 1px solid #ccc;
  }  
  &.Mui-selected {
    color: ${({ theme, parentbg }) => (parentbg === 'light' ? theme.palette.custom.black : theme.palette.custom.white)};

    svg {
      margin-bottom: 3px;
      fill: ${({ theme, parentbg }) =>
        parentbg === 'light' ? theme.palette.background.light.primary : theme.palette.background.dark.primary};
      stroke: ${({ theme, parentbg }) =>
        parentbg === 'light' ? theme.palette.custom.black : theme.palette.custom.white};
    }
  }

  svg {
    margin-bottom: 3px;
    fill: ${({ theme, parentbg }) =>
      parentbg === 'light' ? theme.palette.background.light.primary : theme.palette.background.dark.primary};
    stroke: ${({ theme, parentbg }) =>
      parentbg === 'light' ? theme.palette.background.light.quaternary : theme.palette.background.light.secondary};
  }

  ${({ theme }) => theme.breakpoints.up('xs')} {
    font-size: ${({ theme }) => theme.spacing(3)};
    line-height: 1.33;
    min-height: ${({ theme }) => theme.spacing(4)};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: ${({ theme }) => theme.spacing(3.5)};
    line-height: 1.43;
    min-height: ${({ theme }) => theme.spacing(5)};
  }

  color: ${({ theme, parentbg }) =>
    parentbg === 'light' ? theme.palette.custom.light1.grey : theme.palette.custom.light.grey};
  font-family: inherit;
  font-weight: 600;
  text-align: inherit;
  padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(4)};
}


`
// &:hover {
//   svg {
//     margin-bottom: 3px;
//     fill: ${({theme, parentbg}) =>
//       parentbg === 'light' ? theme.palette.background.light.primary  : theme.palette.background.dark.primary};
//     stroke: ${({theme, parentbg}) =>
//       parentbg === 'light' ? theme.palette.background.dark.tertiary : theme.palette.background.dark.quaternary};
//   }

//   color: ${({theme, parentbg}) =>
//     parentbg === 'light' ? theme.palette.text.dark.tertiary : theme.palette.text.dark.quaternary};

//   &:after {
//     display: block;
//     content: '';
//     width: 100%;
//     height: 2px;
//     background-color: ${({theme}) => theme.palette.background.dark.quaternary};
//     position: absolute;
//     bottom: 0px;

//   }
