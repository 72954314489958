import { Button } from '@components/UI/Button'
import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'

export const StyledSidebar = styled('div', {
  name: 'Sidebar',
})(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(16),
}))

export const StyledHeading = styled(Typography, {
  name: 'SidebarHeading',
})(({ theme }) => ({
  marginBottom: theme.spacing(12),
  fontWeight: 'bold',
}))

export const StyledButtonWrapper = styled('li', {
  name: 'SidebarButtonWrapper',
})(({ theme }) => ({
  marginTop: theme.spacing(8),
  width: 'fit-content',
}))

export const StyledButton = styled(Button, {
  name: 'SidebarButtonWrapper',
})(() => ({
  width: 'fit-content',
}))

export const StyledScreenReaderOnly = styled('span', {
  name: 'ScreenReaderOnly',
})(() => ({
  position: 'absolute',
  width: 1,
  height: 1,
  padding: 0,
  margin: -1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  border: 0,
}))
