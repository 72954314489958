import React, { PropsWithChildren, useContext } from 'react'
import { v4 as uuid } from 'uuid'
import { IPlacementItem } from '@typesApp/cmsPlacement/Placement'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { ICMTeaser } from '@typesApp/cmsPlacement/CMTeaser'
import MediaOverlap from '@components/Cms/CMSContent/components/MediaOverlapV2'
import { StyledGridOfProductsItem } from '../GridOfProducts/GridOfProducts.style'
import {
  ProductItemTeaserTitle,
  StyledProductItem,
  StyledCTA,
  ProductTeaserImage,
  ProductTeaserImage2,
  StyledGridProductItem,
  StyledGridCategoryItem,
} from './ProductCarousel.style'
import { ProductContext } from '@components/PagesSeo/product/context/ProductContext'
import {
  ProductPriceInitialPricePDPForCMS,
  ProductPriceContainerForCMS,
  ProductPriceCurrentPriceForCMS,
  ProductPriceDiscountBoxForCMS,
} from '@pages_views/ProductDetails/components/ProductPriceForCms.style'
import { GridContainer, GridItem } from '@components/UI/Grid'
import { ButtonAsLink } from '@components/UI/Button'
import { CmsProductTile } from '../CmsProductTile'

const ProductItem: React.FC<{ teaser: ILXTeaser; viewType?: IViewType }> = ({ teaser, viewType }) => {
  const isFourItemBoardCarouselSlide = viewType?.includes('four-item-board-carousel-slide') || false
  const isFourItemBoardCarouselTile = viewType?.includes('four-item-board-carousel-tile') || false
  return (
    <>
      {isFourItemBoardCarouselSlide && (
        <ProductItemWithSlide teaser={teaser} viewType={viewType} crop={'PRODUCT_4_ITEMS'} />
      )}
      {isFourItemBoardCarouselTile && <ProductItemWithTiles teaser={teaser} viewType={viewType} />}
    </>
  )
}

export interface ProductItemWithSlideProps {
  teaser: ILXTeaser
  viewType?: IViewType
  crop: string
}

export const ProductItemWithSlide: React.FC<PropsWithChildren<ProductItemWithSlideProps>> = ({
  teaser,
  viewType,
  crop,
}) => {
  const getProductItemContainer = (viewtype: IViewType | undefined) => {
    switch (viewtype) {
      case 'grid-of-products':
        return StyledGridProductItem
      default:
        return StyledProductItem
    }
  }
  const hasHoverImage = (viewtype: IViewType | undefined) => {
    switch (viewtype) {
      case 'grid-of-products':
        return false
      default:
        return true
    }
  }
  const StyledProductItemContainer = getProductItemContainer(viewType)
  const price = teaser.teaserText1 && teaser.teaserText1.replace(/<\/?[^>]+(>|$)/g, '').split('|')
  return (
    <>
      <StyledProductItemContainer crop={crop}>
        <ProductItemTeaserTitle>{teaser.teaserTitle1}</ProductItemTeaserTitle>
        <ProductItemTeaserTitle className="title2">{teaser.teaserTitle2}</ProductItemTeaserTitle>
        <ProductTeaserImage crop={crop}>
          <MediaOverlap crop={crop} teaser={teaser} viewType={viewType} hasHoverImage={hasHoverImage(viewType)} />
        </ProductTeaserImage>
        <ProductItemTeaserTitle className="title3">{teaser.teaserTitle3}</ProductItemTeaserTitle>
        <ProductItemTeaserTitle className="title4">{teaser.teaserTitle4}</ProductItemTeaserTitle>
        {price && (
          <ProductPriceContainerForCMS isPDP={true} isCompact={false}>
            <ProductPriceInitialPricePDPForCMS>{price[0]}</ProductPriceInitialPricePDPForCMS>
            <ProductPriceCurrentPriceForCMS isPDP={true}>{price[1]}</ProductPriceCurrentPriceForCMS>
            <ProductPriceDiscountBoxForCMS>{price[2]}</ProductPriceDiscountBoxForCMS>
          </ProductPriceContainerForCMS>
        )}
      </StyledProductItemContainer>
    </>
  )
}

export const CategoryItemWithSlide: React.FC<{ teaser: ICMTeaser; viewType?: IViewType; crop: string }> = ({
  teaser,
  viewType,
  crop,
}) => {
  const getProductItemContainer = (viewtype: IViewType | undefined) => {
    switch (viewtype) {
      case 'grid-of-products':
        return StyledGridProductItem
      case 'cly-category-with-cta':
        return StyledGridCategoryItem
      default:
        return StyledProductItem
    }
  }
  const hasHoverImage = (viewtype: IViewType | undefined) => {
    switch (viewtype) {
      case 'grid-of-products':
        return false
      default:
        return true
    }
  }
  const StyledProductItemContainer = getProductItemContainer(viewType)
  return (
    <StyledProductItemContainer crop={crop}>
      <MediaOverlap crop={crop} teaser={teaser} viewType={viewType} hasHoverImage={hasHoverImage(viewType)} />
    </StyledProductItemContainer>
  )
}

export const ProductItemWithTiles: React.FC<{ teaser: ILXTeaser; viewType?: IViewType; crop? }> = ({
  teaser,
  viewType,
  crop,
}) => {
  return (
    <StyledProductItem crop={crop}>
      <ProductTeaserImage2>
        <MediaOverlap crop={crop} teaser={teaser} viewType={viewType} hasHoverImage />
      </ProductTeaserImage2>
      <GridContainer>
        <GridItem xs={4} sm={12} md={12}>
          {teaser?.teaserLXCallToActionSettings?.map((item, index) => {
            return <ButtonAsLink key={`cms-content__text-module--cta-${uuid()}`} href={item.target.formattedUrl} />
          })}
        </GridItem>
      </GridContainer>
    </StyledProductItem>
  )
}

export const ProductItemWithTabs: React.FC<{
  gridKeyElement: any
  teasableItems: IPlacementItem[]
  viewType?: IViewType
  placementViewType?: IViewType
  preventImagelazyLoad?: boolean
  useSkuPriceFallback?: boolean
}> = ({ gridKeyElement, viewType, placementViewType, preventImagelazyLoad }) => {
  const productContext = useContext(ProductContext)
  const product = productContext?.product
  if (product == null) return null

  return (
    <StyledGridOfProductsItem key={gridKeyElement} className={placementViewType || ''}>
      <CmsProductTile viewType={viewType} preventImagelazyLoad={preventImagelazyLoad} hidePrice />
    </StyledGridOfProductsItem>
  )
}

export default ProductItem
