import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { Theme } from '@mui/material'

export const isDescriptionOnly = (teasers: ILXTeaser[]) =>
  teasers.every(teaser => teaser.teaserLXCallToActionSettings.length === 0)

export const isStackContent = (type: IViewType) => {
  return type === 'boards-with-fields-below'
}

export const boardsCarouselGap = (isWithCarousel: boolean, isMobile: boolean) => {
  if (isWithCarousel && !isMobile) return 32
  else return 16
}

export const applyCarousel = (teasers: ILXTeaser[], viewType: IViewType, onBreakpoint: boolean) => {
  if (teasers.length > 4) return true
  if (teasers.length === 3 && onBreakpoint) return isStackContent(viewType)
  if (teasers.length === 4 && isDescriptionOnly(teasers)) return onBreakpoint
  if (teasers.length === 4 && !onBreakpoint) return isStackContent(viewType)
  else return false
}

export const boardsMarginConfig = (isWithCarousel: boolean | undefined, theme: Theme) => {
  if (isWithCarousel)
    return {
      marginLeft: 0,
    }
  return {
    marginInline: theme.spacing(16),
    [theme.breakpoints.down('md')]: {
      marginInline: theme.spacing(8),
    },
    [theme.breakpoints.down(501)]: {
      marginInline: theme.spacing(4),
    },
  }
}

const transformOverlayTextAlign = (textAlign: React.CSSProperties['justifyContent']) => {
  switch (textAlign) {
    case 'left':
      return 'start'
    case 'right':
      return 'end'
    default:
      return 'center'
  }
}

export const boardsCtaInternalFlex = (
  viewType: IViewType | undefined,
  internal,
  overlayTextAlign: React.CSSProperties['justifyContent']
) => {
  if ((viewType === 'boards-with-fields-below' || viewType === 'boards-with-fields-below-stack') && !!internal)
    return {
      alignItems: transformOverlayTextAlign(overlayTextAlign),
      flexDirection: 'column',
    }

  return {
    justifyContent: overlayTextAlign,
    flexDirection: 'row',
    'a:first-of-type': {
      marginLeft: overlayTextAlign === 'left' ? 0 : 8,
    },
  }
}
