import config from '@configs/config.base'
import React, { CSSProperties, MouseEvent, TouchEvent, useEffect, useState } from 'react'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { Attachment, IProduct, PictureType, ProductImageUsage } from '../../types/product'

import { generateProductImagePath } from '@utils/product'
import dynamic from 'next/dynamic'
import queryString, { ParsedQuery } from 'query-string'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import { useSite } from '../../foundation/hooks/useSite'
import { ProductImageHead } from './ProductImageHead'
import mockedData from './mocked-attachments'

const VideoPlayer = dynamic(() => import('../Cms/CmsComponents/CmsCommonMedia/VideoPlayer').then(m => m.VideoPlayer), {
  ssr: false,
})

export const PDP_PRODUCT_IMAGE_FRONT_VIEW_SEQUENCE = '1.0'
export const PDP_PRODUCT_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE = '2.0'
export const PLP_PRODUCT_EYEWEAR_IMAGE_FRONT_VIEW_SEQUENCE = '2'
export const PLP_PRODUCT_IMAGE_FRONT_VIEW_SEQUENCE = '1'
export const PLP_PRODUCT_EYEWEAR_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE = '1'
export const PLP_PRODUCT_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE = '2'

export const IMAGE_WITH_SHADOW_QUARTER_ANGLE_ID_INCLUDES = '_shad__qt'

const PictureProduct: React.FC<{
  src: string
  scrSet?: Record<string, string>
  draggable?: boolean
  alt?: string
  loading?: React.ImgHTMLAttributes<HTMLImageElement>['loading']
  onImageLoaded?: () => void
}> = ({ scrSet, src, draggable, alt, onImageLoaded, loading, ...rest }) => {
  return (
    <picture>
      {scrSet && scrSet[900] && <source media="(min-width: 1440px)" srcSet={scrSet[900]} />}
      {scrSet && scrSet[700] && <source media="(min-width: 1280px)" srcSet={scrSet[700]} />}
      {scrSet && scrSet[600] && <source media="(min-width: 1024px)" srcSet={scrSet[600]} />}
      {scrSet && scrSet[400] && <source media="(min-width: 601px)" srcSet={scrSet[400]} />}
      <img src={src} alt={alt} draggable={draggable} width="100%" {...rest} onLoad={onImageLoaded} loading={loading} />
    </picture>
  )
}

export type ProductImageProps = Pick<React.DOMAttributes<HTMLImageElement>, 'onLoad'> & {
  active?: boolean
  alt?: string
  attachments?: Attachment[]
  attr?: Record<string, string> | ParsedQuery<string> | undefined
  autoPlay?: boolean
  draggable?: boolean
  mpolicy?: string
  noImageStyle?: CSSProperties
  pictureType?: PictureType
  sequence?: string
  style?: any
  usage?: ProductImageUsage
  width?: number
  srcsetmap?: Record<number, string>
  onClick?: () => void
  onMouseMove?: (e: MouseEvent<HTMLImageElement>) => void
  onTouchMove?: (e: TouchEvent<HTMLImageElement>) => void
  onImageLoaded?: () => void
  backgroundColor?: string
  partialProduct?: IProduct
  isFramesProduct?: boolean
  preload?: boolean
  loading?: React.ImgHTMLAttributes<HTMLImageElement>['loading']
  controls?: boolean
  lazy?: boolean
  loadOnActive?: boolean
  crossOrigin?: React.MediaHTMLAttributes<HTMLImageElement>['crossOrigin']
}

const ProductImage = ({
  active = false,
  alt,
  attachments,
  width = 600,
  pictureType,
  sequence,
  usage = 'PDP',
  attr,
  noImageStyle,
  draggable,
  srcsetmap,
  backgroundColor,
  partialProduct,
  onImageLoaded,
  isFramesProduct = false,
  preload,
  controls = true,
  ...rest
}: ProductImageProps) => {
  const { mySite: site } = useSite()
  const [isRetina, setIsRetina] = useState(false)

  useEffect(() => {
    const isRetina = devicePixelRatio > 1
    setIsRetina(isRetina)
  }, [])
  const damDomain: string = site.xStoreCfg
    ? site.xStoreCfg['damDomain'] || config.defaultDamDomain
    : config.defaultDamDomain
  if (attachments === undefined && config.useMockedAttachments !== 'false') {
    attachments = mockedData.attachments
  }

  const publicUrl = !config.publicUrl.endsWith('/') ? config.publicUrl + '/' : config.publicUrl
  const url404 = publicUrl + 'images/common/404.svg'

  const imagePath = generateProductImagePath(
    damDomain,
    usage,
    partialProduct,
    pictureType,
    attachments,
    sequence,
    isFramesProduct
  )
  const getImageUrl = (width: number) => {
    return queryString.stringifyUrl({
      url: imagePath.toString(),
      query: {
        impolicy: 'VD_resize',
        wid: isRetina ? width * 2 : width,
        bgc: backgroundColor,
        ...attr,
      },
    })
  }

  const productImageSrcSets = (params: Record<number, string>) => {
    return Object.entries(params).reduce((set, [imageWidth, _resolution]) => {
      const path = getImageUrl(Number(imageWidth) || width)
      set[imageWidth] = path
      return set
    }, {})
  }
  const src = getImageUrl(width)
  const scrSet = !!srcsetmap && productImageSrcSets(srcsetmap)
  const isVideo = src.includes('productvideo')
  return (
    <>
      {preload && <ProductImageHead preload={scrSet ? scrSet : {}} src={src} />}
      {isVideo ? (
        <VideoPlayer
          url={src}
          width="100%"
          height="100%"
          style={{ position: 'relative' }}
          controls={controls}
          playsinline
          {...rest}
        />
      ) : imagePath !== url404 ? (
        <PictureProduct
          src={src}
          scrSet={scrSet ? scrSet : {}}
          alt={alt}
          draggable={draggable}
          {...rest}
          onImageLoaded={onImageLoaded}
          loading={!preload ? 'lazy' : 'eager'}
        />
      ) : (
        <img
          alt={alt}
          src={src}
          {...rest}
          width="100%"
          style={noImageStyle}
          draggable={draggable}
          loading={!preload ? 'lazy' : 'eager'}
        />
      )}
    </>
  )
}

export default ProductImage
