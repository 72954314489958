import React from 'react'
import {
  MediaOverlapContainer,
  MediaOverlapContainerEffect,
  MediaOverlapAnchor,
  MediaOverlapProductCarouselContainer,
  MediaOverlapGridOfProducts,
  MediaTermsAndConditionsCta,
  NoMobileGradient,
  SquatMobileGradient,
  MediaTermsAndConditionsCtaWhite,
  MediaOverlapThreeProductCarouselContainer,
  MediaOverlapFullWidthPromoContainer,
  MediaOverlapTwoColumnContainer,
  MediaOverlapLandscapePromoContainer,
  MediaOverlapTopPagePromoContainer,
  ShoppableWrapper,
  DefaultMobileGradient,
  DefaultTeaserGradient,
  LightTeaserGradient,
} from './MediaOverlap.style'
import { isCMCollection, isCMTeaser, isLXTeaser, isVideoMedia } from '@typesApp/cmsPlacement/Placement'
import usePlayerBanner from '@hooks/useBannerPlayer'
import { ShoppableTeaser } from '@components/Cms/CMSContent/components/ShoppableTeaser'
import { CustomVideoController } from '@components/Cms/CMSContent/components/MediaOverlapV2/components'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import CMSCommonMedia from '@components/Cms/CMSContent/components/CmsCommonMedia'
import TermsAndConditionsCta from '@components/Cms/CMSContent/components/TermsAndConditionsV2'
import MediaQuery from '@components/UI-CSS/MediaQuery'
import { LinkAsButton } from '@components/UI-CSS/LinkAsButton'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { ICMTeaser } from '@typesApp/cmsPlacement/CMTeaser'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { getTeaserPropsByView } from '@utils/cms/placements'
import { cmsApiService } from '@foundation/apis/cms/cms.ssr.service'
import { getDataElementId } from '@utils/common'
import { ImageOverlapProps, TeaserLinkProps } from './types/MediaOverlap.types'
import { IVideoMedia } from '@typesApp/cmsPlacement/Media'
import styles from './styles/MediaOverlap.module.scss'
import { IProduct } from '@typesApp/product'

const getMobileGradient = (viewtype: IViewType) => {
  switch (viewtype) {
    case 'squat-banner':
      return SquatMobileGradient
    case 'full-width-banner':
      return DefaultMobileGradient
    default:
      return NoMobileGradient
  }
}

const getMediaOverlapContainer = (viewtype: IViewType, viewtype2?: IViewType) => {
  switch (viewtype) {
    case 'three-products-carousel':
    case 'three-products-carousel-slide':
      return MediaOverlapThreeProductCarouselContainer
    case 'four-item-board-carousel-slide':
    case 'four-item-board-carousel-tile':
      return MediaOverlapProductCarouselContainer
    case 'two-column-banner-lg':
      return MediaOverlapTwoColumnContainer
    case 'fullwidth-promo-banner':
    case 'fullwidth-promo-banner-2':
    case 'fullwidth-promo-banner-3':
    case 'fullwidth-promo-banner-4':
      return MediaOverlapFullWidthPromoContainer
    case 'full-width-banner-with-shadow':
      return MediaOverlapContainerEffect
    case 'landscape-promo-banner':
    case 'landscape-promo-banner-2':
    case 'landscape-promo-banner-3':
      return MediaOverlapLandscapePromoContainer
    case 'top-page-promo':
    case 'top-page-promo-2':
    case 'top-page-promo-3':
      return MediaOverlapTopPagePromoContainer
    case 'grid-of-products':
      return MediaOverlapGridOfProducts
    case 'default':
      switch (viewtype2) {
        case 'fullwidth-banner-collection-promo':
        case 'fullwidth-banner-collection-promo-multiple':
          return MediaOverlapFullWidthPromoContainer
        case 'landscape-banner-collection-promo':
        case 'landscape-banner-collection-promo-multiple':
          return MediaOverlapLandscapePromoContainer
        case 'top-page-banner-collection-promo':
        case 'top-page-banner-collection-promo-multiple':
          return MediaOverlapTopPagePromoContainer
        default:
          return MediaOverlapContainer
      }
    default:
      return MediaOverlapContainer
  }
}

const MediaOverlap: React.FC<ImageOverlapProps> = ({
  isLazy = true,
  isFetchPriority = false,
  viewType,
  teaser,
  placement,
  teaserIndex,
  crop,
  hasHoverImage,
  fullHeight,
}) => {
  const { basePath } = useStoreIdentity()
  const moduleTeaser = (teaser || placement?.items?.find(isLXTeaser)) as ILXTeaser
  const moduleTeaserCM = (teaser || placement?.items?.find(isCMTeaser)) as ICMTeaser
  const viewtype = viewType ?? placement?.viewtype ?? 'default'

  const media = moduleTeaser?.media
  const desktopMedia = media?.[0] ?? undefined
  const mobileMedia = media?.[1] ?? desktopMedia
  const mediaHover = media?.[1] ?? undefined

  const isVideo = isVideoMedia(desktopMedia) || isVideoMedia(mobileMedia)
  const playerBanner = usePlayerBanner(mobileMedia as IVideoMedia)

  const getFormattedUrl = (): TeaserLinkProps => {
    let formattedUrl: TeaserLinkProps = {
      toLink: moduleTeaserCM ? `${basePath}${moduleTeaserCM.formattedUrl || ''}` : '',
    }

    if (!formattedUrl.toLink) {
      const linkTarget = moduleTeaser?.teaserLXCallToActionSettings?.[0]?.target
      if (linkTarget) {
        const isExternalLink = linkTarget.type === 'CMExternalLink'
        formattedUrl = isExternalLink
          ? { toLink: linkTarget.url, isExternalLink: true }
          : { toLink: `${basePath}${linkTarget.formattedUrl || ''}` }
      }
    }

    return formattedUrl
  }

  const { toLink, isExternalLink } = getFormattedUrl()
  const placementCounter = teaser?.placementCounter || placement?.placementCounter

  const getLinkText = (): string => {
    if (moduleTeaserCM.teaserTitle && moduleTeaserCM.teaserTitle !== '') {
      return moduleTeaserCM.teaserTitle
    } else if (moduleTeaserCM.title !== '') {
      return moduleTeaserCM.title
    } else {
      return moduleTeaserCM.teaserTarget.title
    }
  }

  const teaserName = teaser?.name ? `${teaser?.name.replaceAll(' ', '_')}` : ''
  const bannerImageCommonProps = {
    'aria-label': `Placement_${teaserName} IMG link. Teaser №${teaserIndex ?? 0}`,
    'data-element-id': getDataElementId(viewtype, placementCounter),
    target: isExternalLink ? '_blank' : '_self',
  }

  if (moduleTeaser?.type !== 'LXTeaser' && moduleTeaser?.type !== 'CMTeaser' && moduleTeaser?.type !== 'CMExternalPage')
    return null
  const termsAndConditionsVisible = !moduleTeaser?.targetsTermsAndConditions?.target?.detailText ? 0 : 1
  const collection = placement?.items?.filter(item => item !== null).filter(isCMCollection)
  const firstItem = collection && collection.length > 0 ? collection[0] : null
  const collectionHotzones = (firstItem?.teasableItems[0] as ILXTeaser)?.hotZones

  const isFullWidthCollection =
    firstItem?.viewtype === 'fullwidth-banner-collection-promo' ||
    firstItem?.viewtype === 'landscape-banner-collection-promo' ||
    firstItem?.viewtype === 'top-page-banner-collection-promo' ||
    firstItem?.viewtype?.includes('-banner-collection-promo-multiple')

  const isFullWidth =
    viewtype === 'fullwidth-promo-banner' ||
    viewtype === 'landscape-promo-banner' ||
    viewtype === 'top-page-promo' ||
    viewtype?.includes('-promo-banner-2') ||
    viewtype?.includes('-promo-2') ||
    viewtype?.includes('-promo-banner-3') ||
    viewtype?.includes('-promo-3') ||
    viewtype === 'fullwidth-promo-banner-4' ||
    isFullWidthCollection

  const isFullWidthTermsVisible =
    (isFullWidth && viewtype !== 'fullwidth-promo-banner-4') || viewtype === 'fullwidth-promo-banner-4'

  const MediaOverlapWrapper = getMediaOverlapContainer(viewtype, firstItem?.viewtype)
  const { teaserOverlayTextAlign, teaserOverlaySettings } = getTeaserPropsByView(viewtype)
  const teaserOverlayTextAlignValue = moduleTeaser[teaserOverlayTextAlign]
  const teaserOverlaySettingsValue = moduleTeaser[teaserOverlaySettings]
  const isBlockCenter = teaserOverlaySettingsValue?.includes('center')
  const MobileGradient = getMobileGradient(viewtype)
  const placementHotZones =
    collectionHotzones && viewtype !== 'two-column-banner-lg' ? collectionHotzones : moduleTeaser?.hotZones

  const filteredItems = placementHotZones?.filter((zone, index, self) => {
    const isDuplicate = self.findIndex(z => z.linkedContent?.title === zone.linkedContent?.title) !== index
    const productData =
      // TODO: clean up type casting
      (zone?.productData as unknown as IProduct[])?.length == null
        ? [zone?.productData]
        : (zone?.productData as unknown as IProduct[])?.some(item => item.items)
    return !isDuplicate && productData?.[0]?.items
  })
  const isShoppableImage = (filteredItems?.length && filteredItems?.length >= 0) || false
  const getMediaTermsAndConditionsCta = (isBlockCenter: boolean) => {
    return (isBlockCenter && MediaTermsAndConditionsCtaWhite) || MediaTermsAndConditionsCta
  }
  const MediaOverlapTermsAndConditionsCta = getMediaTermsAndConditionsCta(isBlockCenter)

  const isOverlayGradientVisible = moduleTeaser.settings?.isOverlay === 'true'
  let TeaserOverlay = DefaultTeaserGradient

  if (moduleTeaser.settings?.overlayColor === 'light') {
    TeaserOverlay = LightTeaserGradient
  }

  const hasCTALink = ['CMTeaser', 'CMExternalPage'].includes(moduleTeaserCM?.type) && getLinkText() !== ''
  const ctaLinkText = hasCTALink ? getLinkText() : undefined

  const cmsCommonMediaProps = {
    alt: ctaLinkText,
    fullHeight,
    hasHoverImage,
    isFetchPriority,
    isLazy,
    mediaHover,
    placementName: viewtype,
    playerBannerHook: playerBanner,
    type: crop ?? '',
  }

  const CommonMedia = () => (
    <>
      <CMSCommonMedia {...cmsCommonMediaProps} className={styles.mediaDesktop} media={desktopMedia} />
      <CMSCommonMedia {...cmsCommonMediaProps} className={styles.mediaMobile} media={mobileMedia} />
    </>
  )

  return (
    <MediaOverlapWrapper>
      <ShoppableWrapper>{isShoppableImage && <ShoppableTeaser />}</ShoppableWrapper>
      {!isOverlayGradientVisible && !isVideo && <MobileGradient />}
      {isOverlayGradientVisible && !isVideo && <TeaserOverlay />}

      {termsAndConditionsVisible && isFullWidthTermsVisible ? (
        <MediaQuery size="desktop">
          <MediaOverlapTermsAndConditionsCta viewtype={viewtype}>
            <TermsAndConditionsCta
              overlayTextAlign={cmsApiService.getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)}
              alignment={placement?.viewtype}
              terms={moduleTeaser?.targetsTermsAndConditions}
            />
          </MediaOverlapTermsAndConditionsCta>
        </MediaQuery>
      ) : null}

      {toLink ? (
        <>
          <MediaOverlapAnchor
            {...bannerImageCommonProps}
            external={isExternalLink}
            href={toLink}
            fullHeight={fullHeight}
          >
            <CommonMedia />
          </MediaOverlapAnchor>

          {hasCTALink && (
            <LinkAsButton
              data-element-id={getDataElementId(viewtype, placementCounter, 'CTA')}
              href={toLink}
              variant="outlined-dark"
            >
              {ctaLinkText}
            </LinkAsButton>
          )}
        </>
      ) : (
        <CommonMedia />
      )}

      {isVideo && <CustomVideoController playerBanner={playerBanner} />}
    </MediaOverlapWrapper>
  )
}

export default MediaOverlap
