import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'next-i18next'
import clsx from 'clsx'
import { ProductMedia } from '@components/Cms/CMSContent/components/CmsMedia/Media/ProductMedia'
import {
  ContextWrapperData,
  PlacementContext,
  PlacementContextType,
} from '@components/Cms/CMSContent/PlacementContextWrapper/PlacementContextWrapper'
import { LinkStyled } from '@components/UI-CSS/LinkStyled'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { NOT_FOUND } from '@constants/routes'
import { bffProductApi } from '@features/bffProduct/query'
import useIsInViewport from '@hooks/useIsInViewport'
import { isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { Attachment, IProduct } from '@typesApp/product'
import { TLightProduct } from '@utils/productNew'
import { ProductContextProvider } from '@components/PagesSeo/product/context/ProductContext'
import styles from './styles/index.module.scss'
import { ProductPriceAlgolia } from '@pages_views/ProductDetails/components/ProductPriceAlgolia'

type TShoppableTeaser = {
  className?: string
}

export const ShoppableTeaser: React.FC<TShoppableTeaser> = ({ className }) => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  const { data } = context as ContextWrapperData<PlacementContextType>
  const teaser = data?.placement?.items?.find(isLXTeaser)
  const { hotZones, hotZonesSettings } = teaser ?? {}

  const { t } = useTranslation()
  const teaserRef = useRef<HTMLDivElement | null>(null)
  const inView = useIsInViewport(teaserRef, {})

  const productPartnumberList = useMemo(
    () =>
      hotZones?.reduce<string[]>((curr, hotzone) => {
        const partnumber = hotzone.linkedContent?.externalId?.split('/').pop()
        if (partnumber) curr.push(partnumber)
        return curr
      }, []) || [],
    [hotZones]
  )
  const [getproducts, result] = bffProductApi.endpoints.getProductsByPartnumber.useLazyQuery()

  useEffect(() => {
    if (productPartnumberList.length > 0 && inView) {
      getproducts({
        partnumber: productPartnumberList,
        profile: 'light_product',
      })
    }
  }, [getproducts, inView, productPartnumberList])

  return (
    <div
      ref={teaserRef}
      className={clsx(className, styles.shoppableTeaser, {
        [styles.shoppableTeaserLight]: hotZonesSettings?.lightContrast,
      })}
    >
      <button className={styles.shoppableTeaserIcon}>
        <SVGIcon library="global" name="bag-checkout" />
      </button>

      <div className={styles.shoppableTeaserProducts}>
        {productPartnumberList?.map(partnumber => {
          let productData: TLightProduct | undefined = result.data?.[partnumber]
          if (!productData) return null
          productData = { ...productData, x_price: { ...productData.prices } }
          return (
            <ProductContextProvider
              key={productData.partnumberId}
              // NOTE: temporary typecasting as product types need to be refactored and aligned throughout the app
              productData={{ product: productData as unknown as IProduct }}
            >
              <LinkStyled
                className={styles.shoppableTeaserLink}
                href={productData.url ?? NOT_FOUND}
                variant="no-animation"
              >
                <div className={styles.shoppableTeaserImageContainer}>
                  <ProductMedia
                    attachments={productData?.attachments as Attachment[]}
                    sequence={'1.0'}
                    usage={'PLP'}
                    width={200}
                    lazy={true}
                  />
                </div>
                <ProductPriceAlgolia />
              </LinkStyled>
            </ProductContextProvider>
          )
        })}
      </div>
    </div>
  )
}
