import React from 'react'
import { useTranslation } from 'next-i18next'
import { v4 as uuid } from 'uuid'
import { ProductType } from '@typesApp/product'
import config from '@configs/config.base'
import styles from './ShippingTimesBanner.module.scss'

export interface ShippingTimesBannerProps {
  productType: ProductType
}

export const ShippingTimesBanner: React.FC<ShippingTimesBannerProps> = ({ productType }) => {
  const { t } = useTranslation()
  const content: string[] = t(`ShippingTimesBanner.Content-${productType}`, { returnObjects: true })
  const isEnabled = !!config.pdp.showShippingTimesBanner

  return !isEnabled ? null : (
    <div className={styles.shippingTimesBanner}>
      {content?.map(line => (
        <p key={uuid()} className={styles.shippingTimesContentItem} dangerouslySetInnerHTML={{ __html: line }} />
      ))}
    </div>
  )
}
