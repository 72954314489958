import React from 'react'

export type CmsImageCropsImproved = {
  [key: string | number]: PictureProps['crops']
}

type PictureProps = {
  alt?: string | undefined
  deskL?: string
  deskS?: string
  tabletL?: string
  tabletP?: string
  xxl?: string
  xl?: string
  lg?: string
  md?: string
  sm?: string
  xs?: string
  isLazy?: boolean
  src?: string
  crops?: {
    [key: string]: {
      crop: string
      width: number
      height: number
      sizes?: {
        density: '1x' | '2x'
        width: number
        height: number
      }[]
    }
  }
}

/**
 * NOTE: this component is for temporary use only.
 * Responsive image formats and naming convention needs to be aligned with
 * white label implementation before this can be switched to CmsPicture component
 *
 * @deprecated
 */
export const Picture: React.FC<PictureProps> = ({
  alt,
  deskL,
  deskS,
  tabletL,
  tabletP,
  xxl,
  xl,
  lg,
  md,
  sm,
  xs,
  isLazy,
  src,
  crops,
}) => {
  if (!src) return null
  return (
    <picture>
      {xxl ? (
        <source
          media="(min-width: 1440px)"
          srcSet={xxl}
          height={crops?.xxl?.sizes?.[0]?.height}
          width={crops?.xxl?.sizes?.[0]?.width}
        />
      ) : (
        deskL && (
          <source
            media="(min-width: 1440px)"
            srcSet={deskL}
            height={crops?.deskL?.height}
            width={crops?.deskL?.width}
          />
        )
      )}
      {xl ? (
        <source
          media="(min-width: 1280px) and (max-width: 1439px)"
          srcSet={xl}
          height={crops?.xl?.sizes?.[0]?.height}
          width={crops?.xl?.sizes?.[0]?.width}
        />
      ) : (
        deskS && (
          <source
            media="(min-width: 1280px) and (max-width: 1439px)"
            srcSet={deskS}
            height={crops?.deskS?.height}
            width={crops?.deskS?.width}
          />
        )
      )}
      {lg ? (
        <source
          media="(min-width: 1024px) and (max-width: 1279px)"
          srcSet={lg}
          height={crops?.lg?.sizes?.[0]?.height}
          width={crops?.lg?.sizes?.[0]?.width}
        />
      ) : (
        tabletL && (
          <source
            media="(min-width: 1024px) and (max-width: 1279px)"
            srcSet={tabletL}
            height={crops?.tabletL?.height}
            width={crops?.tabletL?.width}
          />
        )
      )}
      {md ? (
        <source
          media="(min-width: 768px) and (max-width: 1023px)"
          srcSet={md}
          height={crops?.md?.sizes?.[0]?.height}
          width={crops?.md?.sizes?.[0]?.width}
        />
      ) : (
        tabletP && (
          <source
            media="(min-width: 601px) and (max-width: 1023px)"
            srcSet={tabletP}
            height={crops?.tabletP?.height}
            width={crops?.tabletP?.width}
          />
        )
      )}
      {sm && (
        <source
          media="(min-width: 576px) and (max-width: 767px)"
          srcSet={sm}
          height={crops?.sm?.sizes?.[0]?.height}
          width={crops?.sm?.sizes?.[0]?.width}
        />
      )}

      <img
        src={src}
        alt={alt}
        crossOrigin="anonymous"
        srcSet={xs}
        loading={isLazy ? 'lazy' : 'eager'}
        height={crops?.mobile?.height}
        width={crops?.mobile?.width}
      />
    </picture>
  )
}
