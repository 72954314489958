import { getSize } from '@utils/productAttributes'
import { Trans, useTranslation } from 'next-i18next'
import React, { useMemo, useState } from 'react'
import { Attachment, IProduct, IProductAttributes } from '../../../../types/product'
import {
  AttributeLabel,
  AttributesContent,
  CheckboxContainer,
  CloseSubscribeButton,
  Container,
  Content,
  Description,
  DescriptionSuccess,
  Header,
  PrivacyLink,
  PrivacyPolicyContainer,
  StyledOOSFormControlLabel,
  StyledProductImage,
  SubscribeButton,
  Title,
} from './ProductAvailableNotificationDrawer.style'

import { StyledCheckbox } from '@components/UI/Checkbox'
import { IconButton } from '@components/UI/IconButton'
import { CloseIcon } from '@components/UI/Icons/close'
import { TextField } from '@components/UI/TextField'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { useDispatch, useSelector } from 'react-redux'
import { REG_EX } from '../../../../constants/common'
import { setOpenDrawerProductNotificationAvailable } from '../../../../features/ui/action'
import { openDrawerProductNotificationSelector } from '../../../../features/ui/selector'
import { userEmailSelector } from '../../../../features/user/selector'
import { sendNewsletterSubscriptionEvent } from '../../../../foundation/analytics/tealium/lib'
import { NewsletterService } from '../../../../foundation/apis/newsletter/newsletter.service'
import ProductAvailableNotification from '../../../../foundation/apis/product/product.service'
import { useSite } from '../../../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import {
  isNewsletterSubscriptionOnHoldSelector,
  isRememberMeEnabledSelector,
  storeCfgServiceLinksSelector,
} from '../../../../redux/selectors/site'
import Log from '../../../../services/Log'
import { createUserIdentity } from '@utils/user'

interface IProps {
  product?: IProduct
  attachments?: Attachment[]
  attributes?: IProductAttributes
}

const ProductAvailableNotificationDrawer: React.FC<IProps> = ({ product, attributes, ...props }) => {
  const { mySite } = useSite()
  const { basePath } = useStoreIdentity()
  const { t } = useTranslation()
  const { isMobile, isTablet, isDesktop } = useBreakpoints()
  const dispatch = useDispatch()
  const userEmail = useSelector(userEmailSelector)

  const [email, setEmail] = useState<string>(userEmail || '')
  const [emailError, setEmailError] = useState<boolean>(false)
  const [subscriptionResult, setSubscriptionResult] = useState<boolean>()
  const [isNewsletterChecked, setNewsletterChecked] = useState<boolean>(false)
  const productAvailableNotificationOpen = useSelector(openDrawerProductNotificationSelector)
  const serviceLinks = useSelector(storeCfgServiceLinksSelector)
  const isNewsletterSubscriptionOnHold = useSelector(isNewsletterSubscriptionOnHoldSelector)
  const isRememberMeEnabled = useSelector(isRememberMeEnabledSelector)
  const privacyLink = basePath + serviceLinks?.privacy

  const [isPristine, setIsPristine] = React.useState<boolean>(true)

  React.useEffect(() => {
    setIsPristine(email.length === 0)
  }, [email])

  const handleNewsletterChecked = () => setNewsletterChecked(v => !v)

  const handleNewsletterSubmit = async () => {
    try {
      await createUserIdentity(dispatch, isRememberMeEnabled)
      await NewsletterService.subscribeToNewsletter({
        email,
        newsletter_id: mySite.xStoreCfg?.newsletterSubscrType ?? 'NEWSLETTER',
        storeId: mySite.storeID,
        statusOnHold: isNewsletterSubscriptionOnHold,
      })
      sendNewsletterSubscriptionEvent(email)
    } catch (e) {
      Log.error(e as any as string)
    }
  }

  const handleEmailSubmit = async () => {
    try {
      await ProductAvailableNotification.subscribeToAvailableNotification({
        mySite,
        emailAddress: email,
        catentryId: `${product?.uniqueID}`,
      })
      setSubscriptionResult(true)
    } catch (e) {
      Log.error(e as any as string)
    }
  }

  const handleSubmit = async () => {
    if (REG_EX.EMAIL.test(email)) {
      if (isNewsletterChecked) {
        handleNewsletterSubmit()
      }
      await handleEmailSubmit()
    } else setEmailError(true)
  }

  const productImageWidth = useMemo<number>(() => {
    switch (true) {
      case isMobile:
        return 345
      case isTablet:
        return 740
      case isDesktop:
        return 378
      default:
        return 378
    }
  }, [isMobile, isTablet, isDesktop])

  const toggleproductNotificationAvailableDrawer = () =>
    dispatch(setOpenDrawerProductNotificationAvailable(!productAvailableNotificationOpen))

  return (
    <SwipeableDrawer
      disableSwipeToOpen={true}
      open={productAvailableNotificationOpen}
      onClose={toggleproductNotificationAvailableDrawer}
      onOpen={() => {}}
      anchor={'right'}
    >
      <Container>
        <Header>
          <Title>{t('ProductAvailableNotificationDrawer.Title')}</Title>
          <IconButton onClick={toggleproductNotificationAvailableDrawer}>
            <CloseIcon htmlColor="white" />
          </IconButton>
        </Header>
        <Content>
          {subscriptionResult ? (
            <DescriptionSuccess>{t('ProductAvailableNotificationDrawer.DescriptionSuccess')}</DescriptionSuccess>
          ) : (
            <Description>{t('ProductAvailableNotificationDrawer.Description')}</Description>
          )}

          <StyledProductImage
            attachments={props.attachments}
            style={{ border: 'none' }}
            width={productImageWidth}
            sequence="15.0"
            backgroundColor="#fff"
            isFramesProduct
            usage="PDP"
            lazy={true}
          />

          {attributes && (
            <AttributesContent>
              <div>
                <AttributeLabel>{t('ProductAvailableNotificationDrawer.Labels.ModelName')}:</AttributeLabel>{' '}
                {attributes['DISPLAYSKU']}
              </div>
              <div>
                <AttributeLabel>{t('ProductAvailableNotificationDrawer.Labels.Frame')}:</AttributeLabel>{' '}
                {attributes['FRAME_SHAPE']}
              </div>
              <div>
                <AttributeLabel>{t('ProductAvailableNotificationDrawer.Labels.Lenses')}: </AttributeLabel>
                {`${attributes['LENS_COLOR']} / ${attributes['LENS_TREATMENT_FACET']}`}
              </div>
              <div>
                <AttributeLabel>{t('ProductAvailableNotificationDrawer.Labels.Size')}:</AttributeLabel>{' '}
                {!!product ? getSize(product, t) : null}
              </div>
            </AttributesContent>
          )}
          {subscriptionResult ? (
            <CloseSubscribeButton fullWidth onClick={toggleproductNotificationAvailableDrawer}>
              {t('ProductAvailableNotificationDrawer.CTA.SuccessButton')}
            </CloseSubscribeButton>
          ) : (
            <>
              <TextField
                label={t('ProductAvailableNotificationDrawer.EmailAddress')}
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e: any) => {
                  setEmail(e.target.value)
                  setEmailError(!/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(e.target.value))
                }}
                error={emailError}
                isvalid={!emailError && !isPristine}
                showvalidationstatus
                required
                helperText={
                  isPristine && emailError
                    ? t('ProductAvailableNotificationDrawer.Errors.EmptyEmail')
                    : !isPristine && emailError
                      ? t('ProductAvailableNotificationDrawer.Errors.InvalidEmail')
                      : ''
                }
              />
              {/* <EmailField
                label={t('productDetail.notificationAvailable.InputPlaceholder')}
                onChange={handleEmailChange}
                value={email}
                error={emailError}
                helperText={
                  emailError ? t('productDetail.notificationAvailable.InputError') : undefined
                }
              /> */}

              <CheckboxContainer>
                <StyledOOSFormControlLabel
                  control={<StyledCheckbox checked={isNewsletterChecked} onChange={handleNewsletterChecked} />}
                  label={t('ProductAvailableNotificationDrawer.CheckboxNewsletterText')}
                />

                {/* {t('productDetail.notificationAvailable.Checkbox')} */}
              </CheckboxContainer>

              <PrivacyPolicyContainer>
                <PrivacyLink href={privacyLink}>
                  <Trans i18nKey="ProductAvailableNotificationDrawer.PrivacyText">
                    {{
                      policyUrl: t('ProductAvailableNotificationDrawer.PrivacyTextLink'),
                    }}
                  </Trans>
                </PrivacyLink>
              </PrivacyPolicyContainer>

              <SubscribeButton onClick={handleSubmit} fullWidth>
                {t('ProductAvailableNotificationDrawer.CTA.Submit')}
              </SubscribeButton>
            </>
          )}
        </Content>
      </Container>
    </SwipeableDrawer>
  )
}

export default ProductAvailableNotificationDrawer
