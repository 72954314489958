import { getCTAToUrl, getCTAVariant } from '@utils/cta'
import { shallowEqual, useSelector } from 'react-redux'
import { LinkAsButton } from '@components/UI/Button/LinkAsButton'
import { ITeaserCallToAction } from '@typesApp/cmsPlacement/LXTeaser'
import React from 'react'
import styled from 'styled-components'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { themeModeSelector } from '@redux/selectors/theme'

const StyledCTA = styled(LinkAsButton)``

type TStyledCTAProps = React.ComponentProps<typeof StyledCTA> & {
  className?: string
}

const CallToAction: React.FC<ITeaserCallToAction & TStyledCTAProps> = props => {
  const { basePath } = useStoreIdentity()
  const globalThemeMode = useSelector(themeModeSelector, shallowEqual)
  const themeMode = props['themeMode'] ? props['themeMode'] : globalThemeMode

  return (
    <StyledCTA
      {...props}
      themeMode={themeMode}
      aria-label={props.callToActionText ?? 'Placement_Banner_CTA'}
      variant={getCTAVariant(props)}
      key={`arn-cms-content__cta-${props.target?.name}`}
      disabled={!props.callToActionEnabled}
      external={props.target.type === 'CMDownload'}
      href={getCTAToUrl(basePath, props)}
    >
      {props.callToActionText}
    </StyledCTA>
  )
}

export default CallToAction
