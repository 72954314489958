import React from 'react'
import styled from '@mui/material/styles/styled'
import { cssButton } from './index'
import { LinkProps } from '../../../types/link'
import { Link } from '@components/common/Link/Link'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { locationOriginSelector } from '@redux/selectors/site'

export interface LinkInlineProps extends LinkProps {
  children?: React.ReactNode
  dataElementId?: string
  external?: boolean
}

export const LinkInline = styled(Link)(cssButton)
export const ExternalLinkInline = styled('a', {
  name: 'link',
  slot: 'Container',
})(cssButton)
export const ContentRightArrow = styled('span', {
  name: 'ContentRightArrow',
  slot: 'Container',
})({
  marginLeft: 5,
  display: 'inline-flex',
})
export const ContentLeftArrow = styled('span', {
  name: 'ContentLefttArrow',
  slot: 'Container',
})({
  marginRight: 5,
  display: 'inline-flex',
})
export const ContentLink = styled('span', {
  name: 'ContentLink',
  slot: 'Container',
})({
  display: 'flex',
})

export const StyledLinkInline = React.forwardRef((props: LinkInlineProps, ref: any) => {
  const {
    href,
    children,
    dataElementId,
    external,
    labeltext,
    arrowPosition = 'none',
    parentbg,
    size,
    color,
    variant,
  } = props

  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)

  const renderChildren = () => labeltext && labeltext
  const content = !!children ? children : renderChildren()
  const renderContent = (content: any, arrowposition: LinkProps['arrowPosition']) => {
    return arrowposition === 'none' ? <span className="default-content">{content}</span> : <>{content}</>
  }

  const leftArrowExternal = (
    <>
      {arrowPosition && (arrowPosition === 'left' || arrowPosition === 'both') && (
        <ContentLeftArrow>
          {variant === 'separate' ? (
            <ExternalLinkInline
              ref={ref}
              href={href as string}
              target="_blank"
              data-element-id={dataElementId}
              arrow-position={arrowPosition}
              color={color}
            >
              <SVGIcon library="arrow" name="arrow-left" />
            </ExternalLinkInline>
          ) : (
            <SVGIcon library="arrow" name="arrow-left" />
          )}
        </ContentLeftArrow>
      )}
    </>
  )

  const rightArrowExternal = (
    <>
      {arrowPosition && (arrowPosition === 'right' || arrowPosition === 'both') && (
        <ContentRightArrow>
          {variant === 'separate' ? (
            <ExternalLinkInline
              ref={ref}
              href={href as string}
              target="_blank"
              data-element-id={dataElementId}
              arrow-position={arrowPosition}
              color={color}
            >
              <SVGIcon library="arrow" name="arrow-right" />
            </ExternalLinkInline>
          ) : (
            <SVGIcon library="arrow" name="arrow-right" />
          )}
        </ContentRightArrow>
      )}
    </>
  )

  const leftArrowLink = (
    <>
      {arrowPosition && (arrowPosition === 'left' || arrowPosition === 'both') && (
        <ContentLeftArrow>
          {variant === 'separate' ? (
            <ExternalLinkInline
              ref={ref}
              href={href as string}
              target="_blank"
              data-element-id={dataElementId}
              arrow-position={arrowPosition}
              color={color}
            >
              <SVGIcon library="arrow" name="arrow-left" />
            </ExternalLinkInline>
          ) : (
            <SVGIcon library="arrow" name="arrow-left" />
          )}
        </ContentLeftArrow>
      )}
    </>
  )

  const rightArrowLink = (
    <>
      {arrowPosition && (arrowPosition === 'right' || arrowPosition === 'both') && (
        <ContentRightArrow>
          {variant === 'separate' ? (
            <ExternalLinkInline
              ref={ref}
              href={href as string}
              target="_blank"
              data-element-id={dataElementId}
              arrow-position={arrowPosition}
              color={color}
            >
              <SVGIcon library="arrow" name="arrow-right" />
            </ExternalLinkInline>
          ) : (
            <SVGIcon library="arrow" name="arrow-right" />
          )}
        </ContentRightArrow>
      )}
    </>
  )

  return external ? (
    <ContentLink>
      {variant === 'separate' && leftArrowExternal}
      <ExternalLinkInline
        aria-label={labeltext}
        ref={ref}
        href={href as string}
        target="_blank"
        data-element-id={dataElementId}
        arrow-position={arrowPosition}
        color={color}
      >
        {variant !== 'separate' && leftArrowExternal}
        {renderContent(content, arrowPosition)}
        {variant !== 'separate' && rightArrowExternal}
      </ExternalLinkInline>
      {variant === 'separate' && rightArrowExternal}
    </ContentLink>
  ) : (
    <ContentLink>
      {variant === 'separate' && leftArrowLink}
      <LinkInline
        aria-label={labeltext}
        ref={ref}
        href={href as string}
        data-element-id={dataElementId}
        arrow-position={arrowPosition}
        color={color}
        locale={locale as string}
        isLocaleDomain={isLocaleDomain}
        locationOrigin={locationOrigin}
      >
        {variant !== 'separate' && leftArrowLink}
        {renderContent(content, arrowPosition)}
        {variant !== 'separate' && rightArrowLink}
      </LinkInline>
      {variant === 'separate' && rightArrowLink}
    </ContentLink>
  )
})
