import MuiRadioGroup from '@mui/material/RadioGroup'
import {
  formControlLabelClasses as MuiFormControlClasses,
  radioClasses as MuiRadioClasses,
  styled,
} from '@mui/material'

export const RadioGroup = styled(MuiRadioGroup, {
  name: 'Radio',
  slot: 'Root',
})(({ row, theme }) => ({
  [`& .${MuiFormControlClasses.root}`]: {
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    marginBottom: !row ? 24 : 0,
    padding: 0,
  },
  [`& .${MuiRadioClasses.root}`]: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: theme.spacing(1, 3, 0, 0),
    margin: 0,
  },
}))

export const RadioDescription = styled('div', {
  name: 'Radio',
  slot: 'Description',
})(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  marginLeft: theme.spacing(7),
}))
