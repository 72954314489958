import styled from '@mui/material/styles/styled'
import { ProductPriceDiscountBox } from './ProductPrice.style'

export const PriceSection = styled('div', {
  name: 'ProductPrice',
  slot: 'PriceSection',
})(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'end',
}))

export const InitialPriceSection = styled('div', {
  name: 'ProductPrice',
  slot: 'InitialPriceSection',
  shouldForwardProp: prop => prop !== 'isTotal',
})<{ isTotal?: boolean }>(({ isTotal }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  flexWrap: 'wrap',
  alignItems: isTotal ? 'baseline' : 'center',
  gap: '0.5rem',
}))

export const DiscountedPriceSection = styled('div', {
  name: 'ProductPrice',
  slot: 'DiscountedPriceSection',
})(({}) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'end',
  flexGrow: 1,
}))

export const ProductPriceSoldOutBadge = styled(ProductPriceDiscountBox, {
  name: 'ProductPrice',
  slot: 'SoldOutBadge',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  backgroundColor: theme.palette.custom.light.grey,
  textTransform: 'none',
}))

export const ProductPriceSoldOutContainer = styled('div', {
  name: 'ProductPrice',
  slot: 'SoldOutContainer',
})(({ theme }) => ({
  minHeight: theme.spacing(5),
  height: theme.spacing(5),
}))

export const TotalBoxes = styled('span', {
  name: 'ProductPrice',
  slot: 'BoxCount',
})(() => ({
  fontSize: 14,
  fontWeight: 'normal',
  textTransform: 'lowercase',
}))

export const TotalContainer = styled('div', {
  name: 'ProductPrice',
  slot: 'TotalContainer',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  svg: {
    marginBottom: '5px',
    marginLeft: '3px',
  },
}))
