import React from 'react'
import { useTranslation } from 'next-i18next'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { useMediaQuery } from '@mui/material'

import { moCoOrderedIdsFromPlp } from '@features/product/selector'
import useTheme from '@mui/material/styles/useTheme'
import { isEmpty } from '@utils/helpers'
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_MAP } from '@constants/product'
import { COLON_CHARACTER } from '@constants/ui'
import useBreakpoints from '@hooks/useBreakpoints'
import { IProduct, ProductRightColumnProps } from '@typesApp/product'
import { getModelName, getSoldOut } from '@utils/productAttributes'
import ProductColorItem from '../ProductColorItem'
import {
  ProductColorInfoText,
  ProductColorSelectorContainer,
  ProductColorSelectorProp,
  StyledColorsContainer,
  StyledSelectedColorLabel,
  SwiperWrapper,
  StyledColorThumbButton,
} from '@pages_views/ProductDetails/components/ProductColorSelector/ProductColorSelector.style'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import WithSpinningLoader from '@components/with-loading/WithLoading'

interface ProductColorSelectorProps {
  cluster: IProduct['cluster']
  currentProductItem?: IProduct | null
  isLoading?: boolean
  soldOutStatus: ProductRightColumnProps['soldOutStatus']
  selectedPartNumber: string
  onClick?: (p: IProduct) => void
}

const ProductColorSelector: FC<ProductColorSelectorProps> = ({
  cluster,
  currentProductItem,
  isLoading,
  onClick,
  selectedPartNumber,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isTablet, isTabletLandscape } = useBreakpoints()
  const moCoOrderedIds = useSelector(moCoOrderedIdsFromPlp)
  const isLgBreakpoint = useMediaQuery(theme.breakpoints.up('lg'))

  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null)
  const [activeSlider, setActiveSlider] = useState<number>(0)

  const frontColor = !!currentProductItem ? currentProductItem.productAttributes['FRONT_COLOR'] : null
  const productType = !!currentProductItem && currentProductItem.productAttributes['PRODUCT_TYPE']

  const lensColor = !!currentProductItem && currentProductItem.productAttributes['LENS_COLOR']

  const isOpticalPage = productType === 'Optical'
  const slidesPerGroup = isTablet ? 4 : 3

  let colorLabel = 'ProductColorSelector.Labels.Colour'
  if (
    productType &&
    ([PRODUCT_TYPES_MAP.frames, PRODUCT_TYPES_MAP.optical, PRODUCT_TYPES_MAP.sun] as string[]).indexOf(
      productType.toLowerCase()
    ) !== -1
  ) {
    colorLabel = 'ProductColorSelector.Labels.Frame'
  }
  const getClusterSorted = () => {
    if (!cluster) return []
    const newClusterSort = [...cluster].sort((a, b) => {
      const getFilteredPartNumbers = items => items?.filter(({ partNumber }) => partNumber !== selectedPartNumber)
      return getFilteredPartNumbers(a.items).length - getFilteredPartNumbers(b.items).length
    })
    return newClusterSort
  }

  const [newCluster, setNewCluster] = useState(getClusterSorted())
  useEffect(() => {
    // setting the selected items always on the first place
    if (!newCluster || (isEmpty(newCluster) && cluster)) {
      const newClusterSort = getClusterSorted()
      let newOrderedCluster: IProduct[] = Array(moCoOrderedIds.length)

      if (moCoOrderedIds.length > 0) {
        newClusterSort.forEach(p => {
          const position = moCoOrderedIds.indexOf(p.uniqueID as string)
          if (position !== -1) {
            newOrderedCluster[position] = p
          } else {
            newOrderedCluster.push(p)
          }
        })
      } else {
        newOrderedCluster = newClusterSort
      }
      setNewCluster(newOrderedCluster)
    }
  }, [cluster, newCluster])

  const controlPartNumber = p => {
    const productSelected = !!p.items
      ? p.items.find((item: IProduct) => item.partNumber === selectedPartNumber)
      : p.partNumber === selectedPartNumber
    if (productSelected) return productSelected.partNumber
  }

  if (!cluster) return null

  const sortedNewCluster: any = []

  newCluster
    ?.filter(p => getSoldOut(p.items?.[0] || p) !== PRODUCT_SOLDOUT_STATUS.SOLDOUT)
    .map(p => sortedNewCluster.push(p))

  newCluster
    ?.filter(p => getSoldOut(p.items?.[0] || p) === PRODUCT_SOLDOUT_STATUS.SOLDOUT)
    .map(p => sortedNewCluster.push(p))

  const isSunProduct = (product: IProduct | null | undefined) => {
    if (!product) return false
    const productType = product.productAttributes['PRODUCT_TYPE']
    return productType === 'Sun'
  }

  const isOpticalProduct = (product: IProduct | null | undefined) => {
    if (!product) return false
    const productType = product.productAttributes['PRODUCT_TYPE']
    return productType === 'Optical'
  }

  const isFramesProduct = (product: IProduct | null | undefined) => {
    if (!product) return false
    const productType = product.productAttributes['PRODUCT_TYPE']
    return productType === 'Frames'
  }

  const isLensRelatedProduct =
    isSunProduct(currentProductItem) || isOpticalProduct(currentProductItem) || isFramesProduct(currentProductItem)

  return (
    <ProductColorSelectorContainer>
      {cluster?.length >= 0 && (
        <>
          <div>
            <StyledSelectedColorLabel>
              {cluster?.length}{' '}
              {cluster?.length && cluster?.length > 1
                ? t('ProductColorSelector.Labels.ColoursAvailable')
                : t('ProductColorSelector.Labels.ColourAvailable')}
              {COLON_CHARACTER}
            </StyledSelectedColorLabel>
          </div>

          <StyledColorsContainer>
            <div ref={s => setPrevEl(s)}>
              {activeSlider !== 0 && (
                <SVGIcon library="arrow" name="arrow-left" color={theme.palette.text.dark.primary} />
              )}
            </div>
            <SwiperWrapper isTabletLandscape={isTabletLandscape}>
              <WithSpinningLoader isLoading={!!isLoading} skeletonCount={isLgBreakpoint ? 5 : 4}>
                <Swiper
                  navigation={{ prevEl, nextEl }}
                  modules={[Navigation]}
                  slidesPerView={3}
                  slidesPerGroup={3}
                  spaceBetween={0}
                  simulateTouch={false}
                  onSlideChange={swiper => setActiveSlider(swiper.activeIndex)}
                >
                  {newCluster?.map((p, index) => {
                    return (
                      <React.Fragment key={p.uniqueID}>
                        {p?.displayable ? (
                          <SwiperSlide key={p.uniqueID || index}>
                            <StyledColorThumbButton
                              data-element-id="X_X_Prod_ColorThumbs"
                              data-description={`${getModelName(p)}_${
                                controlPartNumber(p) || !!p.items
                                  ? p?.items?.find(item => !!item && item?.displayable)?.partNumber
                                  : p.partNumber
                              }`}
                              onClick={() => onClick?.(p)}
                              selected={controlPartNumber(p) === selectedPartNumber}
                            >
                              <ProductColorItem product={p?.items?.find(item => !!item && item?.displayable) || p} />
                            </StyledColorThumbButton>
                          </SwiperSlide>
                        ) : null}
                      </React.Fragment>
                    )
                  })}
                </Swiper>
              </WithSpinningLoader>
            </SwiperWrapper>
            <div ref={s => setNextEl(s)}>
              <SVGIcon library="arrow" name="arrow-right" color={theme.palette.text.dark.primary} />
            </div>
          </StyledColorsContainer>
        </>
      )}

      {cluster && cluster?.length >= 0 && !!frontColor && (
        <ProductColorInfoText>
          <ProductColorSelectorProp>
            {t(colorLabel)}
            {COLON_CHARACTER}
          </ProductColorSelectorProp>{' '}
          {frontColor}
        </ProductColorInfoText>
      )}

      {cluster && cluster?.length && isLensRelatedProduct && !isOpticalPage ? (
        <ProductColorInfoText isLensRelatedProduct={isLensRelatedProduct}>
          <ProductColorSelectorProp>
            {t('ProductColorSelector.Labels.Lenses')}
            {COLON_CHARACTER}
          </ProductColorSelectorProp>{' '}
          {lensColor}
        </ProductColorInfoText>
      ) : null}
    </ProductColorSelectorContainer>
  )
}

export default ProductColorSelector
