import styled from '@mui/material/styles/styled'
import { ProductImageZoomProps } from '@pages_views/ProductDetails/components/ProductImageZoom/ProductImageZoom'
import { IconButton } from '@components/UI/IconButton'

const ProductImageZoomWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'isVisible' && prop === 'children',
  name: 'ProductImageZoom',
  slot: 'Wrapper',
})<ProductImageZoomProps>(({ isVisible, theme }) => ({
  position: 'absolute',
  display: isVisible ? 'flex' : 'none',
  backgroundColor: theme.palette.custom.white,
  width: '100%',
  height: '100vh',
  zIndex: '3',
  left: '0',
  top: '0',
}))

const ProductImageZoomMagnifiedImageWrapper = styled('div', {
  name: 'ProductImageZoom',
  slot: 'MagnifiedImageWrapper',
})(({ theme }) => ({
  position: 'relative',
  width: `calc(100% - (${theme.spacing(20)} - 2px))`,
  img: {
    maxHeight: 'inherit',
  },
}))

const ProductImageZoomMagnifier = styled('div', {
  name: 'ProductImageZoom',
  slot: 'Magnifier',
  shouldForwardProp: prop => prop !== 'lensHeight' && prop !== 'lensWidth',
})<ProductImageZoomProps>(({ lensHeight, lensWidth, theme }) => ({
  width: `${lensWidth}px`,
  height: `${lensHeight}px`,
  boxShadow: '0 5px 10px -2px rgba(0, 0, 0, 0.3)',
  pointerEvents: 'none',
  position: 'absolute',
  border: `5px solid  ${theme.palette.custom.cyprus}`,
  zIndex: '6',
  borderRadius: '100%',
  display: 'block',
}))

const ProductImageZoomCloseIcon = styled(IconButton, {
  name: 'ProductImageZoom',
  slot: 'CloseIcon',
})(({ theme }) => ({
  borderRadius: '0',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.custom.white,
  position: 'absolute',
  zIndex: '10',
  top: '0',
  right: '0',
  svg: {
    width: theme.spacing(6),
  },
  '&:hover': {
    backgroundColor: theme.palette.custom.white,
  },
}))

export {
  ProductImageZoomWrapper,
  ProductImageZoomMagnifier,
  ProductImageZoomCloseIcon,
  ProductImageZoomMagnifiedImageWrapper,
}
