import React from 'react'
import { useTranslation } from 'next-i18next'

import Breadcrumbs from '@components/UI/Breadcrumbs/Breadcrumbs'
import { BreadCrumbs as BreadcrumbsProps } from '@components/PagesSeo/product/Product'
import { BreadcrumbsWrapper } from '@components/Breadcrumbs/Breadcrumbs.style'
import { IProduct } from '@typesApp/product'
import { replaceLocaleInUrl } from '@utils/locale'
import { BreadcrumbsSeoHeader } from '@components/Breadcrumbs/BreadcrumbsSeoHeader'

interface BreadcrumbProps {
  cid: string
  className?: string
  searchTermText: string
  product?: IProduct
  breadcrumbsList?: any[]
  langCode: string
  locale: string
  isLocaleDomain: boolean
  locationOrigin: string
}

/**
 * Breadcrumb component
 * displays breadcrumb trail
 * @param props
 */
const BreadcrumbsLayout: React.FC<BreadcrumbProps> = ({
  cid,
  className,
  searchTermText,
  breadcrumbsList,
  isLocaleDomain,
  locale,
  locationOrigin,
}) => {
  const { t } = useTranslation()

  const breadcrumbs: BreadcrumbsProps[] = cid.includes('search-results')
    ? [{ label: searchTermText.replace('*', '') }]
    : breadcrumbsList || []

  const items = [
    {
      href: '/',
      label: t('CommerceEnvironment.breadcrumbs.Home'),
    },
    ...breadcrumbs.map((breadcrumb, index) => {
      const isLast = index === breadcrumbs.length - 1
      const href =
        isLast || !breadcrumb.seo?.href
          ? undefined
          : replaceLocaleInUrl({
              isLocaleDomain,
              locationOrigin,
              locale: locale.replace('_', '-').toLowerCase(),
              href: breadcrumb.seo.href,
            })
      return {
        href,
        label: breadcrumb.label,
      }
    }),
  ]

  return (
    <>
      <BreadcrumbsSeoHeader breadcrumbList={items} homepage={locationOrigin} />
      <BreadcrumbsWrapper className={className || ''}>
        <Breadcrumbs items={items} />
      </BreadcrumbsWrapper>
    </>
  )
}

export default BreadcrumbsLayout
