import { css, styled, Theme } from '@mui/material/styles'
import Link, { LinkProps } from 'next/link'
import { CSSInterpolation } from '@emotion/serialize'
import { CSSProperties } from 'react'

export interface AnchorProps extends LinkProps {
  children?: React.ReactNode
  external?: boolean
  variant?: keyof typeof anchorVariants
  href: LinkProps['href']
  as?: any
  className?: string
  style?: CSSProperties
  target?: string
  rel?: string
  callback?: () => void
}

const cssAnchor = ({ theme, variant = 'primary' }: Pick<AnchorProps, 'variant'> & { theme: Theme }) =>
  css(anchorVariants[variant](theme))

type StyledLinkVariant = (theme: Theme) => CSSInterpolation

/**
 * @deprecated !!!!!!!!!! DO NOT USE IN COMPONENTS OUTSIDE OF MEDIAOVERLAPV2 !!!!!!!!!!
 *
 * this component is a temporary solution to import issues and mismatches between CLE and VD repositories
 *
 * Anchor component.
 * @param {string} to  param to set the redirect
 * @param {boolean} external param to change Link(router-dom) to Html link
 */
export const StyledAnchor = styled(
  ({ external, href, callback, children, onClick, ...props }: AnchorProps) => {
    return external ? (
      <a
        href={`${href}`}
        {...props}
        onClick={e => {
          e.preventDefault()
          onClick && onClick(e)
          callback && callback()
        }}
      >
        {children}
      </a>
    ) : (
      <Link
        href={href ?? '/'}
        {...props}
        onClick={e => {
          onClick && onClick(e)
          callback && callback()
        }}
      >
        {children}
      </Link>
    )
  },
  { shouldForwardProp: prop => prop !== 'variant' }
)(cssAnchor)

const primary: StyledLinkVariant = theme => ({
  color: theme.palette.custom.blue,
  position: 'relative',
  textDecoration: 'none',
  '&::after': {
    position: 'absolute',
    content: '""',
    width: '100%',
    height: 1,
    bottom: 1,
    left: 0,
    background: theme.palette.custom.blue,
    transition: '200ms',
    visibility: 'visible',
  },
  '&:visited::after': {
    position: 'absolute',
    content: '""',
    width: '100%',
    height: 1,
    bottom: 1,
    left: 0,
    background: theme.palette.custom.purple,
    transition: '200ms',
    visibility: 'visible',
  },
  '&:hover::after': {
    width: 0,
    visibility: 'hidden',
  },
  '&:hover': {
    color: theme.palette.text.dark.primary,
    cursor: 'pointer',
  },
  '&:visited': {
    color: theme.palette.custom.purple,
  },
  '&:visited:hover': {
    color: theme.palette.text.dark.primary,
  },
  '&:active': {
    color: theme.palette.text.dark.primary,
  },
})

const black: StyledLinkVariant = theme => ({
  ...(primary(theme) as object),
  color: theme.palette.text.dark.primary,
  '&::after': {
    ...(primary(theme) as object)['&::after'],
    background: theme.palette.text.dark.primary,
  },
  '&:visited::after': {
    ...(primary(theme) as object)['&:visited::after'],
    background: theme.palette.text.dark.primary,
  },
  '&:hover': {
    ...(primary(theme) as object)['&:hover'],
    color: theme.palette.text.dark.primary,
  },
  '&:visited': {
    color: theme.palette.text.dark.primary,
  },
  '&:visited:hover': {
    color: theme.palette.text.dark.primary,
  },
  '&:active': {
    color: theme.palette.text.dark.primary,
  },
})

const wrapper: StyledLinkVariant = () => `
  font-size: 0;
}`

const noAnimation: StyledLinkVariant = theme => ({
  position: 'relative',
  fontSize: '14px',
  color: theme.palette.custom.cyprus,
  textDecoration: 'none',

  '&::after': {
    visibility: 'hidden',
  },
  '&:hover::after': {
    visibility: 'hidden',
  },
})

const anchorVariants = {
  primary,
  black,
  wrapper,
  noAnimation,
}
