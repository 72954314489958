import { GridItem } from '@components/UI/Grid'
import BrandIconLink from '../ListOfBrandIcons/components/BrandIconLink'
import { IPlacement, isCMCollection, isCMExternalChannel, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { Pagination } from 'swiper/modules'
import React from 'react'
import {
  StyledGridContainer,
  StyledSwiper,
  StyledTabContainer,
  TitleWallOfBrands,
  WallOfBrandsCtaContainer,
  WallOfBrandsTabItem,
  WrapperWallOfBrands,
} from './WallOfBrands.style'
import { StyledTabsWrapper } from '@components/UI/Tabs/StyledTabsWrapper'
import { StyledTabsWithStyle } from '@components/UI/Tabs/StyledTabs.style'
import { StyledTab } from '@components/UI/Tabs/StyledTab'
import { StyledTabPanel } from '@components/UI/Tabs/StyledTabPanel'
import { SwiperSlide } from 'swiper/react'
import { v4 as uuid } from 'uuid'
import { useMediaQuery, useTheme } from '@mui/material'

import { uniqueId } from '@utils/common'
import { CmsCta } from '../CmsCta'
import useBreakpoints from '@hooks/useBreakpoints'

const WallOfBrands: React.FC<{ placement: IPlacement | ICMCollection; index?: number }> = ({
  placement,
  index: placementIndex,
}) => {
  const items =
    'items' in placement
      ? placement.items.filter(isCMExternalChannel) || []
      : placement.teasableItems.filter(isCMExternalChannel) || []

  const { isMobile, isTabletWidthBelowOrEqualTo768 } = useBreakpoints()
  const collections =
    'items' in placement ? placement.items.filter(isCMCollection) : placement.teasableItems.filter(isCMCollection)
  const teasers = 'items' in placement ? placement.items.filter(isLXTeaser) : placement.teasableItems.filter(isLXTeaser)
  const [value, setValue] = React.useState(0)
  const groupings = isMobile ? 4 : isTabletWidthBelowOrEqualTo768 ? 8 : 12

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }
  const theme = useTheme()
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'))

  const createGroups = (arr, numGroups) => {
    const perGroup = numGroups
    const arraySize = Math.ceil(arr.length / numGroups)
    return new Array(arraySize).fill([]).map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup))
  }

  function a11yProps(index) {
    return {
      id: `WallOfBrands-tab-${uniqueId(index + '-')}`,
      'aria-controls': `WallOfBrands-tabpanel-${uniqueId(index + '-')}`,
      'data-testid': `wallOfBrandsTab${index}`,
    }
  }

  const tabElements = collections.map((collection, i) => {
    return {
      title: collection.collectionTitle,
      tabContent: (
        <>
          <WallOfBrandsTabItem key={uuid()}>
            <StyledSwiper slidesPerView={1} slidesPerGroup={1} modules={[Pagination]} pagination={{ clickable: true }}>
              {createGroups(collection.teasableItems, groupings).map((teasableGroup, groupIndex) => (
                <SwiperSlide key={`WallOfBrands_${uuid()}`}>
                  <StyledGridContainer gap={16} data-testid={`wallOfBrandsContainer${groupIndex}`}>
                    {teasableGroup?.map((item, j) => {
                      const newItem = { ...item, placementCounter: { placementIndex, tabIndex: i, tileIndex: j } }
                      return (
                        <GridItem key={j} md={2} sm={isTabletWidthBelowOrEqualTo768 ? 3 : 2} xs={2}>
                          <React.Fragment key={item.id}>
                            <BrandIconLink teasableItem={newItem} />
                          </React.Fragment>
                        </GridItem>
                      )
                    })}
                  </StyledGridContainer>
                </SwiperSlide>
              ))}
            </StyledSwiper>
          </WallOfBrandsTabItem>
          <WallOfBrandsCtaContainer data-element-id={`${placementIndex}Placement_Tab${i}_CTA`}>
            {collection?.teaserLXCallToActionSettings?.map((actionSettings, index) => (
              <CmsCta
                dataElementId={`${placementIndex}Placement_Tab${i}_CTA`}
                key={`cms-content__text-module--cta-${uuid()}`}
                teaserCtaSetting={actionSettings}
              />
            ))}
          </WallOfBrandsCtaContainer>
        </>
      ),
    }
  })

  return (
    <WrapperWallOfBrands>
      {tabElements.length > 1 && (
        <StyledTabsWrapper parentbg="light">
          <StyledGridContainer>
            {
              <GridItem
                xs={12}
                sm={tabElements.length < 2 ? 12 : 3}
                md={tabElements.length < 2 ? 12 : 3}
                lg={tabElements.length < 2 ? 12 : 3}
              >
                <TitleWallOfBrands isSingle={tabElements.length === 1}>{teasers[0]?.teaserTitle1}</TitleWallOfBrands>
              </GridItem>
            }
            {tabElements.length > 1 && (
              <GridItem xs={12} sm={6} md={6} lg={6}>
                <StyledTabContainer>
                  <StyledTabsWithStyle
                    parentbg="light"
                    value={value}
                    onChange={handleChange}
                    aria-label="tabs example"
                    scrollButtons={false}
                    centered={!isMobileOrTablet}
                    variant={'fullWidth'}
                  >
                    {tabElements?.map((v: any, index: number) => {
                      const { title, renderIcon, iconPosition } = v
                      return (
                        <StyledTab
                          key={index}
                          label={title}
                          parentbg="light"
                          name="WallOfBrands"
                          icon={renderIcon && renderIcon()}
                          iconPosition={iconPosition && iconPosition}
                          {...a11yProps(index)}
                        />
                      )
                    })}
                  </StyledTabsWithStyle>
                </StyledTabContainer>
              </GridItem>
            )}
          </StyledGridContainer>
          {tabElements?.map((v: any, index: number) => (
            <StyledTabPanel key={index} value={value} index={index} name="WallOfBrands">
              {v.tabContent}
            </StyledTabPanel>
          ))}
        </StyledTabsWrapper>
      )}
      {(isTabletWidthBelowOrEqualTo768 || isMobile) && (
        <WallOfBrandsTabItem>
          <StyledSwiper slidesPerView={1} slidesPerGroup={1} modules={[Pagination]} pagination={{ clickable: true }}>
            {createGroups(items, groupings).map((itemGroup, i) => (
              <SwiperSlide key={i}>
                <StyledGridContainer gap={16}>
                  {itemGroup.map((item, tileIndex) => {
                    const newItem = { ...item, placementCounter: { placementIndex, tabIndex: i, tileIndex } }
                    return (
                      <React.Fragment key={item.id}>
                        <GridItem md={2} sm={isTabletWidthBelowOrEqualTo768 ? 3 : 2} xs={2}>
                          <BrandIconLink teasableItem={newItem} />
                        </GridItem>
                      </React.Fragment>
                    )
                  })}
                </StyledGridContainer>
              </SwiperSlide>
            ))}
          </StyledSwiper>
        </WallOfBrandsTabItem>
      )}
      {!isTabletWidthBelowOrEqualTo768 && !isMobile && tabElements.length < 2 && (
        <StyledGridContainer>
          {items.map((item, tileIndex) => {
            const newItem = { ...item, placementCounter: { placementIndex, tileIndex } }
            return (
              <React.Fragment key={item.id}>
                <GridItem md={2} sm={isTabletWidthBelowOrEqualTo768 ? 3 : 2} xs={2}>
                  <BrandIconLink teasableItem={newItem} />
                </GridItem>
              </React.Fragment>
            )
          })}
        </StyledGridContainer>
      )}
    </WrapperWallOfBrands>
  )
}

export default WallOfBrands
