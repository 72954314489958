import { IPlacement, IPlacementItem, TeaserOverlayStyle, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import {
  ILXTeaser,
  ITeaserOverlaySettings,
  ITeaserOverlayStyle,
  ITeaserOverlayTextAlign,
} from '@typesApp/cmsPlacement/LXTeaser'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import React from 'react'
import { Theme } from '@mui/material'
import config from '@configs/index'
import { isCMExternalProduct, isCMProductTeaser } from '@typesApp/teaser'
import { IXStoreConfig } from '@redux/reducers'
import { ICMExternalProduct } from '@typesApp/cmsPlacement/CMExternalProduct'
import { ICMProductTeaser } from '@typesApp/cmsPlacement/CMProductTeaser'

type IPosition =
  | 'left-top'
  | 'left-middle'
  | 'left-bottom'
  | 'right-top'
  | 'right-middle'
  | 'right-bottom'
  | 'center-top'
  | 'center-middle'
  | 'center-bottom'

const normalizeOverlaySettings = (
  value: IPosition,
  halfWidth?: string,
  fullWidth?: string,
  margin?: string,
  viewtype?: IViewType
) => {
  const defaultStyle = {
    width: `${fullWidth}`,
    margin: `${margin}`,
    padding: 'unset',
  }
  const leftMiddleSquatStyle = {
    width: '50%',
    margin: '0 32px',
    padding: 'unset',
  }
  const getMiddleLandscapeStyle = (side: string) => {
    return {
      width: fullWidth,
      margin: side === 'right' ? `0 ${margin} 0 0` : `0 0 0 ${margin}`,
      right: side === 'right' ? 0 : '',
      height: 'inherit',
    }
  }
  switch (value) {
    case 'left-top':
      return { ...defaultStyle, left: '0', top: '0', bottom: 'unset' }
    case 'left-middle':
      switch (viewtype) {
        case 'squat-banner':
          return { ...leftMiddleSquatStyle, left: '0', top: '0', bottom: '0' }
        case 'landscape-banner':
          return { ...getMiddleLandscapeStyle('left') }
        default:
          return { ...defaultStyle, left: '0', top: '0', bottom: '0' }
      }
    case 'left-bottom':
      return { ...defaultStyle, left: '0', bottom: '0' }
    case 'center-top':
      return {
        ...defaultStyle,
        left: `calc(50% - ${halfWidth} - ${margin})`,
        top: '0',
        bottom: 'unset',
      }
    case 'center-middle':
      return {
        ...defaultStyle,
        left: '0',
        top: 'unset',
        bottom: 'unset',
        margin: `${margin}`,
      }
    case 'center-bottom':
      return {
        ...defaultStyle,
        left: `calc(50% - ${halfWidth} - ${margin})`,
        bottom: '0',
      }
    case 'right-top':
      return { ...defaultStyle, right: '0', top: '0', bottom: 'unset' }
    case 'right-middle':
      switch (viewtype) {
        case 'landscape-banner':
          return { ...getMiddleLandscapeStyle('right') }
        default:
          return { ...defaultStyle, right: '0', top: 'unset', bottom: 'unset' }
      }
    case 'right-bottom':
      return { ...defaultStyle, right: '0', bottom: '0' }
  }
}

const normalizeOverlaySettingsMobile = (value: IPosition, halfWidth?: string, fullWidth?: string, margin?: string) => {
  const defaultStyle = {
    width: `${fullWidth}`,
    margin: `${margin}`,
    padding: 'unset',
  }
  switch (value) {
    case 'left-top':
      return { ...defaultStyle, left: '0', top: '0', bottom: 'unset' }
    case 'left-middle':
      return { ...defaultStyle, left: '0', top: '0', bottom: '0' }
    case 'left-bottom':
      return { ...defaultStyle, left: '0', bottom: '0' }
    case 'center-top':
      return {
        ...defaultStyle,
        left: `calc(50% - ${halfWidth} - ${margin})`,
        top: '0',
        bottom: 'unset',
      }
    case 'center-middle':
      return {
        ...defaultStyle,
        left: `calc(50% - ${halfWidth} - ${margin})`,
        top: 'unset',
        bottom: 'unset',
        margin: `${margin}`,
      }
    case 'center-bottom':
      return {
        ...defaultStyle,
        left: `calc(50% - ${halfWidth} - ${margin})`,
        bottom: '0',
      }
    case 'right-top':
      return { ...defaultStyle, right: '0', top: '0', bottom: 'unset' }
    case 'right-middle':
      return { ...defaultStyle, right: '0', top: 'unset', bottom: 'unset' }
    case 'right-bottom':
      return { ...defaultStyle, right: '0', bottom: '0' }
  }
}

export const teaserPropsByView = (
  type: IViewType
): {
  teaserTitle: 'teaserTitle1' | 'teaserTitle2' | 'teaserTitle3' | 'teaserTitle4'
  teaserText: 'teaserText1' | 'teaserText2'
  teaserOverlaySettings: 'teaserOverlay1Settings' | 'teaserOverlay2Settings'
  teaserOverlayTextAlign: 'teaserOverlay1TextAlign' | 'teaserOverlay2TextAlign'
  teaserOverlayStyle: 'teaserOverlay1Style' | 'teaserOverlay2Style'
} => {
  switch (type) {
    case 'text-module':
    case 'square-boards-with-split':
    case 'square-boards-without-split':
      return {
        teaserTitle: 'teaserTitle4',
        teaserText: 'teaserText2',
        teaserOverlaySettings: 'teaserOverlay2Settings',
        teaserOverlayTextAlign: 'teaserOverlay2TextAlign',
        teaserOverlayStyle: 'teaserOverlay2Style',
      }
    case 'full-width-banner':
    case 'landscape-banner':
    case 'squat-banner':
    case 'top-page-banner':
    case 'box-with-margin':
    case 'grid-of-boards-two-columns':
    case 'grid-of-boards-three-columns':
      return {
        teaserTitle: 'teaserTitle1',
        teaserText: 'teaserText1',
        teaserOverlaySettings: 'teaserOverlay1Settings',
        teaserOverlayTextAlign: 'teaserOverlay1TextAlign',
        teaserOverlayStyle: 'teaserOverlay1Style',
      }
    case 'boards-with-fields-below':
      return {
        teaserTitle: 'teaserTitle3',
        teaserText: 'teaserText2',
        teaserOverlaySettings: 'teaserOverlay2Settings',
        teaserOverlayTextAlign: 'teaserOverlay2TextAlign',
        teaserOverlayStyle: 'teaserOverlay2Style',
      }
    case 'combo-mini-slider-plus-box-all-fields':
      return {
        teaserTitle: 'teaserTitle1',
        teaserText: 'teaserText2',
        teaserOverlaySettings: 'teaserOverlay2Settings',
        teaserOverlayTextAlign: 'teaserOverlay2TextAlign',
        teaserOverlayStyle: 'teaserOverlay2Style',
      }
    case 'plp-one-tile':
    case 'plp-two-tiles':
    case 'plp-three-tiles':
      return {
        teaserTitle: 'teaserTitle3',
        teaserText: 'teaserText1',
        teaserOverlaySettings: 'teaserOverlay1Settings',
        teaserOverlayTextAlign: 'teaserOverlay1TextAlign',
        teaserOverlayStyle: 'teaserOverlay1Style',
      }
    default:
      return {
        teaserTitle: 'teaserTitle1',
        teaserText: 'teaserText1',
        teaserOverlaySettings: 'teaserOverlay1Settings',
        teaserOverlayTextAlign: 'teaserOverlay1TextAlign',
        teaserOverlayStyle: 'teaserOverlay1Style',
      }
  }
}

export const isBanner = (viewtype?: IViewType) => {
  return (
    viewtype === 'full-width-banner' ||
    viewtype === 'landscape-banner' ||
    viewtype === 'top-page-banner' ||
    viewtype === 'squat-banner'
  )
}

/**
 * this utility handle the vertical positioning for text module inside broad banners
 */
export const overLaySettingsToVerticalCSS = (
  value: ILXTeaser['teaserOverlay1Settings'] | ''
): {
  justifyContent: string
} => {
  const splitValue = value.replace('block-', '') as IPosition
  switch (splitValue) {
    case 'left-top':
    case 'center-top':
    case 'right-top':
      return {
        justifyContent: 'flex-start',
      }
    case 'left-middle':
    case 'center-middle':
    case 'right-middle':
      return { justifyContent: 'center' }
    case 'left-bottom':
    case 'center-bottom':
    case 'right-bottom':
      return {
        justifyContent: 'flex-end',
      }
    default:
      return { justifyContent: 'center' }
  }
}

export const overLayTextAlignToCSS = (
  value?: ITeaserOverlayTextAlign,
  haveSideProducts?: boolean
): {
  justifyContent: string
  textAlign: ITeaserOverlayTextAlign
  alignItems: React.CSSProperties['alignItems']
} => {
  if (haveSideProducts) {
    return {
      justifyContent: 'flex-start',
      textAlign: 'left',
      alignItems: 'flex-start',
    }
  } else {
    switch (value) {
      case 'left':
        return {
          justifyContent: 'flex-start',
          textAlign: 'left',
          alignItems: 'flex-start',
        }
      case 'center':
        return {
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
        }
      case 'right':
        return {
          justifyContent: 'flex-end',
          textAlign: 'right',
          alignItems: 'flex-end',
        }
      default:
        return {
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
        }
    }
  }
}

export const teaserOverlaySettingsToCSS = (
  value: ILXTeaser['teaserOverlay1Settings'] | '',
  istextoverlay?: 1 | 0,
  halfWidth?: string,
  fullWidth?: string,
  margin?: string,
  viewtype?: IViewType
) => {
  const isBannerType = isBanner(viewtype)
  if (istextoverlay === 0) {
    return {
      left: 'unset',
      top: 'unset',
    }
  } else {
    if (value.length === 0 || value === '') {
      return {
        left: `calc(50% - ${isBannerType ? '18%' : halfWidth} - ${margin})`,
        top: 'unset',
        width: `${isBannerType ? '36%' : fullWidth}`,
        margin: `${isBannerType ? '64px' : margin}`,
      }
    }
  }

  const split = value.replace('block-', '') as IPosition

  return normalizeOverlaySettings(
    split,
    isBannerType ? '18%' : halfWidth,
    isBannerType ? '36%' : fullWidth,
    isBannerType ? '64px' : margin,
    viewtype
  )
}

export const teaserOverlaySettingsToCSSMobile = (
  value: ILXTeaser['teaserOverlay1Settings'] | '',
  istextoverlay?: 1 | 0,
  halfWidth?: string,
  fullWidth?: string,
  margin?: string
) => {
  if (istextoverlay === 0) {
    return {
      left: 'unset',
      top: 'unset',
    }
  } else {
    if (value.length === 0 || value === '') {
      return {
        left: `calc(50% - ${halfWidth} - ${margin})`,
        top: 'unset',
        width: `${fullWidth}`,
        margin: `${margin}`,
      }
    }
  }

  const split = value.replace('block-', '') as IPosition

  return normalizeOverlaySettingsMobile(split, halfWidth, fullWidth, margin)
}

export const teaserAlignToFlex = (value: React.CSSProperties['textAlign']) => {
  switch (value) {
    case 'left':
      return 'flex-start'
    case 'right':
      return 'flex-end'
    case 'center':
      return 'center'
    default:
      return 'flex-start'
  }
}

export const teaserBackGroundColorToBg = (
  value: ILXTeaser['teaserBackground'] | React.CSSProperties['backgroundColor'],
  theme: Theme
) => {
  switch (value) {
    case 'bg-dark-primary':
      return { backgroundColor: theme.palette.background.dark.primary }
    case 'bg-dark-secondary':
      return { backgroundColor: theme.palette.background.dark.secondary }
    case 'bg-light-primary':
      return { backgroundColor: theme.palette.background.light.primary }
    case 'bg-light-secondary':
      return { backgroundColor: theme.palette.custom.light.grey }
    case 'bg-light-tertiary':
      return { backgroundColor: theme.palette.background.light.tertiary }
    case 'bg-light-quaternary':
      return { backgroundColor: theme.palette.background.light.quaternary }
    case 'bg-black':
      return { backgroundColor: theme.palette.custom.black, color: theme.palette.text.light.primary }
    case 'bg-dark-one':
      return { backgroundColor: theme.palette.custom.grey, color: theme.palette.text.light.primary }
    case 'bg-light-blue':
      return { backgroundColor: theme.palette.custom.light.blue }
    case 'bg-light-green':
      return { backgroundColor: theme.palette.custom.light.green }
    case 'bg-light-purple':
      return { backgroundColor: theme.palette.custom.light.purple }
    case 'bg-light-yellow':
      return { backgroundColor: theme.palette.custom.light.yellow }
    case '':
      return { backgroundColor: 'transparent' }
    default:
      return { backgroundColor: value }
  }
}

export const teaserPaddingByBackGroundColor = (
  value: ILXTeaser['teaserBackground'] | React.CSSProperties['backgroundColor'],
  theme: Theme,
  paddingValue: number
) => {
  if (value?.startsWith('bg-')) {
    return {
      paddingTop: theme.spacing(paddingValue),
      paddingBottom: theme.spacing(paddingValue),
    }
  }
  return { padding: 0 }
}

export const CmsModuleVerticalMargin = (marginVertical: string, theme: Theme) => {
  switch (marginVertical) {
    case 'X':
      return { marginTop: theme.spacing(0), marginBottom: theme.spacing(0) }
    case 'S':
      return { marginTop: theme.spacing(8), marginBottom: theme.spacing(8) }
    case 'M':
      return { marginTop: theme.spacing(10), marginBottom: theme.spacing(10) }
    case 'L':
      return { marginTop: theme.spacing(0), marginBottom: theme.spacing(0) }
    default:
      return { marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }
  }
}

export const CmsModuleVerticalMarginMobile = (marginVertical: string, theme: Theme) => {
  switch (marginVertical) {
    case 'M':
      return { marginBlock: theme.spacing(8) }
    default:
      return null
  }
}

export const teaserOverlayStyleToColor = (theme: Theme, value?: TeaserOverlayStyle) => {
  switch (value) {
    case 'text-dark-primary':
      return { color: theme.palette.text.dark.primary }
    case 'text-dark-secondary':
      return { color: theme.palette.text.dark.secondary }
    case 'text-light-primary':
      return { color: theme.palette.text.light.primary }
    case 'text-light-secondary':
      return { color: theme.palette.text.light.secondary }
    default:
      return null
  }
}

export const bannerOverlayStyleToColor = (theme: Theme, viewtype?: IViewType, value?: TeaserOverlayStyle) => {
  switch (viewtype) {
    case 'full-width-banner':
      return { color: theme.palette.text.light.primary }
    case 'landscape-banner':
    case 'top-page-banner':
      return { color: theme.palette.text.dark.primary }
    default:
      return { ...teaserOverlayStyleToColor(theme, value) }
  }
}

export const setMargin = (theme: Theme, teasertype: string, viewtype?: IViewType, external?: boolean) => {
  switch (teasertype) {
    case 'pretitle':
      switch (viewtype) {
        case 'full-width-banner':
        case 'landscape-banner':
          return { marginBottom: theme.spacing(2) }
        case 'top-page-banner':
        case 'squat-banner':
          return { marginBottom: theme.spacing(4) }
        default:
          return { marginBottom: theme.spacing(6) }
      }
    case 'longtext':
      switch (viewtype) {
        case 'full-width-banner':
        case 'landscape-banner':
          return { marginTop: external ? theme.spacing(4) : theme.spacing(2) }
        case 'top-page-banner':
        case 'squat-banner':
        case 'square-boards-with-split':
        case 'square-boards-without-split':
          return { marginTop: external ? theme.spacing(4) : theme.spacing(4) }
        default:
          return { marginTop: external ? theme.spacing(4) : theme.spacing(6) }
      }
    default:
      return null
  }
}

export const setMarginMobile = (theme: Theme, teasertype: string, viewtype?: IViewType, external?: boolean) => {
  switch (teasertype) {
    case 'pretitle':
      switch (viewtype) {
        case 'full-width-banner':
        case 'landscape-banner':
        case 'top-page-banner':
        case 'squat-banner':
          return { marginBottom: theme.spacing(2) }
        default:
          return { marginBottom: theme.spacing(4) }
      }
    case 'longtext':
      switch (viewtype) {
        case 'full-width-banner':
        case 'landscape-banner':
        case 'top-page-banner':
        case 'squat-banner':
        case 'square-boards-with-split':
        case 'square-boards-without-split':
          return { marginTop: external ? theme.spacing(2) : theme.spacing(2) }
        default:
          return { marginTop: external ? theme.spacing(2) : theme.spacing(4) }
      }
    default:
      return null
  }
}

export const setTitleWOBFontSize = (theme: Theme) => {
  return {
    fontSize: theme.spacing(4.5),
    [theme.breakpoints.down(703)]: {
      fontSize: theme.spacing(4),
    },
    [theme.breakpoints.down(636)]: {
      fontSize: theme.spacing(3.5),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(5),
    },
  }
}

export const getTeaserPropsByView = (
  type: IViewType
): {
  teaserTitle: 'teaserTitle1' | 'teaserTitle2' | 'teaserTitle3' | 'teaserTitle4'
  teaserText: 'teaserText1' | 'teaserText2'
  teaserOverlaySettings: 'teaserOverlay1Settings' | 'teaserOverlay2Settings'
  teaserOverlayTextAlign: 'teaserOverlay1TextAlign' | 'teaserOverlay2TextAlign'
  teaserOverlayStyle: 'teaserOverlay1Style' | 'teaserOverlay2Style'
} => {
  switch (type) {
    case 'text-module':
      return {
        teaserTitle: 'teaserTitle4',
        teaserText: 'teaserText2',
        teaserOverlaySettings: 'teaserOverlay2Settings',
        teaserOverlayTextAlign: 'teaserOverlay2TextAlign',
        teaserOverlayStyle: 'teaserOverlay2Style',
      }
    case 'full-width-banner':
    case 'fullwidth-promo-banner':
    case 'landscape-banner':
    case 'squat-banner':
    case 'top-page-banner':
    case 'two-item-board-carousel':
      return {
        teaserTitle: 'teaserTitle1',
        teaserText: 'teaserText1',
        teaserOverlaySettings: 'teaserOverlay1Settings',
        teaserOverlayTextAlign: 'teaserOverlay1TextAlign',
        teaserOverlayStyle: 'teaserOverlay1Style',
      }
    case 'boards-with-fields-below':
      return {
        teaserTitle: 'teaserTitle3',
        teaserText: 'teaserText2',
        teaserOverlaySettings: 'teaserOverlay2Settings',
        teaserOverlayTextAlign: 'teaserOverlay2TextAlign',
        teaserOverlayStyle: 'teaserOverlay2Style',
      }
    default:
      return {
        teaserTitle: 'teaserTitle1',
        teaserText: 'teaserText1',
        teaserOverlaySettings: 'teaserOverlay1Settings',
        teaserOverlayTextAlign: 'teaserOverlay1TextAlign',
        teaserOverlayStyle: 'teaserOverlay1Style',
      }
  }
}

export const replaceTextMediaCmsUrl = (text: string) => {
  if (text) {
    const mediaUrlValue = '{mediaCmsUrl}'
    const re = new RegExp(mediaUrlValue, 'g')

    if (text.indexOf(mediaUrlValue) >= 0) {
      return text.replace(re, config.cmsImageServerUrl)
    }
  }
  return text
}

export const replaceTextFromXStore = (text: string, shortCodes: IXStoreConfig['shortCodes']) => {
  let textValue = text
  const regex = /\[([^\]]+)\]/g
  const matches = textValue.match(regex)

  if (matches?.length) {
    matches.forEach(match => {
      const exactMatch = match.slice(1, -1)
      const replaceValue = shortCodes[exactMatch]
      if (replaceValue) {
        textValue = textValue.replace(match, replaceValue)
      }
    })
  }
  return textValue
}

export const placementHasTextShadow = ({
  placement,
  teaser,
}: {
  placement?: IPlacement | ICMCollection
  teaser?: ILXTeaser
}): boolean => {
  try {
    const moduleTeaser = !!teaser
      ? teaser
      : 'items' in placement!
        ? placement?.items?.find(isLXTeaser)
        : placement?.teasableItems?.find(isLXTeaser)
    const { teaserOverlaySettings, teaserOverlayStyle } =
      teaserPropsByView(moduleTeaser?.viewtype || 'default') || teaserPropsByView(placement?.viewtype || 'default')

    const settings: ITeaserOverlaySettings | undefined = moduleTeaser?.[teaserOverlaySettings]
    const color: ITeaserOverlayStyle | undefined = moduleTeaser?.[teaserOverlayStyle]

    return (!!color && !!settings && color?.includes('-shadow')) || false
  } catch (e) {
    return false
  }
}

export const getBackgroundBasedOnPlacement = (type: string, defaultBackground: string): string => {
  switch (type) {
    case 'dcw-products':
    case 'grid-of-products':
      return '#F6F6F6'
    default:
      return defaultBackground
  }
}

export const getTeaserOverlayTextAlign = (teaserOverlay1TextAlign?: string): ITeaserOverlayTextAlign => {
  if (!teaserOverlay1TextAlign) {
    return 'center'
  }

  return teaserOverlay1TextAlign === 'justified' ? 'justify' : (teaserOverlay1TextAlign as ITeaserOverlayTextAlign)
}

export const getRatioBanner = (type: IViewType, theme: Theme, countItems?: number) => {
  switch (type) {
    case 'full-width-banner':
      return {
        aspectRatio: '75/104',
        [theme.breakpoints.up('sm')]: {
          aspectRatio: '96/65',
        },
        [theme.breakpoints.up('md')]: {
          aspectRatio: '64/29',
        },
      }
    case 'landscape-banner':
      return {
        aspectRatio: '375/284',
        [theme.breakpoints.up('sm')]: {
          aspectRatio: '192/71',
        },
        [theme.breakpoints.up('md')]: {
          aspectRatio: '32/13',
        },
        [theme.breakpoints.up(1280)]: {
          aspectRatio: '40/13',
        },
        [theme.breakpoints.up('lg')]: {
          aspectRatio: '40/13',
        },
      }
    case 'squat-banner':
      return {
        aspectRatio: '125/126',
        [theme.breakpoints.up('sm')]: {
          aspectRatio: '16/5',
        },
        [theme.breakpoints.up('md')]: {
          aspectRatio: '64/15',
        },
        [theme.breakpoints.up(1280)]: {
          aspectRatio: '16/3',
        },
        [theme.breakpoints.up('lg')]: {
          aspectRatio: '6/1',
        },
      }
    case 'top-page-banner':
      return {
        aspectRatio: '374/284',
        [theme.breakpoints.up('sm')]: {
          aspectRatio: '192/71',
        },
        [theme.breakpoints.up('md')]: {
          aspectRatio: '64/20',
        },
        [theme.breakpoints.up(1280)]: {
          aspectRatio: '320/71',
        },
        [theme.breakpoints.up('lg')]: {
          aspectRatio: '9/2',
        },
      }
    case 'box-with-margin':
      return {
        aspectRatio: '1/1',
        [theme.breakpoints.up('sm')]: {
          aspectRatio: '176/163',
        },
        [theme.breakpoints.up('md')]: {
          aspectRatio: '1/1',
        },
        [theme.breakpoints.up(1280)]: {
          aspectRatio: '1/1',
        },
        [theme.breakpoints.up('lg')]: {
          aspectRatio: '1/1',
        },
      }
    case 'combo-mini-slider-plus-box-all-fields':
      return {
        aspectRatio: '327/362',
        [theme.breakpoints.up('sm')]: {
          aspectRatio: '128/127',
        },
        [theme.breakpoints.up('md')]: {
          aspectRatio: '56/61',
        },
        [theme.breakpoints.up(1280)]: {
          aspectRatio: '1/1',
        },
        [theme.breakpoints.up('lg')]: {
          aspectRatio: '43/44',
        },
      }
    case 'square-boards-without-split':
    case 'square-boards-with-split':
      return {
        aspectRatio: '1/1',
      }
    case 'boards-with-fields-below':
      return {
        aspectRatio: countItems === 2 ? '343/362' : '327/362',
        [theme.breakpoints.up('sm')]: {
          aspectRatio: '352/181',
        },
        [theme.breakpoints.up('md')]: {
          aspectRatio: countItems === 2 ? '238/181' : countItems === 3 ? '315/362' : '204/181',
        },
        [theme.breakpoints.up(1280)]: {
          aspectRatio: countItems === 2 ? '286/181' : countItems === 3 ? '379/362' : '200/181',
        },
        [theme.breakpoints.up('lg')]: {
          aspectRatio: countItems === 2 ? '326/181' : countItems === 3 ? '216/181' : '200/181',
        },
      }
    default:
      return null
  }
}

// We can control what we have to load from cms by providing query param includePlacements or excludePlacements
// Base on it placements contain loaded flag if flag false, placements.items = []
// Here we can merge not fullyLoaded and fullyLoaded placements
export const mergePlacements = (notFullPlacements?: IPlacement[], fullPlacements?: IPlacement[]) => {
  const fullItems = notFullPlacements?.map(pl => {
    if (pl.loaded) return pl
    const match = fullPlacements?.find(fullPlacement => fullPlacement.name === pl.name && fullPlacement.loaded)
    if (!match) return pl
    return { ...pl, items: match.items, loaded: true }
  })
  return fullItems
}

export const multipleTeaserPropsByView = (
  type: IViewType
): {
  teaserTitle2: 'teaserTitle2'
  teaserTitle3: 'teaserTitle3'
  teaserTitle4: 'teaserTitle4'
  teaserText2: 'teaserText2'
} => {
  switch (type) {
    case 'fullwidth-promo-banner':
    default:
      return {
        teaserTitle2: 'teaserTitle2',
        teaserTitle3: 'teaserTitle3',
        teaserTitle4: 'teaserTitle4',
        teaserText2: 'teaserText2',
      }
  }
}

export const teaserOverlayBackgroundGradient = ({
  color,
  settings,
}: {
  color?: ITeaserOverlayStyle
  settings?: ITeaserOverlaySettings
}) => {
  if (!color || !settings || !color?.includes('shadow')) return { background: 'transparent' }

  const backgroundColor = color.includes('light') ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255,255,255, 0.5)'

  switch (settings) {
    case 'block-left-top':
    case 'block-left-middle':
      return {
        background: `linear-gradient(90deg, ${backgroundColor} 25%, rgba(255,255,255,0) 50%);`,
      }
    case 'block-left-bottom':
    case 'block-right-bottom':
    case 'block-center-bottom':
      return {
        background: `linear-gradient(0deg, ${backgroundColor} 25%, rgba(255,255,255,0) 50%);`,
      }
    case 'block-right-top':
    case 'block-right-middle':
      return {
        background: `linear-gradient(270deg, ${backgroundColor} 25%, rgba(255,255,255,0) 50%);`,
      }
    case 'block-center-middle':
      return {
        background: `linear-gradient(0deg, ${backgroundColor} 25%, rgba(255,255,255,0) 50%);`,
      }
    default:
      return {
        background: 'transparent',
      }
  }
}

export const cmsModuleLateralMargin = (marginLateral: boolean, theme: Theme) => {
  return {
    marginLeft: theme.spacing(marginLateral ? 4 : 0),
    marginRight: theme.spacing(marginLateral ? 4 : 0),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(marginLateral ? 8 : 0),
      marginRight: theme.spacing(marginLateral ? 8 : 0),
    },
    [theme.breakpoints.up(1280)]: {
      marginLeft: theme.spacing(marginLateral ? 8 : 0),
      marginRight: theme.spacing(marginLateral ? 8 : 0),
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(marginLateral ? 16 : 0),
      marginRight: theme.spacing(marginLateral ? 16 : 0),
    },
  }
}

export const cmsModuleVerticalMargin = (marginVertical: string, theme: Theme) => {
  switch (marginVertical) {
    case 'X':
      return { marginBottom: theme.spacing(0) }
    case 'S':
      return { marginBottom: theme.spacing(6) }
    case 'M':
      return { marginBottom: theme.spacing(10) }
    case 'L':
      return { marginBottom: theme.spacing(16) }
    default:
      return { marginBottom: theme.spacing(4) }
  }
}

export const getItemsWithProductData = (placement: IPlacement<IPlacementItem>) => {
  return (
    placement?.items
      .filter(item => isCMProductTeaser(item) || isCMExternalProduct(item))
      .map(product => (product as ICMProductTeaser | ICMExternalProduct).productData)
      .filter(Boolean) ?? []
  )
}
