import React from 'react'
import { clsx } from 'clsx'
import { v4 as uuid } from 'uuid'
import { useTheme } from '@mui/material/styles'
import { CmsCta } from '@components/Cms/CMSContent/components/CmsCta'
import GridItem from '@components/UI-CSS/Grid/GridItem'
import GridContainer from '@components/UI-CSS/Grid/GridContainer'
import MediaQuery from '@components/UI-CSS/MediaQuery'
import useBreakpoints from '@hooks/useBreakpoints'
import { cmsApiService } from '@foundation/apis/cms/cms.ssr.service'
import { trimText } from '@utils/common'
import { teaserOverlayStyleToColor, teaserPropsByView } from '@utils/placements'
import { ModulesProps } from '@typesApp/cms'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import styles from './styles/BannerTextModule.module.scss'
import {
  StyledBannerPreTitle,
  StyledBannerTextContainer,
  StyledBannerTitle,
  StyledCTAContainer,
  StyledTextModuleContainer,
  TwoColumnCTAContainer,
} from './TextModule.style'
import { CmsCountdown } from '@components/Cms/CMSContent/components/CmsCountdown'

export interface ITextModuleProps extends ModulesProps {
  showCta?: boolean
  placement?: IPlacement | ICMCollection
  teaser?: ILXTeaser
}

const isTextModuleOverlay = (viewtype: IViewType): boolean => {
  switch (viewtype) {
    case 'two-column-banner-lg':
    case 'full-width-banner-with-shadow':
      return true
    case 'two-item-board-carousel':
    default:
      return false
  }
}

const BannerTextModule: React.FC<ITextModuleProps> = ({
  viewType,
  teaserIndex,
  showCta = true,
  placement,
  teaser,
  index: placementIndex,
}) => {
  const theme = useTheme()
  const { isMobile, isTabletWidthBelowOrEqualTo768 } = useBreakpoints()
  const areItemsInPlacement = placement && 'items' in placement
  const moduleTeaser = !!teaser ? teaser : areItemsInPlacement ? placement?.items[0] : placement?.teasableItems[0]
  if (moduleTeaser?.type !== 'LXTeaser') {
    return null
  }
  const placementHasBackGroundColor =
    !!(placement as IPlacement)?.backgroundColor && (placement as IPlacement)?.backgroundColor !== ''
  const media = moduleTeaser?.media?.[0] ?? undefined

  const isTextOverlay = !!media && isTextModuleOverlay(viewType || placement?.viewtype || 'default') ? 1 : 0

  const { teaserTitle, teaserText, teaserOverlaySettings, teaserOverlayTextAlign, teaserOverlayStyle } =
    teaserPropsByView(viewType || placement?.viewtype || 'default')

  const { teaserLXCallToActionSettings, teaserPreTitle, teaserBackground } = moduleTeaser

  const hasCtas = teaserLXCallToActionSettings.length > 0
  const teaserTitleValue = moduleTeaser[teaserTitle]
  const teaserTextValue = moduleTeaser[teaserText]
  const teaserOverlaySettingsValue = moduleTeaser[teaserOverlaySettings]
  const teaserOverlayTextAlignValue = moduleTeaser[teaserOverlayTextAlign]
  const teaserOverlayStyleValue = moduleTeaser[teaserOverlayStyle]

  const referenceTextStyle = !!moduleTeaser.teaserText2
    ? moduleTeaser.teaserOverlay2Style
    : moduleTeaser.teaserOverlay1Style

  const useTextColorReplacement =
    'text-light-primary' === referenceTextStyle && (isMobile || isTabletWidthBelowOrEqualTo768)
  const textColor = useTextColorReplacement
    ? theme.palette.text.primary
    : teaserOverlayStyleToColor(theme, referenceTextStyle)?.color || theme.palette.text.primary

  const content = (
    <>
      <GridContainer container noVertPadding>
        <GridItem sm={12}>
          <GridContainer>
            <GridItem sm={12} xxl={8}>
              {teaserPreTitle && (
                <StyledBannerPreTitle
                  textColor={textColor}
                  textAlign={cmsApiService.getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)}
                >
                  {teaserPreTitle}
                </StyledBannerPreTitle>
              )}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem sm={12} xl={8}>
              {teaserTitleValue && (
                <StyledBannerTitle
                  textColor={textColor}
                  textAlign={cmsApiService.getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)}
                >
                  {teaserTitleValue}
                </StyledBannerTitle>
              )}
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem sm={12} xxl={8}>
              <CmsCountdown teaser={moduleTeaser} />
              {teaserTextValue && (
                <StyledBannerTextContainer
                  data-cm-metadata={`[{"_":"properties.${teaserText}"}]`}
                  dangerouslySetInnerHTML={{ __html: teaserTextValue }}
                  textAlign={cmsApiService.getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)}
                />
              )}
            </GridItem>
          </GridContainer>

          <MediaQuery size="desktop">
            <GridContainer className={styles.gridContainerNoGap}>
              <GridItem sm={8} xxl={4}>
                {teaserLXCallToActionSettings.length > 0 &&
                  showCta &&
                  teaserLXCallToActionSettings.map((teaser, index) => (
                    <StyledCTAContainer
                      overlayTextAlign={cmsApiService.getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)}
                      key={`teaser-${index}`}
                      data-element-id={`${placementIndex}Placement_Banner${teaserIndex}_CTA${
                        teaserLXCallToActionSettings.length === 1 ? '' : index
                      }`}
                    >
                      <CmsCta
                        dataElementId={`${placementIndex}Placement_Banner${teaserIndex}_CTA${
                          teaserLXCallToActionSettings.length === 1 ? '' : index
                        }`}
                        dataDescription={trimText(teaser.callToActionText ?? '')}
                        key={`${teaser.callToActionText}-${uuid()}`}
                        teaserCtaSetting={teaser}
                      />
                    </StyledCTAContainer>
                  ))}
              </GridItem>
            </GridContainer>
          </MediaQuery>
        </GridItem>
      </GridContainer>
    </>
  )

  return (
    <div
      className={clsx({
        [styles.textModuleWrapper]: !isTextOverlay,
        [styles.textModuleWrapperWithOverlay]: !!isTextOverlay,
        [styles[teaserOverlaySettingsValue]]: !!teaserOverlaySettingsValue,
        [styles[teaserOverlayStyleValue]]: !!teaserOverlayStyleValue,
      })}
    >
      <StyledTextModuleContainer>
        {content}

        {hasCtas && (
          <MediaQuery size="mobile" className={styles.ctaContainer}>
            <TwoColumnCTAContainer
              overlayTextAlign={cmsApiService.getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)}
              data-element-id={`${placementIndex}Placement_Banner${teaserIndex}_CTA`}
            >
              {showCta &&
                teaserLXCallToActionSettings.map((teaser, index) => (
                  <CmsCta
                    dataElementId={`${placementIndex}Placement_Banner${teaserIndex}_CTA${index}`}
                    key={`${teaser.callToActionText}-${uuid()}`}
                    teaserCtaSetting={teaser}
                  />
                ))}
            </TwoColumnCTAContainer>
          </MediaQuery>
        )}
      </StyledTextModuleContainer>
    </div>
  )
}

export default BannerTextModule
