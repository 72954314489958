import LoadingSkeleton from '@components/Cms/CmsComponents/CmsCommonMedia/LoadingSkeleton'
import Sidebar from '@layouts/Sidebar'
import Container from '@components/UI/Container'
import { IPlacement, IPlacementItem } from '@typesApp/cmsPlacement/Placement'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'
import styles from './styles/index.module.scss'
import { useBreakpoint } from '@hooks/useBreakpoint'

type TLayoutWithSidebarProps = {
  topPlacements?: React.ReactNode
  loading?: boolean
  sidebarNav: IPlacement<IPlacementItem> | undefined
}

const LayoutWithSidebar: React.FC<PropsWithChildren<TLayoutWithSidebarProps>> = ({
  topPlacements = null,
  children,
  loading,
  sidebarNav,
}) => {
  const { isMobile } = useBreakpoint()
  return (
    <>
      {topPlacements}
      {loading ? (
        <LoadingSkeleton height={'100vh'} />
      ) : (
        <Container>
          <div
            className={!!sidebarNav ? clsx(styles['layout-with-sidebar'], 'layout-with-sidebar', 'has-sidebar') : ''}
          >
            {sidebarNav && !isMobile && (
              <aside className={clsx(styles['sidebar'])}>
                <Sidebar sidebarNav={sidebarNav} />
              </aside>
            )}
            <div>{children}</div>
          </div>
        </Container>
      )}
    </>
  )
}

export default LayoutWithSidebar
