import React, { ReactElement, useMemo } from 'react'
import config from '@configs/index'
import {
  QuantitySelectWrapper,
  StyledContactLensesDataColumn,
  StyledContactLensesDataWrapper,
} from './ContactLensSelection.style'
import { Typography } from '@mui/material'
import { getNormalizedProductType } from '@utils/productAttributes'
import { isMtoProduct } from '@utils/product'
import { useSite } from '@foundation/hooks/useSite'
import storeUtil from '@utils/storeUtil'
import { PRODUCT_SOLDOUT_STATUS } from '@constants/product'
import { SubscriptionSelectionOption } from '@components/UI/ProductSubscriptionModule/pdp/SubscriptionPDP'
import { EyeClFieldConfigMap, EyeContactLensAttribute } from '@typesApp/prescription'
import { IProduct, ProductSoldOutStatus } from '@typesApp/product'
import { Adjustment } from '@typesApp/order'
import { useTranslation } from 'next-i18next'

export type RenderFieldsProps = {
  currentProduct: IProduct
  soldOutStatus: ProductSoldOutStatus
  setAdjustments?: (adjustments: Adjustment[]) => void
  isEditingContactLens: boolean | undefined
  renderField: (RenderFieldsProps) => ReactElement
  fieldsConfig: EyeClFieldConfigMap | null
  updateFieldValue: (eye: string, id: EyeContactLensAttribute, val?: string | null) => void
}

export const CLQuantitySelect: React.FC<RenderFieldsProps> = ({
  currentProduct,
  renderField,
  fieldsConfig,
  updateFieldValue,
  soldOutStatus,
  isEditingContactLens,
  setAdjustments,
}) => {
  const { t } = useTranslation()
  const { mySite } = useSite()
  const productType = getNormalizedProductType(currentProduct)
  const { enabled, allowedProducts, showOnPdp } = config.productSubscription

  const isMTO = storeUtil.isMTOEnabled(mySite) && isMtoProduct(currentProduct)
  const showSubscriptionSection =
    !isMTO &&
    enabled &&
    showOnPdp &&
    allowedProducts?.includes(productType) &&
    soldOutStatus !== PRODUCT_SOLDOUT_STATUS.SOLDOUT &&
    soldOutStatus !== PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK

  const renderFields = useMemo(() => {
    return (
      <>
        <StyledContactLensesDataColumn>
          <Typography id={t('ContactLenses.Labels.Boxes')}>{t('ContactLenses.Labels.Boxes')}</Typography>
        </StyledContactLensesDataColumn>
        <StyledContactLensesDataColumn>
          {renderField({
            key: 'left-quantity',
            label: 'quantity',
            eye: 'left',
            onClSelectChanged: updateFieldValue,
            isMultifield: false,
            options:
              (fieldsConfig?.['left']?.find(fieldConf => fieldConf.id === 'quantity')?.options ||
                fieldsConfig?.['left']?.find(fieldConf => fieldConf.id === 'quantity')?.defaultValue) ??
              [],
            isSelect: false,
          })}
        </StyledContactLensesDataColumn>

        <StyledContactLensesDataColumn>
          {renderField({
            key: 'right-quantity',
            label: 'quantity',
            eye: 'right',
            onClSelectChanged: updateFieldValue,
            isMultifield: false,
            options:
              (fieldsConfig?.['right']?.find(fieldConf => fieldConf.id === 'quantity')?.options ||
                fieldsConfig?.['right']?.find(fieldConf => fieldConf.id === 'quantity')?.defaultValue) ??
              [],
            isSelect: false,
          })}
        </StyledContactLensesDataColumn>
      </>
    )
  }, [currentProduct, updateFieldValue])

  return (
    <>
      {(showSubscriptionSection && (
        <SubscriptionSelectionOption
          currentProduct={currentProduct}
          isEditingContactLens={isEditingContactLens}
          setAdjustment={setAdjustments}
        >
          <QuantitySelectWrapper>{renderFields}</QuantitySelectWrapper>
        </SubscriptionSelectionOption>
      )) || <StyledContactLensesDataWrapper>{renderFields}</StyledContactLensesDataWrapper>}
    </>
  )
}
