import React from 'react'
import { useTranslation } from 'next-i18next'
import { SwiperProps } from 'swiper/react'

// COMPONENTS
import { FrequentlyBoughtTogether } from './FrequentlyBoughtTogether'
import { YouMayAlsoLike } from './YouMayAlsoLike'

import { initIndexName, recommendClient } from '@foundation/algolia/algoliaConfig'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
// TYPES
import { IProduct } from '@typesApp/product'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
// UTILS
import { localeLangCountryUtil } from '@utils/countryUtil'
import { ALGOLIA_RECOMMENDATION_MODELS } from './constants'

export interface AlgoliaRecommendationsProps {
  item: ICMCollection
  currentProduct?: IProduct
  isGrouped: boolean
  sliderProps?: SwiperProps
}

const { FREQUENTLY_BOUGHT_TOGETHER, RELATED_PRODUCTS } = ALGOLIA_RECOMMENDATION_MODELS
const MAX_RECOMMENDED_ITEMS = 6
export const ALGOLIA_SUGGESTED_PRODUCTS_PARTIAL_ID = 'youMayAlsoLikeCarousel-'

const AlgoliaRecommendations: React.FC<AlgoliaRecommendationsProps> = ({ item, currentProduct, sliderProps }) => {
  const { langCode } = useStoreIdentity()
  const langCountry = localeLangCountryUtil(langCode)

  const { t: translate } = useTranslation()
  const carouselTitle = item.title ?? translate('ProductGrid.Recommendations.youMayAlsoLike')

  const indexName = initIndexName({ locale: langCountry, isRecommendations: true })
  const recommendationModel = item.idAction?.toLocaleLowerCase()
  const algoliaConfig = {
    indexName,
    maxRecommendations: MAX_RECOMMENDED_ITEMS,
    recommendClient,
  }

  const carouselProps = {
    id: `${ALGOLIA_SUGGESTED_PRODUCTS_PARTIAL_ID}${currentProduct?.partNumber}`,
    description: item.description ?? '',
    sliderProps,
    title: carouselTitle,
  }

  if (recommendationModel === FREQUENTLY_BOUGHT_TOGETHER) {
    return <FrequentlyBoughtTogether algoliaConfig={algoliaConfig} {...carouselProps} />
  } else if (currentProduct) {
    return <YouMayAlsoLike algoliaConfig={algoliaConfig} currentProduct={currentProduct} {...carouselProps} />
  } else {
    return null
  }
}

export default AlgoliaRecommendations
