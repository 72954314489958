import Log from '@services/Log'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'

import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { IDynamicContentKey, IDynamicRule, LXDynamicContent } from '@typesApp/cmsPlacement/LXDynamicContent'
import { IPlacement, IPlacementItem, isCMCollection, isDynamicContent } from '@typesApp/cmsPlacement/Placement'
import { IProduct } from '@typesApp/product'
import { usePageType } from './usePageType'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { IPlpProductResponse } from '@features/plp/query'

type DynamicContentKeyMapping = Record<IDynamicContentKey, string[]> | {}

/**
 * get dynamic cms content
 * @param dynamicContentKeyMapping dynamic content attributes map
 * @param dynamicRules dynamic rules from cms to be matched with attributes
 * @param dynamicItem dynamic cms entity
 */
const getDynamicContentByRules = (
  dynamicContentKeyMapping: DynamicContentKeyMapping,
  dynamicRules: IDynamicRule[],
  dynamicItem: LXDynamicContent
): ILXTeaser | ICMCollection | null => {
  let matchedRule: IDynamicRule | null = null
  try {
    matchedRule = dynamicRules?.filter(dynamicRule => {
      const match = dynamicRule?.rules.find(rule => {
        const attributesValues: string[] = dynamicContentKeyMapping[rule?.key]
        return !!rule.value && attributesValues?.includes(rule?.value)
      })
      return !!match
    })[0]
    Log.info('DYNAMIC CONTENT RULE MATCH', JSON.stringify(matchedRule))
  } catch (e: any) {
    Log.error('DYNAMIC CONTENT MATCH ERROR', e)
  }
  if (matchedRule !== null && !!matchedRule?.target) {
    return matchedRule.target
  } else {
    Log.info('DYNAMIC CONTENT DEFAULT MATCH', JSON.stringify(matchedRule))
    return dynamicItem.dynamicDefault
  }
}

const processPlacementItem = (item: IPlacementItem, dynamicContentMap: DynamicContentKeyMapping): IPlacementItem => {
  if (item && isDynamicContent(item)) {
    const dynamicContentByRules = getDynamicContentByRules(dynamicContentMap, item.dynamicRules || [], item)

    return dynamicContentByRules!
  } else {
    return item
  }
}

export const getUpdatedCmsContent = (
  cmsBannerData: IPlacement[],
  dynamicContentMap: DynamicContentKeyMapping
): IPlacement[] => {
  try {
    return (cmsBannerData || [])?.map(placement => ({
      ...placement,
      items: placement.items.map(placementItem => {
        if (isCMCollection(placementItem)) {
          return {
            ...placementItem,
            teasableItems: placementItem.teasableItems.map(item => processPlacementItem(item, dynamicContentMap)),
          }
        }

        const formattedPlacementItem = processPlacementItem(placementItem, dynamicContentMap)

        return formattedPlacementItem
      }),
    }))
  } catch (e: any) {
    Log.error('DYNAMIC CONTENT FETCH ERROR', e)

    return []
  }
}

export const useDynamicCmsContentPlpAlgolia = (
  cmsBannerData: IPlacement[] | undefined,
  selectedFacets: any[]
): IPlacement<IPlacementItem>[] => {
  if (cmsBannerData && cmsBannerData.length > 0) {
    const plpDynamicContentMap = selectedFacets.reduce((acc, curr) => {
      const { attribute, value } = curr
      if (!/(sort\.price|sort\.discount)/.test(attribute)) {
        const attributeName = attribute.split('.')[1]
        const parsedValue = value?.toString()?.includes('|') ? value?.split('|')[1] : value
        const attributeValueIdentifier = `${attributeName}_${parsedValue}`
        if (acc[attributeName]) {
          acc[attributeName] = [...acc[attributeName], attributeValueIdentifier]
        } else {
          acc[attributeName] = [attributeValueIdentifier]
        }
      }
      return acc
    }, {})
    const dynamicContentBanners =
      cmsBannerData && plpDynamicContentMap && getUpdatedCmsContent(cmsBannerData, plpDynamicContentMap)
    return dynamicContentBanners
  }

  return [] as IPlacement<IPlacementItem>[]
}

export const useDynamicCmsContent = (
  cmsBannerData: IPlacement[] | undefined,
  pdpData?: IProduct | null
): {
  dynamicContentBanners: IPlacement<IPlacementItem>[]
} => {
  const pageType = usePageType()
  const customerSegments = useCustomerSegmentsUtil()

  if (cmsBannerData?.length === 0) {
    return { dynamicContentBanners: [] }
  }

  if (cmsBannerData && cmsBannerData.length > 0) {
    switch (pageType.pageType) {
      case 'pdp':
        const pdpDynamicContentMap = pdpData && pdpData !== null ? pdpData.productAttributes : {}

        const dynamicContentBanners =
          cmsBannerData && pdpDynamicContentMap && getUpdatedCmsContent(cmsBannerData, pdpDynamicContentMap)

        return { dynamicContentBanners }
    }
  }

  let pageDynamicContentMap = {} as DynamicContentKeyMapping
  customerSegments.map(segment => (pageDynamicContentMap[segment] = segment))
  const dynamicBanners = getUpdatedCmsContent(cmsBannerData ?? [], pageDynamicContentMap)

  return { dynamicContentBanners: dynamicBanners }
}
