import styled from '@mui/material/styles/styled'
import { StyledTabsProps } from '../../../types/tabs'
import { GridContainer } from '../Grid'

export const StyledTabsWrapper = styled('div', {
  name: 'TabsWrapper',
  slot: 'Wrapper',
  shouldForwardProp: prop => prop !== 'parentbg',
})<Pick<StyledTabsProps, 'parentbg'>>(({ theme, parentbg }) => ({
  backgroundColor: parentbg === 'light' ? theme.palette.custom.white : theme.palette.custom.cyprus,

  '&.MuiButtonBase-root': {
    minWidth: 'auto',
    [theme.breakpoints.up('xs')]: {
      height: theme.spacing(4),
    },

    [theme.breakpoints.up('md')]: {
      height: theme.spacing(5),
    },
  },

  '& .MuiTabs-flexContainer': {
    justifyContent: 'center !important',
    [theme.breakpoints.up('xs')]: {
      height: theme.spacing(4),
    },

    [theme.breakpoints.up('md')]: {
      height: theme.spacing(5),
    },
  },
}))

export const StyledGridContainer = styled(GridContainer)(({ theme }) => ({
  padding: `${theme.spacing(16)} ${theme.spacing(16)} 0`,
}))
