import LinkList from '@components/UI/LinkList'
import LinkListItem from '@components/UI/LinkListItem'
import { ICMChannel } from '@typesApp/cmsPlacement/CMChannel'
import { ICMExternalChannel } from '@typesApp/cmsPlacement/CMExternalChannel'
import { ICMExternalPage } from '@typesApp/cmsPlacement/CMExternalPage'
import { IPlacement, IPlacementItem, isCMCollection } from '@typesApp/cmsPlacement/Placement'
import {
  StyledButton,
  StyledButtonWrapper,
  StyledHeading,
  StyledScreenReaderOnly,
  StyledSidebar,
} from './Sidebar.style'
import { formatPathWithBase } from '@utils/url'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { Typography } from '@mui/material'
import { useRouter } from 'next/router'
import { locationOriginSelector } from '@redux/selectors/site'
import { useSelector } from 'react-redux'
import { usePathname } from 'next/navigation'

const listType = ['CMChannel', 'CMExternalPage', 'CMExternalChannel']

const Sidebar: React.FC<{ sidebarNav: IPlacement<IPlacementItem> | undefined }> = ({ sidebarNav }) => {
  const { basePath } = useStoreIdentity()
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)
  const sideBarNavItemPath = usePathname().substring(1) || ''

  if (!sidebarNav) {
    return null
  }

  const filterType = (teasableItems: IPlacementItem[]) =>
    (teasableItems.filter(teasableItem => listType.includes(teasableItem.type)) as (
      | ICMChannel
      | ICMExternalChannel
      | ICMExternalPage
    )[]) || []

  const sidebarNavItems = sidebarNav?.items.filter(isCMCollection)
  const sidebar = (
    <>
      {sidebarNavItems?.map(item => {
        const { id } = item
        const teaserLXCallToActionSettings = item['teaserLXCallToActionSettings'] || []
        const viewAllLinkText = teaserLXCallToActionSettings[0]?.['callToActionText']
        const viewAllLink = teaserLXCallToActionSettings[0]?.['target']?.['formattedUrl']
        const formattedItems = filterType(item.teasableItems).map(teasableItem => ({
          ...teasableItem,
          formattedUrl: formatPathWithBase(teasableItem.formattedUrl, basePath),
        }))

        return (
          <LinkList key={id}>
            <StyledHeading variant="subtitle1">{item['collectionTitle']}</StyledHeading>
            <ul>
              {formattedItems.map((teasableItem, index) => {
                const sideBarMatchFromUrl = teasableItem.formattedUrl.split('/').slice(2).join('/')
                return (
                  <li key={index}>
                    <LinkListItem
                      href={teasableItem.formattedUrl}
                      locale={locale as string}
                      isLocaleDomain={isLocaleDomain}
                      locationOrigin={locationOrigin}
                    >
                      <Typography
                        variant="body1"
                        fontWeight={
                          sideBarNavItemPath && sideBarNavItemPath.trim() === sideBarMatchFromUrl.trim()
                            ? 'bold'
                            : 'light'
                        }
                      >
                        {teasableItem.title}
                      </Typography>
                    </LinkListItem>
                  </li>
                )
              })}
              {viewAllLink && (
                <StyledButtonWrapper key={viewAllLinkText}>
                  <StyledButton href={viewAllLink} variant="primary" fillType="outline">
                    {viewAllLinkText}
                    <StyledScreenReaderOnly>{`${viewAllLinkText} ${item['collectionTitle']}`}</StyledScreenReaderOnly>
                  </StyledButton>
                </StyledButtonWrapper>
              )}
            </ul>
          </LinkList>
        )
      })}
    </>
  )

  return <StyledSidebar data-element-id="LeftNav">{sidebar}</StyledSidebar>
}

export default Sidebar
