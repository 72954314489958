import React from 'react'
import { StyledPill } from './Pill.style'

export interface PillProps {
  key?: string
  id?: string
  className?: string
  label?: string
  disabled?: boolean
  onClick?: () => void
  onDelete?: () => void
  isActive?: boolean
  icon?: React.ReactElement
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quinary'
}

export const Pill = (props: PillProps) => {
  const { key, id, className, label, disabled, onClick, onDelete, isActive, icon, variant } = props

  return (
    <StyledPill
      key={key ?? label}
      id={id ?? label}
      className={className}
      label={label}
      onClick={onClick ? onClick : undefined}
      onDelete={onDelete ? onDelete : undefined}
      isActive={isActive}
      disabled={disabled}
      deleteIcon={onDelete ? icon : undefined}
      icon={onDelete ? undefined : icon}
    />
  )
}
