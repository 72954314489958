import React from 'react'
import { useSelector } from 'react-redux'
import { useRelatedProducts } from '@algolia/recommend-react'
import { CmsCarousel, CmsCarouselProps } from '@components/Cms/CMSContent/components/CmsCarousel/CmsCarousel'
import { PRODUCT_TYPES_MAP } from '@constants/product'
import { catentriesSelector, orderItemsSelector } from '@features/order/selector'
import { IAlgoliaHit } from '@typesApp/product'
import { isAccessories, isCLAccessories, isContactLensesProduct } from '@utils/product'
import { getProductType } from '@utils/productAttributes'
import frequentlyBoughtStyles from './styles/FrequentlyBoughtTogether.module.scss'

type FrequentlyBoughtTogetherProps = {
  algoliaConfig: {
    indexName: string
    maxRecommendations: number
    recommendClient: any
  }
  id: string
  description: string
  title: string
} & Pick<CmsCarouselProps, 'sliderProps'>

export const FrequentlyBoughtTogether: React.FC<FrequentlyBoughtTogetherProps> = ({
  algoliaConfig,
  description,
  id,
  title,
  sliderProps,
}) => {
  const orderItems = useSelector(orderItemsSelector)
  const catentries = useSelector(catentriesSelector)
  let productsPartNumber: string[] = []

  if (orderItems?.length) {
    productsPartNumber = orderItems
      .map(item => {
        const validProductType = PRODUCT_TYPES_MAP[getProductType(item).toLowerCase()]
        if (isContactLensesProduct(item)) {
          const productItem = catentries?.[item.productId]
          return productItem?.items?.[0]?.partNumber ?? ''
        } else if (validProductType) {
          return item.partNumber
        } else {
          return ''
        }
      })
      .filter(v => v) // removes empty strings
  }

  const recommendations = useRelatedProducts({
    ...algoliaConfig,
    objectIDs: productsPartNumber.length ? [...new Set(productsPartNumber)] : [],
    queryParameters: {
      ruleContexts: ['frequently-bought'],
    },
  })?.recommendations

  const recommendationsWithPrices = recommendations
    .map((hit: Partial<IAlgoliaHit>) => ({
      ...hit,
      x_price: { ...hit.prices },
    }))
    .filter(hit => orderItems.every(o => o.partNumber !== hit.objectID))

  const customSliderProps = {
    ...sliderProps,
    breakpoints: {
      768: {
        slidesPerGroup: 2,
        slidesPerView: 2,
      },
      1280: {
        slidesPerGroup: 3,
        slidesPerView: 3,
      },
    },
    slidesPerGroup: 1,
    slidesPerView: 1.5,
  }
  const productTileProps = {
    addToCartId: 'BoughtTogether_AddCart',
    addToCartTestId: 'CartAddToCart',
    alignPriceCenter: true,
    isAddToCartEnabled: product => {
      const productType = getProductType(product)
      return isCLAccessories(productType) || isAccessories(productType)
    },
  }

  return (
    <div id={id} data-analytics_available_call="0">
      <CmsCarousel
        products={recommendationsWithPrices}
        productTileProps={productTileProps}
        description={description}
        title={title}
        sliderProps={customSliderProps}
        styleOverride={[frequentlyBoughtStyles, undefined]}
        isAlgolia
      />
    </div>
  )
}
