import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import config from '../../configs'
import { hostnameSelector, hostnameUrlsSelector, monetateKeySelector } from '../../redux/selectors/site'
import Log from '../../services/Log'
import { executeOnce } from '../../utils/common'
import { SiteInfo } from '@redux/reducers'
import { BASE_URL, URL_ENVLETTER_PROD, URL_ENVLETTER_DEV } from './constants/tracker'
import { getDomainFromHost } from '@utils/url'

export const useMonetate = (site: SiteInfo) => {
  const isMonetateEnabledConf = useSelector(monetateKeySelector)
  const isMonetateEnabled = config.enableMonetate && isMonetateEnabledConf
  const businessUnitId = site.mySite?.xStoreCfg?.monetateId
  const hostname = useSelector(hostnameSelector)
  const domain = getDomainFromHost(hostname)
  const hostnameUrls = useSelector(hostnameUrlsSelector)
  const envLetter =
    hostname && !!Object.keys(hostnameUrls).length && hostnameUrls.isProduction ? URL_ENVLETTER_PROD : URL_ENVLETTER_DEV

  const loadScript = () => {
    return executeOnce(() => {
      const head = document.head

      const monetateTag = document.createElement('script')
      const src = `${BASE_URL}/${businessUnitId}/${envLetter}/${domain}/entry.js`
      monetateTag.type = 'text/javascript'
      monetateTag.src = src
      head.insertBefore(monetateTag, head.firstChild)

      const monetateTScript = document.createElement('script')
      monetateTScript.type = 'text/javascript'
      monetateTScript.text = 'var monetateT = new Date().getTime();'
      head.insertBefore(monetateTScript, head.firstChild)
    }, 'dw-monetate')()
  }

  useEffect(() => {
    if (isMonetateEnabled && site) {
      try {
        window.monetateQ = window.monetateQ || []
        window.monetateQ.push(['setPageType', 'loading'])
        loadScript()
      } catch (e: any) {
        Log.error('[MONETATE] LOAD SCRIPT ERROR ', e.message)
      }
    }
  }, [isMonetateEnabled, site])
}

export const listenMonetateQ = () => {
  const monetateInitial =
    typeof window !== 'undefined' ? window.monetateQ !== undefined && !Array.isArray(window.monetateQ) : false
  const [isMonetateQDefined, setIsMonetateQDefined] = useState<boolean>(monetateInitial)

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.monetateQ !== undefined && !Array.isArray(window.monetateQ)) {
        setIsMonetateQDefined(true)
        clearInterval(intervalId)
      }
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return isMonetateQDefined
}
