import { Dialog, DialogActions, styled } from '@mui/material'
import { StyledTypography } from '../Typography'
import { IconButton } from '../IconButton'

export const StyledDialog = styled(Dialog, {
  name: 'StyledDialog',
  slot: 'Dialog',
  shouldForwardProp: prop => prop !== 'fullHeightOnSmallScreens',
})<{ fullHeightOnSmallScreens: boolean }>(({ theme, fullHeightOnSmallScreens }) => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      maxHeight: '100%',
      width: '100%',
      height: fullHeightOnSmallScreens ? '100%' : 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      borderRadius: theme.spacing(1),
      minWidth: 500,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 760,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 850,
    },
  },
}))

export const StyledDialogTitle = styled(StyledTypography, {
  name: 'StyledDialog',
  slot: 'Title',
})(({ theme }) => ({
  width: '90%',
  fontWeight: 700,
  textAlign: 'center',
  marginTop: theme.spacing(9),
  marginBottom: 0,
}))

export const StyledDialogCloseButton = styled(IconButton, {
  name: 'StyledDialog',
  slot: 'CloseButton',
})(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(4),
  right: theme.spacing(4),
}))

export const StyledDialogActions = styled(DialogActions, {
  name: 'StyledDialog',
  slot: 'DialogActions',
})(({ theme }) => ({
  justifyContent: 'center',
  padding: `${theme.spacing(6)} ${theme.spacing(6)}`,

  '& .MuiButtonBase-root': {
    margin: 0,
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))
