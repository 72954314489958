import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import config from '@configs/config.base'
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_KEYS } from '@constants/product'
import { NOT_FOUND } from '@constants/routes'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import useProductData from '@hooks/useProductData/useProductData'
import { locationOriginSelector } from '@redux/selectors/site'
import { Attachment, IProduct, ProductRightColumnProps } from '@typesApp/product'
import { getCurrentProductItemByUniqId, isSunProduct } from '@utils/product'
import { getNormalizedProductType, getSoldOut } from '@utils/productAttributes'
import { seoUrlConcat } from '@utils/url'

import { ProductMedia } from '@components/common/Media/ProductMedia'
import {
  StyledFramesRightColumnContainer,
  StyledImageWrapper,
  StyledRelatedProduct,
  StyledSeparator,
} from '@pages_views/ProductDetails/ProductDetails.style'
import ProductColorSelector from '@pages_views/ProductDetails/components/ProductColorSelector'
import { OmniChannelCarousel } from '@pages_views/ProductDetails/components/OmniChannelCarousel/OmnichannelCarousel'
import ProductAvailableNotificationDrawer from '@pages_views/ProductDetails/components/ProductAvailableNotificationDrawer'
import ProductCtaContainer from '@pages_views/ProductDetails/components/ProductCtaContainer'
import { ProductDetailsList } from '@pages_views/ProductDetails/components/ProductDetailsList'
import { ProductNameSection } from '@pages_views/ProductDetails/components/ProductNameSection'
import ProductSize from '@pages_views/ProductDetails/components/ProductSize'
import { StyledSpecialFrameInfo } from '@pages_views/ProductDetails/components/StyledSpecialFrameInfo'
import styles from '@pages_views/ProductDetails/components/ProductRightColumn/styles/PdpRight.module.scss'
import { ProductPriceAlgolia } from '@pages_views/ProductDetails/components/ProductPriceAlgolia'
import { toggleAddToCart } from '@features/product/slice'
import { ShippingTimesBanner } from '../ShippingTimesBanner/ShippingTimesBanner'

export const FramesPdpRightColumn = ({
  partNumber,
  currentProduct,
  soldOutStatus,
  productInCart,
  addToCartButtonFail,
  ctaRef,
  placements,
  cluster,
  onClusterProductClick,
  isLoading,
  isSoldout,
  isRoxable,
  pdpData,
  onlyFewPieces,
  productQuantity,
}: ProductRightColumnProps) => {
  const { langCode } = useStoreIdentity()
  const { t } = useTranslation()
  const theme = useTheme()
  const router = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)
  const dispatch = useDispatch()

  const displayable = !!currentProduct?.displayable
  const areAllClusterItemsSoldOut = cluster?.length
    ? cluster.every(i => getSoldOut(i) === PRODUCT_SOLDOUT_STATUS.SOLDOUT)
    : false
  const renderColorSelector = !areAllClusterItemsSoldOut && cluster && cluster?.length > 1

  const { suggestedProducts: relatedProducts } = useProductData(pdpData, partNumber)

  const getAttachments = (cluster: IProduct): Attachment[] => cluster?.cluster?.[0]?.attachments || []

  const getSequence = (cluster: IProduct) => {
    const attachments = cluster[0]?.attachments
    const hasFrontalAttachment = attachments.some(({ sequence }) => sequence === '15.0')

    return hasFrontalAttachment ? '15.0' : undefined
  }

  const getItems = (product: IProduct) => {
    return product.sKUs != null ? product.sKUs : product.items
  }

  const getMocoLinkTo = (cluster: IProduct | undefined) => {
    let href = ''
    const product = cluster?.cluster?.[0]
    const items = product && getItems(product)
    href = items?.[0]?.seo?.href || NOT_FOUND
    const finalHref = seoUrlConcat(langCode, href)
    return finalHref
  }

  const onSizeChange = useCallback(
    (id: string | undefined) => {
      const selectedSizeItem = !!id ? getCurrentProductItemByUniqId(id, cluster) : null
      if (!selectedSizeItem) return
      router.push(selectedSizeItem?.seo?.href ?? '')
    },
    [cluster, router]
  )

  let productType = getNormalizedProductType(currentProduct)
  //We want the SUN products type to be considered as frames so the sticky bar shows the  price that matches the Frame right column
  if (productType === PRODUCT_TYPES_KEYS.SUN) {
    productType = PRODUCT_TYPES_KEYS.FRAMES
  }
  const soldOutFeatureEnabled = config.isSoldOutFeatureEnabled
  const showProductDetailsList =
    !!config.pdp.showProductDetailsInRightColumn && (soldOutFeatureEnabled || (currentProduct && !isSoldout))
  const showProductCtas = soldOutFeatureEnabled || currentProduct
  dispatch(toggleAddToCart(true))

  return !currentProduct ? null : (
    <StyledFramesRightColumnContainer>
      <ProductNameSection currentProduct={currentProduct} isSoldout={isSoldout} displayable={displayable} />
      <ProductSize
        onSizeChange={onSizeChange}
        currentProduct={currentProduct}
        cluster={cluster}
        partNumber={partNumber}
      />

      {!areAllClusterItemsSoldOut && <StyledSpecialFrameInfo currentProduct={currentProduct} />}

      {!!relatedProducts?.length && (
        <StyledRelatedProduct
          href={getMocoLinkTo(relatedProducts[0])}
          locale={router.locale as string}
          isLocaleDomain={router.isLocaleDomain}
          locationOrigin={locationOrigin}
        >
          <StyledImageWrapper>
            <ProductMedia
              sequence={getSequence(relatedProducts[0])}
              attachments={getAttachments(relatedProducts[0])}
              backgroundColor={theme.palette.background.light.primary}
              lazy={true}
            />
          </StyledImageWrapper>
          <span>
            {isSunProduct(relatedProducts[0])
              ? t('ProductDetails.Suggested.ShopInSun')
              : t('ProductDetails.Suggested.ShopInOptical')}
          </span>
        </StyledRelatedProduct>
      )}

      {renderColorSelector && (
        <>
          {!areAllClusterItemsSoldOut && <StyledSeparator />}

          <ProductColorSelector
            currentProductItem={currentProduct}
            isLoading={isLoading}
            cluster={cluster}
            selectedPartNumber={partNumber}
            soldOutStatus={soldOutStatus}
            onClick={onClusterProductClick}
          />
        </>
      )}

      {displayable && (
        <div className={styles.container}>
          <ProductPriceAlgolia
            isCompact
            soldOutStatus={soldOutStatus}
            productQuantity={productQuantity}
            totalBoxes={1}
          />
          {showProductCtas && (
            <ProductCtaContainer
              addToCartFillType="fill"
              ctaRef={ctaRef}
              currentProduct={currentProduct}
              error={addToCartButtonFail}
              isLoading={isLoading}
              onlyFewPieces={onlyFewPieces}
              productInCart={productInCart}
              roxable={isRoxable}
              type={soldOutStatus}
            />
          )}
          {!soldOutFeatureEnabled && <ShippingTimesBanner productType={PRODUCT_TYPES_KEYS.FRAMES} />}
        </div>
      )}

      <ProductAvailableNotificationDrawer
        attachments={currentProduct?.attachments || []}
        attributes={currentProduct?.productAttributes}
        product={currentProduct || undefined}
      />
      {placements && <OmniChannelCarousel placements={placements} />}
      {showProductDetailsList && <ProductDetailsList type={soldOutStatus} currentProduct={currentProduct} />}
    </StyledFramesRightColumnContainer>
  )
}
