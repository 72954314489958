import React from 'react'
import {
  StyledNewsletterBody,
  StyledNewsletterContainer,
  StyledNewsletterTypographyTitle,
  ThankYouContainer,
  StyledNewsletterTypographySubTitle,
  StyledLPCtaContainer,
} from './NewsLetterSubscribe.style'
import { useNewsletterSubscriptionSuccess } from '@foundation/hooks/useNewsletterSubscriptionSuccess'
import { v4 as uuid } from 'uuid'
import { CmsCta } from '@components/Cms/CMSContent/components/CmsCta'
export const SuccessContainer: React.FC = () => {
  const { signUpSuccessTitle, signUpSuccessSubtitle, teaserLXCallToActionSettings } = useNewsletterSubscriptionSuccess()
  const glassesPages = ['glasses', 'lunettes', 'black-friday']

  return (
    <StyledNewsletterContainer>
      <div>
        <StyledNewsletterBody>
          <ThankYouContainer>
            <StyledNewsletterTypographyTitle>{signUpSuccessTitle}</StyledNewsletterTypographyTitle>
            <StyledNewsletterTypographySubTitle dangerouslySetInnerHTML={{ __html: signUpSuccessSubtitle }} />
          </ThankYouContainer>
        </StyledNewsletterBody>
        <StyledLPCtaContainer>
          {teaserLXCallToActionSettings?.map((actionSettings, index) => (
            <CmsCta
              dataDescription={`${
                glassesPages.includes(actionSettings.target.formattedUrl.split('/')?.[2]) ? 'ShopGlasses' : 'ShopCL'
              }`}
              dataElementId={'SignUpOverlay_EA_CTA'}
              key={`SignUpOverlay_EA_CTA${uuid()}`}
              teaserCtaSetting={actionSettings}
            />
          ))}
        </StyledLPCtaContainer>
      </div>
    </StyledNewsletterContainer>
  )
}
