import { NOT_FOUND } from '@constants/routes'
import { isAccessories, isCLAccessories, isContactLenses, isFrames } from '@utils/product'
import { PRODUCT_TYPES_MAP } from '../../constants/product'
import CurrencyService from '../../services/CurrencyService'
import { IProduct, SupplyData } from '../../types/product'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { getProductPriceByCustomerSegments } from '@components/common/UI/ProductPrice/utils/utils'

class ProductTileUtils {
  getAttributes = (product: IProduct) => {
    const attributes = product.productAttributes
    const productType = PRODUCT_TYPES_MAP[attributes['PRODUCT_TYPE']?.toLowerCase()]
    const lxProductType = PRODUCT_TYPES_MAP[attributes['LX_SEARCH_PAGE_PRODUCT_TYPE']?.toLowerCase()]
    const name = attributes['MODEL_NAME']
    const brand = attributes['BRAND'] || attributes['CL_BRAND']
    const modelCode = attributes['DISPLAYSKU']

    const customerSegments = useCustomerSegmentsUtil()
    const prices = getProductPriceByCustomerSegments(product?.x_price, customerSegments)
    const currency = CurrencyService.getSymbolByName(prices?.currency || '')
    const colorLabel = this.getColorLabel(product)
    const annualSupply = this.getAnnualSupply(product).find(data => data.timePeriod === '12')
    return {
      name,
      brand,
      modelCode,
      productType,
      lxProductType,
      prices,
      currency,
      colorLabel,
      annualSupply,
    }
  }
  getMocoLinkTo = (product: IProduct, langCode: string) => {
    const href = product?.seo?.href || NOT_FOUND
    return href?.startsWith('/') ? href : '/' + href
  }

  getLxProductTypes = (lxProductType: string) => {
    return {
      isFrames: isFrames(lxProductType),
      isContactLenses: isContactLenses(lxProductType),
      isContactLensesAccessories: isCLAccessories(lxProductType),
      isAccessories: isAccessories(lxProductType),
    }
  }

  getColorLabel = (product: IProduct) => {
    const attributes = product.productAttributes
    const lxProductType = attributes['PRODUCT_TYPE']?.toLowerCase()

    switch (lxProductType) {
      case PRODUCT_TYPES_MAP.accessories:
      case PRODUCT_TYPES_MAP.optical:
        return attributes['FRONT_COLOR']
      case PRODUCT_TYPES_MAP.sun:
        const frontColor = attributes['FRONT_COLOR']
        const lensColor = attributes['LENS_COLOR'] || attributes['LENS_COLOR_FACET']
        return frontColor === lensColor ? frontColor : `${frontColor} ${lensColor}`.trim()
      default:
        return ''
    }
  }

  getAnnualSupply = (product: IProduct) => {
    const attributes = product.productAttributes
    const annualSupplyValue = attributes['CL_ANNUAL_SUPPLY_BADGES']

    if (!annualSupplyValue) return []

    const supplyAttributeDataConfMap: Record<number, keyof SupplyData> = {
      0: 'quantity',
      1: 'discountAmount',
      2: 'originalBoxPrice',
      3: 'discountedBoxPrice',
      4: 'timePeriod',
    }

    const supplyTempData: SupplyData[] = []
    const temp: SupplyData = {}
    annualSupplyValue.split('|').forEach((supplyDataItem, i) => {
      if (!!supplyDataItem && !!supplyAttributeDataConfMap[i]) {
        temp[supplyAttributeDataConfMap[i]] = supplyDataItem
      }
    })
    if (temp) supplyTempData.push(temp)

    return supplyTempData
  }
}
const productTileUtils = new ProductTileUtils()
export default productTileUtils
