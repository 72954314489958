import { Chip as MuiChip, chipClasses as MuiChipClasses, styled } from '@mui/material'
import { IconCrossThree } from '../Icons/VD/General'

export const StyledPill = styled(MuiChip, {
  name: 'Pill',
  slot: 'Pill',
  shouldForwardProp: prop => prop !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive }) => ({
  [`&.${MuiChipClasses.root}`]: {
    backgroundColor: isActive ? theme.palette.primary.tones[90] : theme.palette.common.white,
    borderRadius: theme.spacing(20),
    border: `1px solid ${theme.palette.neutral.tones[60]}`,
    cursor: 'pointer',
    padding: theme.spacing(3.75, 6),
    transition: 'none',
    flexDirection: 'row-reverse',

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3.5, 8),
    },

    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        backgroundColor: theme.palette.primary.tones[95],
      },
    },

    '&:active': {
      boxShadow: 'none',
    },

    [`&.${MuiChipClasses.disabled}`]: {
      opacity: 0.3,
    },

    [`.${MuiChipClasses.label}`]: {
      ...theme.typography.body2,
      padding: theme.spacing(0),
    },
  },

  ['.MuiTouchRipple-root']: {
    display: 'none',
  },

  [`.${MuiChipClasses.deleteIcon}`]: {
    color: theme.palette.error.main,
    fontSize: 24,
    margin: theme.spacing(0, -2, 0, 4),

    '&:hover': {
      color: theme.palette.error.main,
    },
  },

  [`&.${MuiChipClasses.deletable}`]: {
    backgroundColor: theme.palette.primary.tones[90],
    flexDirection: 'row',
  },
}))

export const StyledPillWrapper = styled('div', {
  name: 'PillWrapper',
  slot: 'PillWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(4),
}))

export const StyledDeleteIcon = styled(IconCrossThree, {
  name: 'PlpHeader',
  slot: 'StyledCloseIcon',
})(() => ({}))

export const StyledSortByCTAForMobile = styled(StyledPill, {
  name: 'PlpHeader',
  slot: 'StyledSortByCTA',
})(({ theme }) => ({
  [`&.${MuiChipClasses.root}`]: {
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'row-reverse',

    '&:hover': {
      backgroundColor: theme.palette.primary.tones[100],
    },
  },
}))
