import React from 'react'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { AssociatedProduct, IProduct } from '@typesApp/product'
import { PlacementsSwitch } from '@components/Cms/PlacementsSwitch'
import config from '@configs/index'
import { PlacementsSwitchRefactored } from './CMSContent/PlacementsSwitch'

export interface CMSContentProps {
  activePlacements?: IViewType[]
  contentVisibilityStart?: number
  currentProduct?: IProduct
  isPLP?: boolean
  placements?: IPlacement[]
  suggestedProducts?: AssociatedProduct[]
}

export function CMSContent(props: CMSContentProps) {
  const { activePlacements, contentVisibilityStart, currentProduct, isPLP, placements, suggestedProducts } = props

  const { isCmsRefactoredContentEnabled } = config

  if (isCmsRefactoredContentEnabled) {
    return (
      <PlacementsSwitchRefactored
        activePlacements={activePlacements}
        isPLP={isPLP}
        pdpData={currentProduct}
        placements={placements}
      />
    )
  }

  return (
    <PlacementsSwitch
      placements={placements}
      currentProduct={currentProduct}
      suggestedProducts={suggestedProducts}
      activePlacements={activePlacements}
      isPLP={!!isPLP}
      contentVisibilityStart={contentVisibilityStart}
    />
  )
}
