import { StyledPLPFilteredContent } from '@components/PlpFilter/PlpFilter.style'
import { AlgoliaProductListInitSSR } from '@components/common/components/AlgoliaInit'
import { ICategory } from '@features/category/query'
import { PlpProvider } from '@utils/Plp/PlpContext'
import { IPlpState } from '@utils/Plp/PlpReducer'
import PlpFilterAlgolia from '@components/PlpFilter/PlpFilterAlgolia'
import { ProductGridLayoutAlgolia } from '../../ProductGrid'
import LayoutWithSidebar from '@layouts/LayoutWithSidebar'
import { ICommerceHclPage } from '@typesApp/cms'
import { useTranslation } from 'next-i18next'
import Container from '@components/UI/Container'
import { getProductBreadcrumbs } from '../product/Product'
import { StyledCategoryWrapper } from '@components/PagesSeo/category/Category.style'
import { NextRouter } from 'next/router'
import BreadcrumbsLayout from '@components/Breadcrumbs/Breadcrumbs'

interface CategoryProps {
  plpState: IPlpState
  categoryData: ICategory[] | null
  parentCatalogGroupID?: string[] | null
  plpCommerce: ICommerceHclPage | undefined
  isLocaleDomain: boolean
  locationOrigin: string
  domainLocales: NextRouter['domainLocales']
}

const Category: React.FC<CategoryProps> = ({
  categoryData,
  parentCatalogGroupID,
  plpState,
  plpCommerce,
  isLocaleDomain,
  locationOrigin,
  domainLocales,
}) => {
  const { categoryId, categoryIdentifier, algoliafilterCategory, groupedIndexName, serverUrl, locale } = plpState
  const sidebarNav = plpCommerce?.commercePlacements.find(placement => placement.name === 'header_left_side_navigation')
  const breadcrumbs = getProductBreadcrumbs(categoryData)
  const { t } = useTranslation()

  return (
    <>
      <Container>
        <BreadcrumbsLayout
          breadcrumbsList={breadcrumbs}
          cid={`category-products-${categoryIdentifier}`}
          langCode={plpState.locale.replace('_', '-')}
          searchTermText={`${t('ProductGrid.Labels.searchResults')}`}
          isLocaleDomain={isLocaleDomain}
          locale={locale}
          locationOrigin={locationOrigin}
        />
      </Container>
      <StyledCategoryWrapper>
        <LayoutWithSidebar sidebarNav={sidebarNav}>
          <PlpProvider initialState={plpState}>
            <AlgoliaProductListInitSSR
              plpSSRInitParams={plpState.serverState}
              plpInitParams={{
                groupedIndexName,
                serverUrl,
                locale,
                configure: {
                  filters: algoliafilterCategory ?? '',
                },
              }}
            >
              {searchClient => (
                <>
                  <PlpFilterAlgolia searchClient={searchClient} />
                  <StyledPLPFilteredContent id={categoryIdentifier}>
                    <ProductGridLayoutAlgolia
                      cid={`category-products-${categoryIdentifier}`}
                      categoryId={categoryId}
                      searchClient={searchClient}
                      categoryFilter={algoliafilterCategory ?? ''}
                      categoryData={categoryData}
                      parentCatalogGroupID={parentCatalogGroupID}
                      plpCommerce={plpCommerce}
                    />
                  </StyledPLPFilteredContent>
                </>
              )}
            </AlgoliaProductListInitSSR>
          </PlpProvider>
        </LayoutWithSidebar>
      </StyledCategoryWrapper>
    </>
  )
}

export default Category
